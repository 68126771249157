// import enTranslation from './en/translation.json';
import plTranslation from './pl/translation.json';

// export const en = {
//     translation: enTranslation,
// };

export const pl = {
    translation: plTranslation,
};
