import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import { HiOutlinePencil, HiPlus } from 'react-icons/hi';
import { useConfigContext } from '../../contexts/ConfigContext';
import Table from '../../components/Table';
import EmailCell from '../../components/Table/components/EmailCell';
import PhoneCell from '../../components/Table/components/PhoneCell';
import Grid from '../../components/Grid';
import DescriptionList from '../../components/DescriptionList';
import urls from '../../setup/urls';

import { clientApi } from '../../api';
import csvSampleData from './csvSampleData';

/* eslint-disable react/prop-types */
function NameCell({ value, data }) {
    return (
        <div className="rgt-cell-inner">
            <Link to={`${get(urls, 'clientForm.route')}/${data.id}`}>{value}</Link>
        </div>
    );
}

function DeleteMessage({ item }) {
    const { t } = useTranslation();
    const { firstName, lastName, email, phone } = item;
    return (
        <div>
            <p>{t('Are you sure you want to delete this client?')}</p>
            <div className="mt-3">
                <DescriptionList
                    items={[
                        {
                            label: t('Name'),
                            value: `${firstName} ${lastName}`,
                        },
                        {
                            label: t('Email'),
                            value: email,
                        },
                        {
                            label: t('Phone'),
                            value: phone,
                        },
                    ]}
                />
            </div>
        </div>
    );
}
/* eslint-enable react/prop-types */

function Clients() {
    const { t } = useTranslation();
    const [, { setPageTitle }] = useConfigContext();

    useEffect(() => {
        setPageTitle(t('Clients'));
    }, []);

    const tableConfig = {
        id: 'clients',
        columns: React.useMemo(
            () => [
                {
                    field: 'lastName',
                    label: `${t('Last name')}/${t('Company')}`,
                    pinned: true,
                    type: 'string',
                    cellRenderer: NameCell,
                },
                {
                    field: 'firstName',
                    label: t('First name'),
                    pinned: true,
                    type: 'string',
                    cellRenderer: NameCell,
                },
                {
                    field: 'phone',
                    label: t('Phone'),
                    type: 'string',
                    cellRenderer: PhoneCell,
                },
                {
                    field: 'phone2',
                    label: t('Phone 2'),
                    type: 'string',
                    cellRenderer: PhoneCell,
                },
                {
                    field: 'email',
                    label: t('Email'),
                    type: 'string',
                    cellRenderer: EmailCell,
                },
                {
                    field: 'email2',
                    label: t('Email 2'),
                    type: 'string',
                    cellRenderer: EmailCell,
                },
                {
                    field: 'birthDate',
                    label: t('Birth Date'),
                    type: 'date',
                    width: '160px',
                },
                {
                    field: 'passport',
                    label: t('No Passport'),
                    type: 'string',
                },
                {
                    field: 'passportDate',
                    label: t('Passport Date'),
                    type: 'date',
                    width: '200px',
                },
            ],
            [],
        ),
        headerActions: [
            {
                label: 'Add Client',
                icon: HiPlus,
                to: `${get(urls, 'clientForm.route')}/create`,
                useSeparately: true,
            },
        ],
        rowActions: [
            {
                label: 'Edit',
                icon: HiOutlinePencil,
                to: ({ data: { id } = {} }) => `${get(urls, 'clientForm.route')}/${id}`,
            },
        ],
        initConfig: {
            sort: { field: 'lastName', direct: 'ASC' },
            hiddenColumns: ['phone2', 'email2'],
        },
        contextApi: clientApi,
        deleteMessage: DeleteMessage,
        withImport: true,
        importConfig: {
            title: 'Import clients',
            fileName: 'clients-example.csv',
            data: csvSampleData,
        },
        withExport: true,
    };

    return (
        <Grid.Container fluid>
            <Grid.Row>
                <Grid.Col>
                    <Table {...tableConfig} />
                </Grid.Col>
            </Grid.Row>
        </Grid.Container>
    );
}

export default Clients;
