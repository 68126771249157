import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Menu from '../../../Menu';

function PageSize({ tableManager }) {
    const { t } = useTranslation();
    const { config, paginationApi, asyncApi, rowsApi } = tableManager;
    const { pageSize, page, setPage, setPageSize } = paginationApi;
    const { totalRows } = rowsApi;

    const handlePaginationChange = (pageSize) => {
        setPage(1);
        setPageSize(pageSize);
        asyncApi.resetRows();
    };

    const items = config.pageSizes.map((pageSize) => ({
        label: pageSize,
        onClick: () => handlePaginationChange(pageSize),
        type: 'button',
    }));

    const currentPageSize = pageSize > totalRows ? totalRows : page * pageSize;
    return (
        <div>
            <span className="text-sm font-semibold text-gray-500 dark:text-gray-300">
                {t('Showing')}
                <Menu
                    trigger={() => <span>{`${(page - 1) * pageSize + 1}- ${currentPageSize}`}</span>}
                    items={items}
                    wrapperClassName="mx-1"
                    itemsClassName="w-16"
                    triggerClassName="p-2 rounded-md font-semibold text-slate-500 hover:text-sky-600 hover:bg-slate-100 dark:text-slate-300 dark:hover:bg-gray-600 dark:hover:text-white"
                />
                {t(`of {{number}} items`, { number: totalRows })}
            </span>
        </div>
    );
}

PageSize.propTypes = {
    tableManager: PropTypes.object.isRequired,
};

PageSize.defaultProps = {};

export default PageSize;
