import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useNotification } from '../../../../contexts/NotificationContext';
import { sendRequest } from '../../../../utils/request';
import { authActivateAccount } from '../../../../api';

export const useForm = ({ token }) => {
    const { t } = useTranslation();
    const [, { addError }] = useNotification();
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(0);

    useEffect(() => {
        const sendToken = async (token) => {
            setLoading(true);
            const response = await sendRequest(authActivateAccount(token), {
                method: 'PUT',
            });

            setLoading(false);
            if (response.status === 200) {
                setStatus(1);
            } else {
                setStatus(2);
                addError(t(response.data.error));
            }
        };

        if (token) {
            sendToken(token);
        }
    }, [token]);

    return {
        loading,
        status,
    };
};
