import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import bg from '../../assets/bg.jpg';
import bgSmall from '../../assets/bg-small.jpg';
import { COPYRIGHTS } from '../../constants';

function BaseLayout({ children, background }) {
    const imgRef = useRef(null);

    useEffect(() => {
        const img = imgRef?.current;

        const handleLoaded = () => {
            img.style.opacity = 1;
        };

        if (img) {
            if (img.complete) {
                handleLoaded();
            } else {
                img.addEventListener('load', handleLoaded);
            }
        }

        return () => () => img.removeEventListener('load', handleLoaded);
    }, [imgRef]);

    return (
        <div className="min-h-screen">
            <div
                className="absolute inset-0 bg-center bg-cover"
                style={{
                    backgroundImage: `url(${bgSmall})`,
                }}
            >
                <img
                    ref={imgRef}
                    className="object-cover object-center w-full h-full transition-opacity duration-200 opacity-0"
                    src={background || bg}
                    alt=""
                    loading="lazy"
                />
                <div className="absolute inset-0 dark:bg-slate-800 dark:opacity-50 dark:bg-blend-multiply" />
            </div>
            {children}
            <p className="fixed bottom-0 left-0 right-0 p-2 text-xs text-center bg-black/30 text-neutral-100 text-shadow">
                {COPYRIGHTS}
            </p>
        </div>
    );
}

BaseLayout.propTypes = {
    children: PropTypes.any,
    background: PropTypes.node,
};

BaseLayout.defaultProps = {
    children: null,
    background: null,
};

export default BaseLayout;
