import { useMemo } from 'react';
import get from 'lodash/get';
import { useField } from 'formik';
import { values, reduce, set, keys, some } from 'lodash';

import { getReservationFinances } from '../../../../../../utils/price';

export const useAdvancePayment = () => {
    const [field, meta] = useField('advancePayment');
    const [, servicesMeta] = useField('services');

    const handleAddPayment = (arrayHelpers) => {
        arrayHelpers.push({
            date: '',
            amount: 0,
            currency: process.env.REACT_APP_DEFAULT_CURRENCY,
        });
    };

    const totals = useMemo(
        () =>
            values(
                reduce(
                    field.value,
                    (result, { amount, currency }) => {
                        const currentTotalForCurrency = get(result, `${currency}.value`, 0);
                        set(result, currency, {
                            value: parseFloat(currentTotalForCurrency) + parseFloat(amount),
                            currency,
                        });
                        return result;
                    },
                    {},
                ),
            ),
        [field.value],
    );

    const getToPay = () => {
        const financeData = getReservationFinances({ services: servicesMeta.value, advancePayment: meta.value });

        return reduce(
            keys(financeData),
            (result, currency) => {
                result.push({ value: get(financeData, `${currency}.toPay`, 0), currency });
                return result;
            },
            [],
        );
    };

    const toPay = getToPay();

    return [
        {
            field,
            meta,
            totals,
            toPay,
            isSurcharge: some(toPay, ({ value }) => value > 0),
        },
        {
            handleAddPayment,
        },
    ];
};
