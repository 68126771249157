import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Header from '../Header';

function Section({ className, children, title }) {
    const rootClass = classNames(
        'bg-white w-full rounded-md p-4 ring-slate-900 shadow-lg dark:bg-slate-800 ',
        className,
    );

    return (
        <section className={rootClass}>
            {title && (
                <Header as="h2" dividing>
                    {title}
                </Header>
            )}
            {children}
        </section>
    );
}

Section.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any,
    title: PropTypes.any,
};

Section.defaultProps = {
    className: '',
    children: null,
    title: null,
};

export default Section;
