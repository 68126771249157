import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getButtonClass } from '../Button/Button';

function LinkButton({
    size,
    color,
    outline,
    to,
    iconOnly,
    iconLeft,
    iconRight,
    loading,
    disabled,
    children,
    className,
    ...props
}) {
    const rootClassName = getButtonClass({ color, className, size, iconOnly, outline });

    const IconLeft = iconLeft;
    const IconRight = iconRight;

    return (
        <Link to={to} className={rootClassName} {...props} disabled={disabled || loading}>
            {iconLeft && <IconLeft className="mr-2 text-xl" />}
            <span>{children}</span>
            {iconRight && <IconRight className="ml-2 text-xl" />}
        </Link>
    );
}

LinkButton.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    to: PropTypes.any,
    type: PropTypes.oneOf(['button', 'submit', 'reset']),
    iconOnly: PropTypes.bool,
    iconLeft: PropTypes.any,
    iconRight: PropTypes.any,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    outline: PropTypes.bool,
    color: PropTypes.oneOf(['primary', 'secondary', 'success', 'danger', 'warning', 'link']),
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
};

LinkButton.defaultProps = {
    children: null,
    className: '',
    to: null,
    type: 'button',
    iconOnly: false,
    iconLeft: null,
    iconRight: null,
    loading: false,
    disabled: false,
    outline: true,
    color: 'primary',
    size: 'md',
};
export default LinkButton;
