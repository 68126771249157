import React from 'react';
import PropTypes from 'prop-types';

function Row({ children, className }) {
    return <div className={`flex flex-wrap -mx-2 lg:-mx-4 ${className}`}>{children}</div>;
}

Row.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
};

Row.defaultProps = {
    children: null,
    className: '',
};
export default Row;
