import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get, find, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import Button from '../../../../../../components/Button';
import Modal from '../../../../../../components/Modal';
import ErrorMessage from '../../../../../../components/FormItem/components/ErrorMessage';
import { getFEFormattedDate } from '../../../../../../utils/date';
import { countries } from '../../../../../../utils/dictionaries';
import ClientSearchList from '../ClientSearchList';

function Purchaser({ client, onAddClient }) {
    const { t } = useTranslation();
    const [field, meta, helpers] = useField('purchaser');
    const [purchaser, setPurchaser] = useState({});
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        if (isEmpty(purchaser) && client) {
            setPurchaser(client);
        }
    }, [client]);

    const handleOpen = () => {
        setModalOpen(true);
    };
    const handleClose = () => {
        setModalOpen(false);
    };

    const handleSelection = (client) => {
        helpers.setValue(get(client, 'id', get(client, '_id')));
        setPurchaser(client);
        handleClose();
    };

    const handleAddClientAndSet = () => {
        onAddClient({
            onSuccess: handleSelection,
            onFailure: () => {},
        });
    };

    const countryCode = get(purchaser, 'country', '');
    const country = get(find(countries, ['value', countryCode]), 'label', countryCode);

    return (
        <Fragment>
            <div className="flex flex-col gap-3">
                {field.value && (
                    <div className="max-w-md p-4 bg-white border rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700">
                        <p>
                            {get(purchaser, 'lastName', '')} {get(purchaser, 'firstName', '')} (
                            {getFEFormattedDate(get(purchaser, 'birthDate', ''))})
                        </p>
                        <p>
                            {get(purchaser, 'street', '')}, {get(purchaser, 'postcode', '')}{' '}
                            {get(purchaser, 'city', '')}, {t(country)}
                        </p>
                        <p>
                            {get(purchaser, 'email', '')}, {get(purchaser, 'phone', '')}
                        </p>
                    </div>
                )}
                <div className="flex flex-col gap-3 sm:flex-row">
                    <Button onClick={handleOpen}>{t(field.value ? 'Change Purchaser' : 'Select Purchaser')}</Button>
                    <Button onClick={handleAddClientAndSet}>{t('Create and set purchaser')}</Button>
                </div>
            </div>

            <ErrorMessage isInvalid={meta.error && meta.touched} message={meta.error} />
            <Modal title="Select Purchaser" className="max-w-screen-md" open={modalOpen} onClose={handleClose}>
                <ClientSearchList onSelection={handleSelection} />
            </Modal>
        </Fragment>
    );
}

Purchaser.propTypes = {
    client: PropTypes.object,
    onAddClient: PropTypes.func,
};

Purchaser.defaultProps = {
    client: {},
    onAddClient: () => {},
};

export default Purchaser;
