import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

function DropzonePlaceholder() {
    const { t } = useTranslation();
    return (
        <Fragment>
            <p>{t("Drag 'n' drop image here, or click to select file")}</p>
            <p>
                <small>{t('Maximum file size: 2MB. Allowed file types: jpg, jpeg, png.')}</small>
            </p>
        </Fragment>
    );
}

export default DropzonePlaceholder;
