import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useConfigContext } from '../../contexts/ConfigContext';

import Section from '../../components/Section';
import ChangePasswordForm from './components/ChangePasswordForm';
import Details from './components/Details';
import Grid from '../../components/Grid';

function Profile() {
    const { t } = useTranslation();
    const [, { setPageTitle }] = useConfigContext();

    useEffect(() => {
        setPageTitle(t('Profile'));
    }, []);

    return (
        <Grid.Container fluid>
            <Grid.Row>
                <Grid.Col lg={6}>
                    <Section title={t('Details')}>
                        <Details />
                    </Section>
                </Grid.Col>
                <Grid.Col lg={6}>
                    <Section title={t('Change Password')}>
                        <ChangePasswordForm />
                    </Section>
                </Grid.Col>
            </Grid.Row>
        </Grid.Container>
    );
}
export default Profile;
