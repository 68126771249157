import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CgSpinner } from 'react-icons/cg';

const COLOR_MAP = {
    primary: 'blue',
    secondary: 'cyan',
    success: 'emerald',
    danger: 'red',
    warning: 'amber',
};

export const getButtonClass = ({ color, className, size, iconOnly, outline }) => {
    const mappedColor = COLOR_MAP[color];
    return classNames(
        `relative  border rounded-md flex justify-center items-center font-semibold whitespace-nowrap shadow-md transition duration-150 ease-in-out 
        hover:shadow-lg 
        focus:shadow-lg focus:outline-none focus:ring-0 
        active:shadow-lg 
        disabled:opacity-50 disabled:cursor-not-allowed`,
        `border-2 border-${mappedColor}-600 
         hover:bg-${mappedColor}-700 focus:bg-${mappedColor}-700 active:bg-${mappedColor}-800
         hover:text-white dark:hover:text-white
         dark:border-${mappedColor}-500 
         dark:hover:bg-${mappedColor}-700 dark:focus:bg-${mappedColor}-700 dark:active:bg-${mappedColor}-700`,
        className,
        {
            'text-xs px-2 py-2': size === 'sm',
            'text-sm px-3 py-2.5': size === 'md',
            'text-lg px-3 py-2.5': size === 'lg',
            'min-w-[70px]': size === 'sm' && !iconOnly,
            'min-w-[100px]': (size === 'md' || size === 'lg') && !iconOnly,
            'min-w-[34px]': size === 'sm' && iconOnly,
            'min-w-[42px]': size === 'md' && iconOnly,
            'min-w-[50px]': size === 'lg' && iconOnly,
            '!text-white': !outline,
            [`bg-${mappedColor}-600 dark:bg-${mappedColor}-600`]: !outline,
            'focus:text-white  dark:focus:text-white': outline,
            [`text-${mappedColor}-700 dark:text-${mappedColor}-400`]: outline,
        },
    );
};

function Button({
    size,
    color,
    outline,
    type,
    iconOnly,
    iconLeft,
    iconRight,
    loading,
    disabled,
    children,
    className,
    ...props
}) {
    const rootClassName = getButtonClass({ color, className, size, iconOnly, outline });

    const IconLeft = iconLeft;
    const IconRight = iconRight;

    return (
        <button type={type} className={rootClassName} {...props} disabled={disabled || loading}>
            {loading && <CgSpinner className="absolute inset-0 m-auto w-7 h-7 animate-spin" />}
            {iconLeft && <IconLeft className="mr-2 text-xl" />}
            <span>{children}</span>
            {iconRight && <IconRight className="ml-2 text-xl" />}
        </button>
    );
}

Button.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    type: PropTypes.oneOf(['button', 'submit', 'reset']),
    iconOnly: PropTypes.bool,
    iconLeft: PropTypes.any,
    iconRight: PropTypes.any,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    outline: PropTypes.bool,
    color: PropTypes.oneOf(['primary', 'secondary', 'success', 'danger', 'warning', 'link']),
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
};

Button.defaultProps = {
    children: null,
    className: '',
    type: 'button',
    iconOnly: false,
    iconLeft: null,
    iconRight: null,
    loading: false,
    disabled: false,
    outline: false,
    color: 'primary',
    size: 'md',
};
export default Button;
