import { HiOutlineUserCircle, HiOutlineCog } from 'react-icons/hi';
import get from 'lodash/get';
import { ADMIN_ROLE } from '../contexts/AuthContext/useAuth';
import urls from './urls';

function getUserItems({ t }) {
    return [
        {
            label: t('Profile'),
            to: get(urls, 'profile.route'),
            icon: HiOutlineUserCircle,
            order: 10,
        },
        {
            to: get(urls, 'settings.route'),
            icon: HiOutlineCog,
            label: t('Settings'),
            permissions: [ADMIN_ROLE],
        },
        {
            type: 'divider',
            order: 20,
        },
    ];
}

export default getUserItems;
