import React from 'react';

import Table from '../../components/Table';
import Grid from '../../components/Grid';
import LoadingMask from '../../components/LoadingMask';
import CSVDownload from '../../components/CSVDownload';
import { StatusModal } from './useChangeBulkStatus';

import { useReservations } from './useReservations';

function Reservations() {
    const { dictionariesLoading, changeStatusModalProps, csvInsuranceData, clearCsvInusernceData, tableConfig } =
        useReservations();

    return (
        <Grid.Container fluid>
            <Grid.Row>
                <Grid.Col>
                    {dictionariesLoading ? <LoadingMask /> : <Table {...tableConfig} />}
                    {csvInsuranceData.data.length > 0 && (
                        <CSVDownload
                            data={csvInsuranceData.data}
                            separator=","
                            filename={csvInsuranceData.fileName}
                            onClick={() =>
                                setTimeout(() => {
                                    clearCsvInusernceData();
                                }, 2000)
                            }
                        />
                    )}
                    <StatusModal {...changeStatusModalProps} />
                </Grid.Col>
            </Grid.Row>
        </Grid.Container>
    );
}

export default Reservations;
