import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { HiOutlineTrash } from 'react-icons/hi';
import { FieldArray } from 'formik';
import Grid from '../../../../../../components/Grid';
import FormItem from '../../../../../../components/FormItem';
import PricingList from '../../../../../../components/PricingList';
import Button from '../../../../../../components/Button';
import ErrorMessage from '../../../../../../components/FormItem/components/ErrorMessage';

import { useSettings } from '../../../../../../hooks/useSettings';
import { useAdvancePayment } from './useAdvancePayment';

function AdvancePayment({ disabled, formSchema }) {
    const { t } = useTranslation();
    const [, { getCurrencies }] = useSettings();

    const [{ field, meta, totals, toPay, isSurcharge }, { handleAddPayment }] = useAdvancePayment();

    return (
        <Grid.Row>
            <Grid.Col>
                <FieldArray
                    name="advancePayment"
                    render={(arrayHelpers) => (
                        <div className="overflow-x-auto">
                            <div className="divide-y divide-gray-200 ">
                                <div className="flex flex-row items-start justify-start gap-4 flex-nowrap">
                                    <div className="flex flex-col items-start justify-start w-56 pt-2 shrink-0" />
                                    <div className="flex flex-none w-48 pt-2">
                                        <span className="mb-1 text-xs font-semibold text-gray-700 uppercase dark:text-slate-300">
                                            {t('Date')}
                                        </span>
                                    </div>
                                    <div className="flex flex-none w-48 px-2 pt-2 bg-blue-200 dark:bg-slate-500 rounded-t-md">
                                        <span className="mb-1 text-xs font-semibold text-gray-700 uppercase dark:text-slate-300">
                                            {t('Amount')}
                                        </span>
                                    </div>
                                    <div className="flex flex-none pt-2 w-28">
                                        <span className="mb-1 text-xs font-semibold text-gray-700 uppercase dark:text-slate-300">
                                            {t('Currency')}
                                        </span>
                                    </div>
                                    <div className="flex flex-none w-16" />
                                </div>
                                {!!field.value && !!field.value.length && (
                                    <div className="divide-y divide-gray-200 ">
                                        {field.value.map((advance, index) => (
                                            <div
                                                key={index}
                                                className="flex flex-row items-start justify-start gap-4 flex-nowrap"
                                            >
                                                <div className="flex flex-col items-start justify-center w-56 py-4 mt-2 shrink-0 ">
                                                    {t('Advance')} {index + 1}
                                                </div>
                                                <div className="flex flex-none w-48 py-4">
                                                    <FormItem
                                                        name={`advancePayment.${index}.date`}
                                                        type="date"
                                                        disabled={disabled}
                                                        validationSchema={formSchema}
                                                        datePickerProps={{
                                                            appendToBody: true,
                                                        }}
                                                    />
                                                </div>
                                                <div className="flex flex-none w-48 px-2 py-4 bg-blue-200 dark:bg-slate-500">
                                                    <FormItem
                                                        name={`advancePayment.${index}.amount`}
                                                        type="price"
                                                        disabled={disabled}
                                                        validationSchema={formSchema}
                                                    />
                                                </div>
                                                <div className="flex flex-none py-4 w-28 ">
                                                    <FormItem
                                                        name={`advancePayment.${index}.currency`}
                                                        type="dropdown"
                                                        isDeletable={false}
                                                        options={getCurrencies()}
                                                        disabled={disabled}
                                                        validationSchema={formSchema}
                                                    />
                                                </div>
                                                <div className="flex items-start justify-center flex-none w-16 py-4 ">
                                                    <Button
                                                        outline
                                                        color="danger"
                                                        iconOnly
                                                        onClick={() => arrayHelpers.remove(index)}
                                                        title={t('Remove advance')}
                                                    >
                                                        <HiOutlineTrash className="text-xl" />
                                                    </Button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                                <div className="flex flex-row items-stretch justify-start gap-4 flex-nowrap bg-slate-200 dark:bg-slate-600 rounded-b-md w-fit md:w-full">
                                    <div className="flex flex-col items-start justify-center w-56 p-4 shrink-0">
                                        <Button onClick={() => handleAddPayment(arrayHelpers)}>
                                            {t('Add advance')}
                                        </Button>
                                    </div>
                                    <div className="flex flex-none w-48 py-4" />
                                    <div className="flex flex-none w-48 px-2 py-4 bg-blue-200 dark:bg-slate-500 rounded-b-md">
                                        <PricingList title={t('Advances total')} items={totals} />
                                    </div>
                                    <div className="flex flex-none w-64 px-2 py-4 rounded-b-md">
                                        <PricingList
                                            title={t('Left to pay')}
                                            items={toPay}
                                            className={`border-2 ${
                                                isSurcharge ? 'border-red-600' : 'border-green-600'
                                            }`}
                                            classNamePriceWrapper="text-[1.2rem]"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                />
                <ErrorMessage isInvalid={meta.error && meta.touched} message={meta.error} />
            </Grid.Col>
        </Grid.Row>
    );
}

AdvancePayment.propTypes = {
    disabled: PropTypes.bool,
    formSchema: PropTypes.object,
};

AdvancePayment.defaultProps = {
    disabled: false,
    formSchema: {},
};

export default AdvancePayment;
