import get from 'lodash/get';
import API from '../config/api';

const ABORT_REQUST_CODE = 'ERR_CANCELED';

export const sendRequest = async (url, params = {}) =>
    await API.request({ ...params, url }) // eslint-disable-line no-return-await
        .then((response) => response)
        .catch((error) => {
            if (error.code === ABORT_REQUST_CODE) {
                return {
                    data: {
                        success: false,
                        error: '',
                    },
                };
            }

            if (error.message === 'Network Error') {
                console.log(error); // eslint-disable-line no-console
                return {
                    data: {
                        success: false,
                        error: 'Network Error',
                    },
                };
            }
            if ([401, 403].includes(error.response.status) && window.location.pathname.includes('/admin')) {
                setTimeout(() => {
                    window.location.replace('/');
                }, 1500);
            }

            return {
                status: error.response.status,
                data: {
                    success: false,
                    error: get(error, 'response.data.error', error.message),
                },
            };
        });
