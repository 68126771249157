import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HiOutlinePencil, HiPlus } from 'react-icons/hi';
import get from 'lodash/get';
import set from 'lodash/set';
import Table from '../../../../components/Table';
import withStatusManager from '../../../../components/Table/components/withStatusManager';
import DescriptionList from '../../../../components/DescriptionList';
import urls from '../../../../setup/urls';

import { dictionaryApi } from '../../../../api';

function DeleteMessage({ item }) {
    const { t } = useTranslation();
    const { value } = item;
    return (
        <div>
            <p>{t('Are you sure you want to delete this item?')}</p>
            <div className="mt-3">
                <DescriptionList
                    items={[
                        {
                            label: t('Name'),
                            value,
                        },
                    ]}
                />
            </div>
        </div>
    );
}

const TableComponent = withStatusManager(Table);

function DictionaryTable({ id, group }) {
    const { t } = useTranslation();

    const tableConfig = {
        id,
        columns: React.useMemo(
            () => [
                {
                    field: 'value',
                    label: t('Name'),
                    type: 'string',
                    width: 'auto',
                    cellRenderer: ({ value, data }) => (
                        <div className="rgt-cell-inner">
                            <Link to={`${get(urls, 'dictionaryForm.route')}/${data.id}`} state={{ group }}>
                                {value}
                            </Link>
                        </div>
                    ),
                },
            ],
            [id, group],
        ),
        headerActions: [
            {
                label: 'Add item',
                icon: HiPlus,
                to: `${get(urls, 'dictionaryForm.route')}/create`,
                useSeparately: true,
                state: { group },
            },
        ],
        rowActions: [
            {
                label: 'Edit',
                icon: HiOutlinePencil,
                to: ({ data: { id } = {} }) => `${get(urls, 'dictionaryForm.route')}/${id}`,
                state: { group },
            },
        ],
        initConfig: {
            sort: { field: 'value', direct: 'ASC' },
        },
        getCustomPayload: (payload) => {
            if (group) {
                set(payload, 'filter.group', { value: group, operator: 'eq' });
            }
            return payload;
        },
        contextApi: dictionaryApi,
        deleteMessage: DeleteMessage,
        // withExport: true,
    };

    return <TableComponent {...tableConfig} />;
}

DictionaryTable.propTypes = {
    id: PropTypes.string.isRequired,
    group: PropTypes.string.isRequired,
};

export default DictionaryTable;
