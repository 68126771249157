import React from 'react';
import PropTypes from 'prop-types';
import { Popover as ReactPopover } from '@headlessui/react';
import { Float } from '@headlessui-float/react';
import { HiChevronDown } from 'react-icons/hi';
import classNames from 'classnames';
import '../Menu/styles.module.css';

function Popover({
    trigger,
    triggerTitle,
    position,
    hideChevron,
    isPortable,
    children,
    wrapperClassName,
    triggerClassName,
    itemsClassName,
}) {
    return (
        <ReactPopover as="div" className={classNames('menu-wrapper', wrapperClassName)}>
            <Float
                portal={isPortable}
                placement="bottom-start"
                offset={0}
                enter="transition  ease-out duration-200"
                enterFrom="scale-80 opacity-0"
                enterTo="scale-100 opacity-100"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                tailwindcssOriginClass
                adaptiveWidth
            >
                <div>
                    <ReactPopover.Button
                        className={classNames('menu-trigger-button', triggerClassName)}
                        title={triggerTitle}
                    >
                        {trigger && trigger()}
                        {!hideChevron && <HiChevronDown className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />}
                    </ReactPopover.Button>
                </div>
                <ReactPopover.Panel
                    className={classNames('menu-items', itemsClassName, `${position}-0 origin-top-${position} `)}
                >
                    {children}
                </ReactPopover.Panel>
            </Float>
        </ReactPopover>
    );
}

Popover.propTypes = {
    trigger: PropTypes.func,
    triggerTitle: PropTypes.string,
    position: PropTypes.oneOf(['right', 'left']),
    hideChevron: PropTypes.bool,
    isPortable: PropTypes.bool,
    wrapperClassName: PropTypes.string,
    triggerClassName: PropTypes.string,
    itemsClassName: PropTypes.string,
    children: PropTypes.any,
};
Popover.defaultProps = {
    trigger: null,
    triggerTitle: '',
    position: 'left',
    hideChevron: false,
    isPortable: true,
    wrapperClassName: '',
    triggerClassName: '',
    itemsClassName: '',
    children: null,
};
export default Popover;
