import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineSun, HiOutlineMoon } from 'react-icons/hi';

export const DARK_MODE_CLASS = 'dark';

function DarkModeSwitch() {
    const { t } = useTranslation();
    const [darkMode, toggleMode] = useState(localStorage.getItem('color-theme') === DARK_MODE_CLASS);

    useEffect(() => {
        if (darkMode) {
            localStorage.setItem('color-theme', DARK_MODE_CLASS);
            document.documentElement.classList.add(DARK_MODE_CLASS);
        } else {
            localStorage.setItem('color-theme', 'light');
            document.documentElement.classList.remove(DARK_MODE_CLASS);
        }
    }, [darkMode]);

    return (
        <button
            type="button"
            aria-label={t('Dark mode switcher')}
            onClick={() => toggleMode((state) => !state)}
            className="btn-icon p-2.5"
        >
            {darkMode ? <HiOutlineMoon /> : <HiOutlineSun />}
        </button>
    );
}

DarkModeSwitch.propTypes = {};

DarkModeSwitch.defaultProps = {};

export default DarkModeSwitch;
