export function saveBlobAsFile(blob, fileName) {
    const a = document.createElement('a');
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
}
export const createBlobFromResponse = (response, fileName, fileType) => {
    const contentType = fileType || response.headers['content-type'];
    const blob = new Blob([response.data], { type: contentType });

    saveBlobAsFile(blob, fileName);

    return response;
};
