import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { get, find } from 'lodash';
import { HiOutlinePencil, HiOutlineSave } from 'react-icons/hi';
import { Form, Formik } from 'formik';
import Yup from '../../utils/yup';
import Button from '../../components/Button';
import FormItem from '../../components/FormItem';
import Modal from '../../components/Modal';
import Grid from '../../components/Grid';
import Badge from '../../components/Badge';

import { useNotification } from '../../contexts/NotificationContext';
import { reservationsApi } from '../../api';

import { sendRequest } from '../../utils/request';

export function StatusModal({ open, loading, reservations, statuses, onClose, onSubmit }) {
    const { t } = useTranslation();

    const initValues = {
        status: '',
    };

    const formSchema = Yup.object().shape({
        status: Yup.string().required().max(255),
    });

    return (
        <Modal title={t('Change status')} className="" open={open} onClose={onClose}>
            <Formik initialValues={initValues} validationSchema={formSchema} enableReinitialize onSubmit={onSubmit}>
                {() => (
                    <Form>
                        <Grid.Container fluid noPadding>
                            <Grid.Row>
                                <Grid.Col>
                                    <p className="text-sm font-semibold">
                                        {t('Set new status for below reservations.')}
                                    </p>
                                    <div className="mt-3 overflow-y-auto divide-y divide-gray-200 dark:divide-gray-400 max-h-40">
                                        {reservations.map(({ reservationNo, status: statusId }) => {
                                            const status = get(
                                                find(statuses, ['value', parseInt(statusId, 10)]),
                                                'label',
                                                statusId,
                                            );
                                            const color = get(
                                                find(statuses, ['value', parseInt(statusId, 10)]),
                                                'color',
                                                'primary',
                                            );

                                            return (
                                                <div
                                                    key={reservationNo}
                                                    className="flex flex-row items-center justify-between gap-2 py-2"
                                                >
                                                    {reservationNo} <Badge color={color}>{t(status)}</Badge>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </Grid.Col>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Col>
                                    <FormItem
                                        name="status"
                                        label="New Status"
                                        type="dropdown"
                                        options={statuses}
                                        disabled={loading}
                                        validationSchema={formSchema}
                                    />
                                </Grid.Col>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Col>
                                    <div className="flex justify-end gap-4 pt-4 mt-4 border-t border-t-slate-300">
                                        <Button outline color="primary" disabled={loading} onClick={onClose}>
                                            {t('Cancel')}
                                        </Button>
                                        <Button
                                            type="submit"
                                            iconLeft={HiOutlineSave}
                                            color="primary"
                                            loading={loading}
                                        >
                                            {t('Save')}
                                        </Button>
                                    </div>
                                </Grid.Col>
                            </Grid.Row>
                        </Grid.Container>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
}

StatusModal.propTypes = {
    open: PropTypes.bool,
    loading: PropTypes.bool,
    reservations: PropTypes.array,
    statuses: PropTypes.array,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
};

StatusModal.defaultProps = {
    open: false,
    loading: false,
    reservations: [],
    statuses: [],
    onClose: () => {},
    onSubmit: () => {},
};

export const useChangeBulkStatus = ({ statuses }) => {
    const { t } = useTranslation();

    const [, { addSuccess, addError }] = useNotification();
    const [modalData, setModalData] = useState({
        open: false,
        loading: false,
        reservations: [],
        onSuccess: () => {},
    });

    const headerActions = [
        {
            type: 'divider',
            order: 101,
        },
        {
            label: 'Edit Statuses',
            icon: HiOutlinePencil,
            type: 'button',
            onClick: ({ asyncApi, rowsApi, rowSelectionApi }) => {
                const reservations = rowsApi.rows
                    .filter(({ id }) => rowSelectionApi.selectedRowsIds.includes(id))
                    .map(({ id, reservationNo, status }) => ({ id, reservationNo, status }));

                setModalData((state) => ({
                    ...state,
                    open: true,
                    reservations,
                    onSuccess: () => asyncApi.resetRows(),
                }));
            },
            disabled: ({ rowSelectionApi }) => rowSelectionApi.selectedRowsIds.length === 0,
            order: 110,
        },
    ];

    const handleSubmit = async (props) => {
        const { status } = props;

        setModalData((state) => ({
            ...state,
            loading: true,
        }));
        try {
            const response = await sendRequest(reservationsApi.setStatuses(), {
                method: 'PATCH',
                data: {
                    ids: modalData.reservations.map(({ id }) => id),
                    status,
                },
            });

            if (response.status !== 200) {
                addError(t(response.data.error));

                setModalData((state) => ({
                    ...state,
                    loading: false,
                }));
                return;
            }

            modalData.onSuccess();
            setModalData((state) => ({
                ...state,
                open: false,
                reservations: [],
                loading: false,
            }));
            addSuccess(t('Statuses were changed for selected reservations.'));
        } catch (e) {
            setModalData((state) => ({
                ...state,
                loading: false,
            }));
            addError(e);
        }
    };

    const modalProps = {
        ...modalData,
        statuses,
        onClose: () => {
            setModalData((state) => ({
                ...state,
                open: false,
                reservations: [],
            }));
        },
        onSubmit: handleSubmit,
    };

    return [{ headerActions, modalProps }, {}];
};
