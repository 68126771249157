import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { get, set, isEmpty, reduce } from 'lodash';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import Grid from '../../../Grid';
import Button from '../../../Button';
import InputFilterField from './components/InputFilterField';
import NumbeRangeFilterField from './components/NumbeRangeFilterField';
import DropdownFilterField from './components/DropdownFilterField';
import MultiDropdownFilterField from './components/MultiDropdownFilterField';
import DateRangeFilterField from './components/DateRangeFilterField';

const componentMap = {
    string: InputFilterField,
    number: NumbeRangeFilterField,
    dropdown: DropdownFilterField,
    multiDropdown: MultiDropdownFilterField,
    date: DateRangeFilterField,
};

function FilterForm({ columns, filters, onFilterChange, resetRows }) {
    const { t } = useTranslation();
    const handleFilterChange = (field, payload) => {
        onFilterChange(
            {
                ...filters,
                [field]: payload,
            },
            () => resetRows(),
        );
    };

    const handleClearAll = () => {
        onFilterChange({}, () => resetRows());
    };

    const filtersComponents = columns
        .filter(({ filterable = true }) => filterable)
        .map((column) => {
            const { type } = column;
            const Component = componentMap[type] || InputFilterField;

            return {
                column,
                Component,
            };
        });

    const initialValues = reduce(
        columns,
        (result, column) => {
            const defaultValue = column.type === 'multiDropdown' ? [] : '';
            set(result, column.field, get(filters, `${column.field}.value`, defaultValue));
            return result;
        },
        {},
    );

    return (
        <Formik initialValues={initialValues} validationSchema={Yup.object().shape({})} enableReinitialize>
            {() => (
                <Form>
                    <Grid.Container fluid>
                        <Grid.Row>
                            <Grid.Col>
                                <div className="flex justify-end">
                                    <Button outline size="sm" disabled={isEmpty(filters)} onClick={handleClearAll}>
                                        {t('Clear All')}
                                    </Button>
                                </div>
                            </Grid.Col>
                        </Grid.Row>
                        <Grid.Row>
                            {filtersComponents.map(({ column, Component }) => (
                                <Grid.Col md={6} key={column.field}>
                                    <Component column={column} filters={filters} onFilterChange={handleFilterChange} />
                                </Grid.Col>
                            ))}
                        </Grid.Row>
                    </Grid.Container>
                </Form>
            )}
        </Formik>
    );
}

FilterForm.propTypes = {
    columns: PropTypes.array,
    filters: PropTypes.object,
    onFilterChange: PropTypes.func,
    resetRows: PropTypes.func,
};

FilterForm.defaultProps = {
    columns: [],
    filters: {},
    onFilterChange: () => {},
    resetRows: () => {},
};

export default FilterForm;
