import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';

import Button from '../../../../../../components/Button';
import ButtonsWrapper from '../../../../../../components/ButtonsWrapper';
import Modal from '../../../../../../components/Modal';
import Grid from '../../../../../../components/Grid';

import FormItem from '../../../../../../components/FormItem';
import FormFields from '../../../../../ClientForm/components/Form/components/FormFields';
import { minInitValues, initValues } from '../../../../../ClientForm/components/Form/Form';
import { minFormSchema, formSchema } from '../../../../../ClientForm/components/Form/useForm';
import { useAddClientModal } from './useAddClientModal';

function AddClientModal({ open, onlyRequired, onClose, onSuccess, onFailure }) {
    const { t } = useTranslation();
    const { loading, handleSubmit } = useAddClientModal({ onClose, onSuccess, onFailure });

    return (
        <Modal title="Add Client" className="max-w-screen-lg" open={open} onClose={onClose}>
            <Formik
                initialValues={onlyRequired ? minInitValues : initValues}
                validationSchema={onlyRequired ? minFormSchema : formSchema}
                enableReinitialize
                onSubmit={handleSubmit}
            >
                {() => (
                    <Form>
                        {}
                        <Grid.Container fluid noPadding>
                            {onlyRequired ? (
                                <Grid.Row>
                                    <Grid.Col md={6} lg={9}>
                                        <FormItem
                                            name="lastName"
                                            label="LastName"
                                            disabled={loading}
                                            validationSchema={formSchema}
                                        />
                                    </Grid.Col>
                                    <Grid.Col md={6} lg={3}>
                                        <FormItem
                                            name="firstName"
                                            label="FirstName"
                                            disabled={loading}
                                            validationSchema={formSchema}
                                        />
                                    </Grid.Col>
                                    <Grid.Col md={6} lg={3}>
                                        <FormItem
                                            name="birthDate"
                                            label="BirthDate"
                                            type="date"
                                            disabled={loading}
                                            validationSchema={formSchema}
                                            datePickerProps={{
                                                withPortal: true,
                                            }}
                                        />
                                    </Grid.Col>
                                </Grid.Row>
                            ) : (
                                <FormFields loading={loading} formSchema={formSchema} />
                            )}
                            <Grid.Row>
                                <Grid.Col>
                                    <ButtonsWrapper>
                                        <Button type="submit" color="primary" loading={loading}>
                                            {t('Save')}
                                        </Button>
                                        <Button outline color="primary" disabled={loading} onClick={onClose}>
                                            {t('Cancel')}
                                        </Button>
                                    </ButtonsWrapper>
                                </Grid.Col>
                            </Grid.Row>
                        </Grid.Container>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
}

AddClientModal.propTypes = {
    open: PropTypes.bool,
    onlyRequired: PropTypes.bool,
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    onFailure: PropTypes.func,
};

AddClientModal.defaultProps = {
    open: false,
    onlyRequired: false,
    onClose: () => {},
    onSuccess: () => {},
    onFailure: () => {},
};

export default AddClientModal;
