import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useField } from 'formik';
import { omit } from 'lodash';
import { omitProps } from '../../constants';

function FormTextarea({ inputWrapperClassName, inputWrapperChildren, isInvalid, ...props }) {
    const { name } = props;
    const [field] = useField(name);

    const inputWrapperClass = classNames(inputWrapperClassName);

    const inputClass = classNames('', {
        '!border-red-600 focus:!ring-red-600/30': isInvalid,
    });

    return (
        <div className={inputWrapperClass}>
            <textarea id={name} className={inputClass} {...field} {...omit(props, omitProps)} />
            {inputWrapperChildren}
        </div>
    );
}

FormTextarea.propTypes = {
    name: PropTypes.string.isRequired,
    inputWrapperClassName: PropTypes.string,
    inputWrapperChildren: PropTypes.any,
    isInvalid: PropTypes.bool,
};

FormTextarea.defaultProps = {
    inputWrapperClassName: '',
    inputWrapperChildren: null,
    isInvalid: false,
};

export default FormTextarea;
