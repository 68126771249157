import React from 'react';
import PropTypes from 'prop-types';
import DescriptionList from '../../../DescriptionList';

function DeleteMessage({ message, descriptionItems }) {
    return (
        <div>
            <p>{message}</p>
            <div className="mt-3">
                <DescriptionList items={descriptionItems}
                />
            </div>
        </div>
    );
}

DeleteMessage.propTypes = {
    message: PropTypes.string,
    descriptionItems: PropTypes.array
};

DeleteMessage.defaultProps = {
    message: '',
    descriptionItems: []
};

export default DeleteMessage;