import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Form, Formik } from 'formik';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import urls from '../../../../setup/urls';
import FormItem from '../../../../components/FormItem';
import Grid from '../../../../components/Grid';
import Button from '../../../../components/Button';
import LinkButton from '../../../../components/LinkButton';
import ButtonsWrapper from '../../../../components/ButtonsWrapper';

import { formSchema, useForm } from './useForm';

const initValues = {
    name: '',
    rate: '',
    letter: '',
    currency: process.env.REACT_APP_DEFAULT_CURRENCY,
    netPrice: '',
};

function ServiceForm({ id, details, dictionaries }) {
    const { t } = useTranslation();
    const { loading, handleSubmit } = useForm({ id, details });

    return (
        <Formik
            initialValues={!isEmpty(details) ? details : initValues}
            validationSchema={formSchema}
            enableReinitialize
            onSubmit={handleSubmit}
        >
            {() => (
                <Form>
                    <Grid.Container fluid noPadding>
                        <Grid.Row>
                            <Grid.Col>
                                <FormItem name="name" label="Name" disabled={loading} validationSchema={formSchema} />
                            </Grid.Col>
                            <Grid.Col md={6}>
                                <FormItem
                                    name="rate"
                                    label="Rate"
                                    type="number"
                                    disabled={loading}
                                    validationSchema={formSchema}
                                />
                            </Grid.Col>
                            <Grid.Col md={6}>
                                <FormItem
                                    name="letter"
                                    label="Letter"
                                    disabled={loading}
                                    validationSchema={formSchema}
                                />
                            </Grid.Col>
                            <Grid.Col md={6}>
                                <FormItem
                                    name="netPrice"
                                    label="Net Price"
                                    type="number"
                                    disabled={loading}
                                    validationSchema={formSchema}
                                />
                            </Grid.Col>
                            <Grid.Col md={6}>
                                <FormItem
                                    name="currency"
                                    label="Currency"
                                    type="dropdown"
                                    options={get(dictionaries, 'currencies', [])}
                                    disabled={loading}
                                    validationSchema={formSchema}
                                />
                            </Grid.Col>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Col mobile={16}>
                                <ButtonsWrapper>
                                    <Button type="submit" color="primary" loading={loading}>
                                        {t('Save')}
                                    </Button>
                                    <LinkButton to={get(urls, 'services.route')} disabled={loading}>
                                        {t('Cancel')}
                                    </LinkButton>
                                </ButtonsWrapper>
                            </Grid.Col>
                        </Grid.Row>
                    </Grid.Container>
                </Form>
            )}
        </Formik>
    );
}

ServiceForm.propTypes = {
    id: PropTypes.string,
    details: PropTypes.object,
    dictionaries: PropTypes.shape({}),
};

ServiceForm.defaultProps = {
    id: null,
    details: {},
    dictionaries: {},
};

export default ServiceForm;
