import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import Yup from '../../../../utils/yup';
import { useNotification } from '../../../../contexts/NotificationContext';
import { sendRequest } from '../../../../utils/request';
import { passwordRegex } from '../../../../utils/password';
import { passwordStrong, passwordMatch } from '../../../../utils/validationMessages';
import { recaptchaVerify, register } from '../../../../api';

export const formSchema = Yup.object().shape({
    email: Yup.string().email().required().max(255),
    password: Yup.string().min(8).max(255).matches(passwordRegex, passwordStrong()).required(),
    repeatPassword: Yup.string()
        .required()
        .oneOf([Yup.ref('password'), null], passwordMatch()),
});

export const useForm = () => {
    const { t } = useTranslation();
    const [, { addError }] = useNotification();

    const recaptcha = useRef();
    const [loading, setLoading] = useState(false);
    const [isCreated, setIsCreated] = useState(false);

    const handleSubmit = async (values, actions) => {
        setLoading(true);
        try {
            const captchaValue = recaptcha.current.getValue();

            if (!captchaValue) {
                throw new Error('Invalid reCAPTCHA');
            }

            const recaptchaResponse = await sendRequest(recaptchaVerify(), {
                method: 'POST',
                data: { token: captchaValue },
            });

            if (recaptchaResponse.status === 200) {
                const response = await sendRequest(register(), {
                    method: 'POST',
                    data: { ...values },
                });
                actions.setSubmitting(false);
                setLoading(false);

                if (response.status === 200) {
                    setIsCreated(true);
                } else {
                    throw new Error(response.data.error);
                }
            } else {
                throw new Error('Invalid reCAPTCHA');
            }
        } catch (e) {
            addError(t(e.message));
            setLoading(false);
            recaptcha.current.reset();
        }
    };

    return {
        recaptcha,
        loading,
        isCreated,
        handleSubmit,
    };
};
