import React from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import { AiOutlineMenu } from 'react-icons/ai';

import classNames from 'classnames';

import { COPYRIGHTS } from '../../constants';
import Nav from './components/Nav';
import UserNav from './components/UserNav';
import DarkModeSwitch from '../../components/DarkModeSwitch';
import Logo from '../../components/Logo';
import LoadingMask from '../../components/LoadingMask';

import styles from './styles.module.scss';

function AdminLayout({
    loading,
    pageTitle,
    userName,
    userRole,
    asideIsOpen,
    navItems,
    userItems,
    logoProps,
    toggleAside,
}) {
    const contentClass = classNames(styles.content, { [styles['content-loading']]: loading });

    return (
        <section className="min-h-screen pt-11 lg:pt-14 bg-slate-50 dark:bg-slate-900">
            <header className="fixed top-0 left-0 right-0 z-50 flex items-stretch justify-between bg-white border-b shadow-md flex-nowrap h-11 lg:h-14 border-b-slate-300 dark:bg-slate-800 dark:border-b-slate-500">
                <div className="flex items-center justify-start pr-4 flex-nowrap">
                    <button
                        className="h-full p-4 lg:hidden hover:text-gray-800 dark:hover:text-gray-300"
                        type="button"
                        onClick={toggleAside}
                    >
                        <AiOutlineMenu />
                    </button>
                    <h1 className="px-4 m-0 lg:ml-[16rem] text-xl font-semibold truncate border-l border-l-slate-300 dark:border-l-slate-500">
                        {pageTitle}
                    </h1>
                </div>
                <div className="flex items-center justify-start flex-nowrap">
                    <DarkModeSwitch />
                    <UserNav name={userName} role={userRole} items={userItems} />
                </div>
            </header>
            <aside
                className={`${styles.aside} fixed top-0 -left-[16rem] lg:left-0 z-[100] flex justify-start items-stretch flex-nowrap flex-col min-h-screen w-[16rem] overflow-hidden transition-left duration-150 ease-in-out shadow-xl bg-gradient-to-t from-blue-700 to-blue-900`}
                data-open={asideIsOpen}
            >
                <header className="flex items-center justify-center px-8 bg-white border-b flex-nowrap h-11 lg:h-14 border-b-slate-300 dark:bg-slate-800 dark:border-b-slate-500">
                    <Logo {...logoProps} />
                </header>
                <div className="flex flex-col items-stretch flex-auto flex-nowrap">
                    <nav>
                        <Nav items={navItems} />
                    </nav>
                </div>
                <div className="mt-4 mb-2 px-2 text-[0.7rem] text-center text-slate-200 leading-3">
                    <img src="/sygnet-light.svg" width={20} className="mx-auto my-2" alt={process.env.REACT_APP_NAME} />
                    {COPYRIGHTS}
                </div>
            </aside>
            <main className={contentClass}>
                {loading && <LoadingMask />}
                <Outlet />
            </main>
            <div
                className={styles.backdrop}
                data-open={asideIsOpen}
                role="button"
                aria-label="backdrop"
                tabIndex={0}
                onClick={toggleAside}
                onKeyDown={toggleAside}
            />
        </section>
    );
}

AdminLayout.propTypes = {
    loading: PropTypes.bool,
    asideIsOpen: PropTypes.bool,
    pageTitle: PropTypes.string,
    userName: PropTypes.string,
    userRole: PropTypes.string,
    navItems: PropTypes.array,
    userItems: PropTypes.array,
    logoProps: PropTypes.object,
    toggleAside: PropTypes.func,
};

AdminLayout.defaultProps = {
    loading: false,
    asideIsOpen: false,
    pageTitle: '',
    userName: '',
    userRole: '',
    navItems: [],
    userItems: [],
    logoProps: {},
    toggleAside: () => {},
};

export default AdminLayout;
