import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { isEmpty, get } from 'lodash';

import { HiOutlinePencil, HiOutlinePrinter } from 'react-icons/hi';

import { useNotification } from '../../contexts/NotificationContext';
import { useConfigContext } from '../../contexts/ConfigContext';
import { useAuthContext } from '../../contexts/AuthContext';
import Grid from '../../components/Grid';
import Section from '../../components/Section';
import Button from '../../components/Button';
import LinkButton from '../../components/LinkButton';
import ButtonsWrapper from '../../components/ButtonsWrapper';
import withNoDetailsWrapper from '../../hocs/NoDetailsWrapper/withNoDetailsWrapper';
import { ReservationPdfPreview, downloadReservationPdf } from '../../components/ReservationPdf/ReservationPdf';

import { sendRequest } from '../../utils/request';
import urls from '../../setup/urls';
import { printReservation } from '../../api';
import styles from './styles.module.scss';

function ReservationPreview({ hasNoId }) {
    const { t } = useTranslation();
    const [, { addError }] = useNotification();
    const { id } = useParams();
    const [{ user }] = useAuthContext();
    const [, { setPageTitle }] = useConfigContext();
    const [loading, setLoading] = useState(false);
    const [details, setDetails] = useState({});

    useEffect(() => {
        const fetchDetails = async (id) => {
            setLoading(true);
            try {
                const response = await sendRequest(printReservation({ id }), {
                    method: 'GET',
                });

                if (response.status !== 200) {
                    if (response.status === 404) {
                        hasNoId();
                    } else {
                        addError(t(response.data.error));
                    }
                }

                setDetails(response.data.data);
                setLoading(false);
            } catch (e) {
                addError(t(e));
                setLoading(false);
            }
        };

        if (id) {
            fetchDetails(id);
        }
    }, [id]);

    useEffect(() => {
        setPageTitle(`${t('Reservations')} / ${t('Preview')}: ${get(details, 'reservation.reservationNo')}`);
    }, [details]);

    const handlePrint = async () => {
        await downloadReservationPdf({
            data: { ...details, user },
            name: get(details, 'reservation.reservationNo'),
            t,
        });
    };

    const statusId = get(details, 'reservation.status');

    return (
        <Grid.Container fluid>
            <Grid.Row>
                <Grid.Col>
                    <Section loading={loading}>
                        <ButtonsWrapper>
                            {statusId !== 3 && statusId !== 5 && (
                                <Button
                                    iconLeft={HiOutlinePrinter}
                                    color="primary"
                                    loading={loading}
                                    onClick={() => handlePrint()}
                                >
                                    {t('Print')}
                                </Button>
                            )}
                            {statusId !== 5 && (
                                <LinkButton
                                    iconLeft={HiOutlinePencil}
                                    to={`${get(urls, 'reservationForm.route')}/${id}`}
                                    disabled={loading}
                                >
                                    {t('Edit')}
                                </LinkButton>
                            )}
                            <LinkButton to={get(urls, 'reservations.route')} disabled={loading}>
                                {t('Cancel')}
                            </LinkButton>
                        </ButtonsWrapper>
                    </Section>
                    {!isEmpty(details) && (
                        <div className={styles.reservationPreview}>
                            <ReservationPdfPreview data={{ ...details, user }} />
                        </div>
                    )}
                </Grid.Col>
            </Grid.Row>
        </Grid.Container>
    );
}

export default withNoDetailsWrapper(ReservationPreview);
