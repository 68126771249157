import React from 'react';
import PropTypes from 'prop-types';
import { forEach } from 'lodash';

const COLUMNS_NUMBER = 12;
const BREAKPOINT = ['sm', 'md', 'lg', 'xl', '2xl'];

function Col({ children, className, ...restProps }) {
    const getClassName = () => {
        const colClassName = ['w-full p-2 -mb-2 lg:p-4 lg:-mb-4'];

        forEach(BREAKPOINT, (breakpoint) => {
            if (restProps[breakpoint] !== COLUMNS_NUMBER) {
                colClassName.push(`${breakpoint}:w-${restProps[breakpoint]}/12`);
            }
        });

        colClassName.push(className);
        return colClassName.join(' ');
    };
    return <div className={getClassName()}>{children}</div>;
}

Col.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
    '2xl': PropTypes.number,
};

Col.defaultProps = {
    children: null,
    className: '',
    sm: COLUMNS_NUMBER,
    md: COLUMNS_NUMBER,
    lg: COLUMNS_NUMBER,
    xl: COLUMNS_NUMBER,
    '2xl': COLUMNS_NUMBER,
};
export default Col;
