import React from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';

import BaseLayout from '../BaseLayout';
import LoadingMask from '../../components/LoadingMask';
import Logo from '../../components/Logo';
import Section from '../../components/Section';
import DarkModeSwitch from '../../components/DarkModeSwitch';

function NotLoggedLayout({ loading, children, background }) {
    return (
        <BaseLayout background={background}>
            <div className="relative z-10 flex items-center justify-center min-h-screen p-4 pb-8">
                <Section className="relative max-w-lg overflow-hidden">
                    <div className="absolute top-0 right-0 m-2">
                        <DarkModeSwitch />
                    </div>
                    <div className="flex items-center justify-center mx-auto mt-0 mb-4">
                        <Logo />
                    </div>
                    {loading && <LoadingMask />}
                    <Outlet />
                    {children}
                </Section>
            </div>
        </BaseLayout>
    );
}

NotLoggedLayout.propTypes = {
    loading: PropTypes.bool,
    children: PropTypes.any,
    background: PropTypes.node,
};

NotLoggedLayout.defaultProps = {
    loading: false,
    children: null,
    background: null,
};

export default NotLoggedLayout;
