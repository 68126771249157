import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import FormItem from '../../../../../../components/FormItem';
import Grid from '../../../../../../components/Grid';
import Header from '../../../../../../components/Header';
import { countries } from '../../../../../../utils/dictionaries';

function FormFields({ loading, formSchema }) {
    const { t } = useTranslation();

    return (
        <Fragment>
            <Grid.Row>
                <Grid.Col>
                    <Header as="h3" dividing>
                        {t('Basic')}
                    </Header>
                    <Grid.Row>
                        <Grid.Col md={6} lg={6}>
                            <FormItem
                                name="lastName"
                                label={`${t('Last name')}/${t('Company')}`}
                                disabled={loading}
                                validationSchema={formSchema}
                            />
                        </Grid.Col>
                        <Grid.Col md={6} lg={6}>
                            <FormItem
                                name="firstName"
                                label="First name"
                                disabled={loading}
                                validationSchema={formSchema}
                            />
                        </Grid.Col>
                        <Grid.Col md={6} lg={4}>
                            <FormItem
                                name="birthDate"
                                label="Birth Date"
                                type="date"
                                disabled={loading}
                                validationSchema={formSchema}
                            />
                        </Grid.Col>
                    </Grid.Row>
                </Grid.Col>
            </Grid.Row>
            <Grid.Row>
                <Grid.Col>
                    <Header as="h3" dividing>
                        {t('Contact')}
                    </Header>
                    <Grid.Row>
                        <Grid.Col md={6}>
                            <FormItem
                                name="email"
                                label="Email"
                                type="email"
                                disabled={loading}
                                validationSchema={formSchema}
                            />
                        </Grid.Col>
                        <Grid.Col md={6}>
                            <FormItem
                                name="email2"
                                label="Email 2"
                                type="email"
                                disabled={loading}
                                validationSchema={formSchema}
                            />
                        </Grid.Col>
                        <Grid.Col md={6}>
                            <FormItem
                                name="phone"
                                label="Phone"
                                type="tel"
                                disabled={loading}
                                validationSchema={formSchema}
                            />
                        </Grid.Col>
                        <Grid.Col md={6}>
                            <FormItem name="phone2" label="Phone 2" disabled={loading} validationSchema={formSchema} />
                        </Grid.Col>
                    </Grid.Row>
                </Grid.Col>
            </Grid.Row>
            <Grid.Row>
                <Grid.Col>
                    <Header as="h3" dividing>
                        {t('Address')}
                    </Header>
                    <Grid.Row>
                        <Grid.Col lg={9}>
                            <FormItem name="street" label="Street" disabled={loading} validationSchema={formSchema} />
                        </Grid.Col>
                        <Grid.Col md={4} lg={3}>
                            <FormItem
                                name="postcode"
                                label="Postcode"
                                disabled={loading}
                                validationSchema={formSchema}
                            />
                        </Grid.Col>
                        <Grid.Col md={8} lg={6}>
                            <FormItem name="city" label="City" disabled={loading} validationSchema={formSchema} />
                        </Grid.Col>
                        <Grid.Col lg={6}>
                            <FormItem
                                name="country"
                                label="Country"
                                type="dropdown"
                                options={countries}
                                disabled={loading}
                                validationSchema={formSchema}
                            />
                        </Grid.Col>
                    </Grid.Row>
                </Grid.Col>
            </Grid.Row>
            <Grid.Row>
                <Grid.Col>
                    <Header as="h3" dividing>
                        {t('Passport')}
                    </Header>
                    <Grid.Row>
                        <Grid.Col md={6} lg={4}>
                            <FormItem
                                name="passport"
                                label="No Passport"
                                disabled={loading}
                                validationSchema={formSchema}
                            />
                        </Grid.Col>
                        <Grid.Col md={6} lg={4}>
                            <FormItem
                                name="passportDate"
                                label="Passport Date"
                                type="date"
                                disabled={loading}
                                validationSchema={formSchema}
                            />
                        </Grid.Col>
                        <Grid.Col md={6} lg={4}>
                            <FormItem
                                name="nationality"
                                label="Nationality"
                                disabled={loading}
                                validationSchema={formSchema}
                            />
                        </Grid.Col>
                    </Grid.Row>
                </Grid.Col>
            </Grid.Row>
            <Grid.Row>
                {' '}
                <Grid.Col>
                    <Header as="h3" dividing>
                        {t('Other')}
                    </Header>
                    <Grid.Row>
                        <Grid.Col>
                            <FormItem
                                name="innerNotes"
                                label="Inner Notes"
                                type="wysiwig"
                                disabled={loading}
                                validationSchema={formSchema}
                                editorClassName="min-h-[400px]"
                            />
                        </Grid.Col>
                    </Grid.Row>
                </Grid.Col>
            </Grid.Row>
        </Fragment>
    );
}

FormFields.propTypes = {
    loading: PropTypes.bool,
    formSchema: PropTypes.object,
};

FormFields.defaultProps = {
    loading: false,
    formSchema: {},
};

export default FormFields;
