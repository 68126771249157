import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import { HiOutlineTrash } from 'react-icons/hi';

import { Form, Formik } from 'formik';
import FormItem from '../../../../components/FormItem';
import Button from '../../../../components/Button';
import ButtonsWrapper from '../../../../components/ButtonsWrapper';
import Grid from '../../../../components/Grid';
import Header from '../../../../components/Header';
import Dropzone from '../../../../components/Dropzone';
import DropzonePlaceholder from './components/DropzonePlaceholder';

import { formSchema, useForm } from './useForm';

const initValues = {
    name: '',
    address: '',
    bankName: '',
    accountNumber: '',
    nip: '',
    regon: '',
    email: '',
    email2: '',
    phone: '',
    phone2: '',
    licenseNumber: '',
    insuranceGuaranteeNumber: '',
};

function CompanyForm({ details }) {
    const { t } = useTranslation();
    const { loading, currentLogo, uploadedLogo, handleUploadLogo, handleRemoveLogo, handleSubmit } = useForm({
        details,
    });

    const data = JSON.parse(get(details, 'data', '{}'));

    return (
        <Formik
            initialValues={{ ...initValues, ...data }}
            validationSchema={formSchema}
            enableReinitialize
            onSubmit={handleSubmit}
        >
            {() => (
                <Form>
                    <Grid.Container fluid>
                        <Grid.Row>
                            <Grid.Col>
                                <Header as="h3" dividing>
                                    {t('Basic')}
                                </Header>
                                <Grid.Row>
                                    <Grid.Col md={4}>
                                        <FormItem
                                            name="name"
                                            label="Name"
                                            disabled={loading}
                                            validationSchema={formSchema}
                                        />
                                    </Grid.Col>
                                    <Grid.Col md={8}>
                                        <FormItem
                                            name="address"
                                            label="Address"
                                            disabled={loading}
                                            validationSchema={formSchema}
                                        />
                                    </Grid.Col>
                                </Grid.Row>
                            </Grid.Col>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Col>
                                <Header as="h3" dividing>
                                    {t('BankInfo')}
                                </Header>
                                <Grid.Row>
                                    <Grid.Col md={6}>
                                        <FormItem
                                            name="bankName"
                                            label="Bank Name"
                                            disabled={loading}
                                            validationSchema={formSchema}
                                        />
                                    </Grid.Col>
                                    <Grid.Col md={6}>
                                        <FormItem
                                            name="accountNumber"
                                            label="Account Number"
                                            disabled={loading}
                                            validationSchema={formSchema}
                                        />
                                    </Grid.Col>
                                    <Grid.Col md={6}>
                                        <FormItem
                                            name="nip"
                                            label="Nip"
                                            disabled={loading}
                                            validationSchema={formSchema}
                                        />
                                    </Grid.Col>
                                    <Grid.Col md={6}>
                                        <FormItem
                                            name="regon"
                                            label="Regon"
                                            disabled={loading}
                                            validationSchema={formSchema}
                                        />
                                    </Grid.Col>
                                </Grid.Row>
                            </Grid.Col>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Col>
                                <Header as="h3" dividing>
                                    {t('Contact')}
                                </Header>
                                <Grid.Row>
                                    <Grid.Col md={6}>
                                        <FormItem
                                            name="email"
                                            label="Email"
                                            disabled={loading}
                                            validationSchema={formSchema}
                                        />
                                    </Grid.Col>
                                    <Grid.Col md={6}>
                                        <FormItem
                                            name="email2"
                                            label="Email 2"
                                            disabled={loading}
                                            validationSchema={formSchema}
                                        />
                                    </Grid.Col>
                                    <Grid.Col md={6}>
                                        <FormItem
                                            name="phone"
                                            label="Phone"
                                            disabled={loading}
                                            validationSchema={formSchema}
                                        />
                                    </Grid.Col>
                                    <Grid.Col md={6}>
                                        <FormItem
                                            name="phone2"
                                            label="Phone 2"
                                            disabled={loading}
                                            validationSchema={formSchema}
                                        />
                                    </Grid.Col>
                                </Grid.Row>
                            </Grid.Col>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Col>
                                <Header as="h3" dividing>
                                    {t('Other')}
                                </Header>
                                <Grid.Row>
                                    <Grid.Col md={6}>
                                        <FormItem
                                            name="licenseNumber"
                                            label="License number"
                                            disabled={loading}
                                            validationSchema={formSchema}
                                        />
                                    </Grid.Col>
                                    <Grid.Col md={6}>
                                        <FormItem
                                            name="insuranceGuaranteeNumber"
                                            label="Insurance guarantee number"
                                            disabled={loading}
                                            validationSchema={formSchema}
                                        />
                                    </Grid.Col>
                                </Grid.Row>
                            </Grid.Col>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Col>
                                <Header as="h3" dividing>
                                    {t('Logo')}
                                </Header>
                                <Grid.Row>
                                    <Grid.Col md={6} lg={3} className="-mb-0 lg:-mb-0">
                                        {uploadedLogo || currentLogo ? (
                                            <div className="h-[192px] relative p-4 rounded-lg border border-slate-300 dark:border-slate-600 dark:bg-slate-600">
                                                <img
                                                    className="object-scale-down w-full h-full"
                                                    src={uploadedLogo ? URL.createObjectURL(uploadedLogo) : currentLogo}
                                                    alt=""
                                                />
                                                <div className="absolute right-2 bottom-2">
                                                    <Button
                                                        size="sm"
                                                        color="danger"
                                                        outline
                                                        onClick={handleRemoveLogo}
                                                        title={t('Delete')}
                                                        iconOnly
                                                    >
                                                        <HiOutlineTrash className="text-xl" />
                                                    </Button>
                                                </div>
                                            </div>
                                        ) : (
                                            <Dropzone
                                                config={{
                                                    accept: {
                                                        'image/jpeg': [],
                                                        'image/png': [],
                                                    },
                                                    maxSize: 2 * 1024 * 1024, // 2mb
                                                    multiple: false,
                                                }}
                                                placeholderMessage={DropzonePlaceholder}
                                                setFiles={handleUploadLogo}
                                            />
                                        )}
                                    </Grid.Col>
                                </Grid.Row>
                            </Grid.Col>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Col>
                                <ButtonsWrapper>
                                    <Button type="submit" color="primary" loading={loading}>
                                        {t('Save')}
                                    </Button>
                                </ButtonsWrapper>
                            </Grid.Col>
                        </Grid.Row>
                    </Grid.Container>
                </Form>
            )}
        </Formik>
    );
}

CompanyForm.propTypes = {
    details: PropTypes.object,
};

CompanyForm.defaultProps = {
    details: {},
};

export default CompanyForm;
