import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import get from 'lodash/get';
import { useNotification } from '../../contexts/NotificationContext';
import Form from './components/Form';
import Grid from '../../components/Grid';
import Section from '../../components/Section';
import withNoDetailsWrapper from '../../hocs/NoDetailsWrapper/withNoDetailsWrapper';
import { useConfigContext } from '../../contexts/ConfigContext';
import { sendRequest } from '../../utils/request';

import { getAgent } from '../../api';

function AgentForm({ hasNoId }) {
    const { t } = useTranslation();
    const [, { addError }] = useNotification();
    const { id } = useParams();
    const [, { setPageTitle }] = useConfigContext();
    const [details, setDetails] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchDetails = async (id) => {
            setLoading(true);
            try {
                const response = await sendRequest(getAgent({ id }), {
                    method: 'GET',
                });

                if (response.status !== 200) {
                    if (response.status === 404) {
                        hasNoId();
                    } else {
                        addError(t(response.data.error));
                    }
                }

                setDetails(response.data.data);
                setLoading(false);
            } catch (e) {
                addError(t(e));
                setLoading(false);
            }
        };

        if (id && id !== 'create') {
            fetchDetails(id);
        }
    }, [id]);

    useEffect(() => {
        setPageTitle(`${t('Agents')} / ${get(details, 'name', t('New'))}`);
    }, [details]);

    return (
        <Grid.Container fluid>
            <Grid.Row className="justify-center">
                <Grid.Col md={10} lg={8}>
                    <Section loading={loading}>
                        <Form details={details} id={id !== 'create' ? id : null} />
                    </Section>
                </Grid.Col>
            </Grid.Row>
        </Grid.Container>
    );
}

export default withNoDetailsWrapper(AgentForm);
