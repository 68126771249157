import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { HiOutlineUpload } from 'react-icons/hi';
import Button from '../../../Button';
import Dropzone from '../../../Dropzone';
import Modal from '../../../Modal';

function ImportModal({ config, isOpen, onClose, onSubmit }) {
    const { data = [], fileName = 'example.csv', title = 'Import Items' } = config;

    const [files, setFiles] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        setFiles(null);
    }, [isOpen]);

    const handleSubmit = () => {
        onSubmit({ file: files[0] });
    };

    return (
        <Modal open={isOpen} onClose={onClose} title={title}>
            <div>
                <Dropzone
                    config={{
                        accept: {
                            'text/csv': ['.csv'],
                        },
                        multiple: false,
                    }}
                    placeholderMessage="Drag 'n' drop csv file here, or click to select files"
                    setFiles={setFiles}
                />
                <p className="mt-1 mb-0 text-sm">
                    {t('Download a')}
                    <CSVLink data={data} filename={fileName}>
                        {t('sample CSV file')}
                    </CSVLink>
                </p>
            </div>
            <div className="flex justify-end gap-4 pt-4 mt-4 border-t border-t-slate-300">
                <Button outline onClick={onClose}>
                    {t('Cancel')}
                </Button>
                <Button color="primary" iconLeft={HiOutlineUpload} onClick={handleSubmit}>
                    {t('Import')}
                </Button>
            </div>
        </Modal>
    );
}

ImportModal.propTypes = {
    isOpen: PropTypes.bool,
    config: PropTypes.object,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
};

ImportModal.defaultProps = {
    isOpen: false,
    config: {},
    onClose: () => {},
    onSubmit: () => {},
};

export default ImportModal;
