import React from 'react';
import PropTypes from 'prop-types';

function ButtonsWrapper({ children }) {
    return (
        <div className="flex flex-wrap gap-4 flex-col md:flex-row justify-start items-stretch md:items-center">
            {children}
        </div>
    );
}

ButtonsWrapper.propTypes = {
    children: PropTypes.any,
};

ButtonsWrapper.defaultProps = {
    children: null,
};

export default ButtonsWrapper;
