import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const COLOR_MAP = {
    primary: 'blue',
    secondary: 'cyan',
    success: 'emerald',
    danger: 'red',
    warning: 'amber',
    dark: 'gray',
};

function Badge({ color, className, outline, size, iconLeft, iconRight, children }) {
    const mappedColor = COLOR_MAP[color];
    const rootClassName = classNames(
        `text-center tracking-wide cursor-default font-semibold uppercase shadow-sm rounded-md border`,
        `border-${mappedColor}-400 text-${mappedColor}-600 dark:text-${mappedColor}-400`,
        className,
        {
            'text-xs px-2 py-0.5': size === 'sm',
            'text-sm px-2.5 py-0.5': size === 'md',
            'text-md px-3 py-1': size === 'lg',
            [`bg-${mappedColor}-100 dark:bg-${mappedColor}-700`]: !outline,
            [`bg-transparent`]: outline,
        },
    );

    const IconLeft = iconLeft;
    const IconRight = iconRight;

    return (
        <span className={rootClassName}>
            {iconLeft && <IconLeft className="mr-2 text-xl" />}
            <span>{children}</span>
            {iconRight && <IconRight className="ml-2 text-xl" />}
        </span>
    );
}

Badge.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    iconLeft: PropTypes.any,
    iconRight: PropTypes.any,
    outline: PropTypes.bool,
    color: PropTypes.oneOf(['primary', 'secondary', 'success', 'danger', 'warning', 'link']),
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
};

Badge.defaultProps = {
    children: null,
    className: '',
    iconLeft: null,
    iconRight: null,
    outline: false,
    color: 'primary',
    size: 'sm',
};
export default Badge;
