import { useToasts } from 'react-toast-notifications';

const useNotification = () => {
    const { addToast } = useToasts();

    const addSuccess = (message) => addToast(message, { appearance: 'success' });
    const addError = (message) => addToast(message, { appearance: 'error' });
    const addWarning = (message) => addToast(message, { appearance: 'warning' });
    const addInfo = (message) => addToast(message, { appearance: 'info' });

    return [{}, { addSuccess, addError, addWarning, addInfo }];
};

export default useNotification;
