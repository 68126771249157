import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Form, Formik } from 'formik';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import urls from '../../../../setup/urls';
import FormItem from '../../../../components/FormItem';
import Grid from '../../../../components/Grid';
import Button from '../../../../components/Button';
import LinkButton from '../../../../components/LinkButton';
import ButtonsWrapper from '../../../../components/ButtonsWrapper';

import { formSchema, useForm } from './useForm';

const initValues = {
    name: '',
    issuer: '',
    nip: '',
    email: '',
    phone: '',
    address: '',
};

function ServiceForm({ id, details }) {
    const { t } = useTranslation();
    const { loading, handleSubmit } = useForm({ id, details });

    return (
        <Formik
            initialValues={!isEmpty(details) ? details : initValues}
            validationSchema={formSchema}
            enableReinitialize
            onSubmit={handleSubmit}
        >
            {() => (
                <Form>
                    <Grid.Container fluid noPadding>
                        <Grid.Row>
                            <Grid.Col>
                                <FormItem name="name" label="Name" disabled={loading} validationSchema={formSchema} />
                            </Grid.Col>
                            <Grid.Col md={6}>
                                <FormItem
                                    name="issuer"
                                    label="Issuer"
                                    disabled={loading}
                                    validationSchema={formSchema}
                                />
                            </Grid.Col>
                            <Grid.Col md={6}>
                                <FormItem name="nip" label="Nip" disabled={loading} validationSchema={formSchema} />
                            </Grid.Col>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Col md={6}>
                                <FormItem name="email" label="Email" disabled={loading} validationSchema={formSchema} />
                            </Grid.Col>
                            <Grid.Col md={6}>
                                <FormItem name="phone" label="Phone" disabled={loading} validationSchema={formSchema} />
                            </Grid.Col>
                            <Grid.Col>
                                <FormItem
                                    name="address"
                                    label="Address"
                                    disabled={loading}
                                    validationSchema={formSchema}
                                />
                            </Grid.Col>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Col>
                                <ButtonsWrapper>
                                    <Button type="submit" color="primary" loading={loading}>
                                        {t('Save')}
                                    </Button>
                                    <LinkButton to={get(urls, 'agents.route')} disabled={loading}>
                                        {t('Cancel')}
                                    </LinkButton>
                                </ButtonsWrapper>
                            </Grid.Col>
                        </Grid.Row>
                    </Grid.Container>
                </Form>
            )}
        </Formik>
    );
}

ServiceForm.propTypes = {
    id: PropTypes.string,
    details: PropTypes.object,
};

ServiceForm.defaultProps = {
    id: null,
    details: {},
};

export default ServiceForm;
