import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import get from 'lodash/get';
import urls from '../../../../setup/urls';
import FormItem from '../../../../components/FormItem';
import Button from '../../../../components/Button';

import { formSchema, useForm } from './useForm';

function ForgotPasswordForm(props) {
    const { t } = useTranslation();
    const { loading, handleSubmit } = useForm(props);

    return (
        <Formik
            initialValues={{
                password: '',
                repeatPassword: '',
            }}
            validationSchema={formSchema}
            onSubmit={handleSubmit}
        >
            {() => (
                <Form className="ui form">
                    <FormItem
                        name="password"
                        label="Password"
                        type="password"
                        disabled={loading}
                        validationSchema={formSchema}
                    />
                    <FormItem
                        name="repeatPassword"
                        label="Repeat Password"
                        type="password"
                        disabled={loading}
                        validationSchema={formSchema}
                    />
                    <div className="flex flex-row items-center justify-between">
                        <Button type="submit" color="primary" loading={loading}>
                            {t('Reset password')}
                        </Button>
                        <Link to={get(urls, 'login.route')}>{t('Back to login page')}</Link>
                    </div>
                </Form>
            )}
        </Formik>
    );
}
export default ForgotPasswordForm;
