import { useCallback, useState } from 'react';
import { debounce } from 'lodash';
import * as Yup from 'yup';

export const formSchema = Yup.object().shape({
    search: Yup.string().min(3).max(255),
});

export const useSearchForm = ({ filters, onFilterChange, resetRows }) => {
    const [value, setValue] = useState('');

    function handleDebounceFn(value) {
        onFilterChange(
            {
                ...filters,
                search: { value, operator: 'like' },
            },
            () => resetRows(),
        );
    }
    const debounceFn = useCallback(debounce(handleDebounceFn, 500), [filters]);

    const handleChange = (event) => {
        setValue(event.target.value);
        debounceFn(event.target.value);
    };

    return {
        value,
        handleChange,
    };
};
