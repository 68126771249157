import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { reduce } from 'lodash';
import { useNotification } from '../NotificationContext';
import { useAuthContext } from '../AuthContext';

import { getSettings } from '../../api';
import { sendRequest } from '../../utils/request';

const useSettings = () => {
    const { t } = useTranslation();
    const [, { addError }] = useNotification();
    const [{ isLogged }] = useAuthContext();
    const [settings, setSettings] = useState({});

    useEffect(() => {
        const fetchDictionary = async () => {
            try {
                const response = await sendRequest(getSettings(), {
                    method: 'get',
                });

                if (response.status !== 200) {
                    addError(t(response.data.error));
                }

                setSettings(
                    reduce(
                        response.data.data,
                        (result, { code, data }) => ({
                            ...result,
                            [code]: JSON.parse(data),
                        }),
                        {},
                    ),
                );
            } catch (e) {
                addError(t(e));
            }
        };
        if (isLogged) fetchDictionary();
    }, [isLogged]);

    const updateSettings = (code, data) => {
        setSettings((state) => ({
            ...state,
            [code]: data,
        }));
    };

    return [{ settings }, { updateSettings }];
};

export default useSettings;
