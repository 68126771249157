import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

function HintMessage({ message }) {
    const { t } = useTranslation();
    return <span className="flex mt-1 text-xs text-gray-600 dark:text-slate-300">{t(message)}</span>;
}

HintMessage.propTypes = {
    message: PropTypes.string,
};

HintMessage.defaultProps = {
    message: '',
};

export default HintMessage;
