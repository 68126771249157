import React from 'react';

import AuthContextProvider from './AuthContext';
import ConfigContextProvider from './ConfigContext';
import SettingsContextProvider from './SettingsContext';
import NotificationContextProvider from './NotificationContext';

const contextProviders = [
    NotificationContextProvider,
    AuthContextProvider,
    SettingsContextProvider,
    ConfigContextProvider,
];

function AppContextProvider({ children }) {
    return contextProviders.reduceRight((memo, ContextProvider) => <ContextProvider>{memo}</ContextProvider>, children);
}

export default AppContextProvider;
