import React from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Formik } from 'formik';
import Button from '../../../../components/Button';
import ButtonsWrapper from '../../../../components/ButtonsWrapper';
import FormItem from '../../../../components/FormItem';

import { formSchema, useForm } from './useForm';

function ChangePasswordForm() {
    const { t } = useTranslation();
    const { loading, handleSubmit } = useForm();

    return (
        <Formik
            initialValues={{
                password: '',
                newPassword: '',
                confirmPassword: '',
            }}
            validationSchema={formSchema}
            onSubmit={handleSubmit}
        >
            {() => (
                <Form autoComplete="off">
                    <FormItem
                        name="password"
                        label="Current Password"
                        type="password"
                        autocomplete="off"
                        disabled={loading}
                        validationSchema={formSchema}
                    />
                    <FormItem
                        name="newPassword"
                        label="New Password"
                        type="password"
                        autocomplete="off"
                        disabled={loading}
                        validationSchema={formSchema}
                    />
                    <FormItem
                        name="confirmPassword"
                        label="Repeat Password"
                        type="password"
                        autocomplete="off"
                        disabled={loading}
                        validationSchema={formSchema}
                    />
                    <div className="field">
                        <ButtonsWrapper>
                            <Button type="submit" color="primary" loading={loading}>
                                {t('Save')}
                            </Button>
                        </ButtonsWrapper>
                    </div>
                </Form>
            )}
        </Formik>
    );
}
export default ChangePasswordForm;
