import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Dialog } from '@headlessui/react';
import { Float } from '@headlessui-float/react';
import { HiX } from 'react-icons/hi';
import '../Menu/styles.module.css';

function Modal({ title, className, children, open, onClose }) {
    const { t } = useTranslation();
    return (
        <Float
            portal
            placement="bottom-start"
            offset={0}
            enter="transition duration-1000 ease-out"
            enterFrom="scale-20 opacity-0"
            enterTo="scale-100 opacity-100"
            leave="transition ease-in duration-1000"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            tailwindcssOriginClass
            show={open}
        >
            <Dialog as="div" className="" open={open} onClose={onClose}>
                <div className="fixed z-[100] inset-0 bg-black bg-opacity-25" />
                <div className="fixed z-[101] inset-0 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-full p-4 text-center">
                        <Dialog.Panel
                            className={`w-full max-w-md p-4 text-left align-middle transition-all transform bg-white rounded-lg shadow-xl dark:bg-slate-700 ${className}`}
                        >
                            <button
                                type="button"
                                className="absolute btn-icon top-2 right-2 dark:hover:bg-slate-800"
                                onClick={onClose}
                            >
                                <HiX />
                                <span className="sr-only">{t('Close')}</span>
                            </button>
                            <Dialog.Title
                                as="h3"
                                className="pr-6 mb-4 text-lg font-medium leading-6 text-gray-900 dark:text-gray-300"
                            >
                                {t(title)}
                            </Dialog.Title>
                            {children}
                        </Dialog.Panel>
                    </div>
                </div>
            </Dialog>
            <div />
        </Float>
    );
}

Modal.propTypes = {
    title: PropTypes.any,
    children: PropTypes.any,
    className: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func,
};
Modal.defaultProps = {
    title: '',
    children: null,
    className: null,
    open: false,
    onClose: null,
};

export default Modal;
