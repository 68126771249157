import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HiOutlinePencil, HiPlus } from 'react-icons/hi';
import get from 'lodash/get';
import { useConfigContext } from '../../contexts/ConfigContext';
import Table from '../../components/Table';
import EmailCell from '../../components/Table/components/EmailCell';
import PhoneCell from '../../components/Table/components/PhoneCell';
import withStatusManager from '../../components/Table/components/withStatusManager';
import Grid from '../../components/Grid';
import DescriptionList from '../../components/DescriptionList';
import urls from '../../setup/urls';

import { agentApi } from '../../api';

/* eslint-disable react/prop-types */
function NameCell({ value, data }) {
    return (
        <div className="rgt-cell-inner">
            <Link to={`${get(urls, 'agentForm.route')}/${data.id}`}>{value}</Link>
        </div>
    );
}

function DeleteMessage({ item }) {
    const { t } = useTranslation();
    const { name } = item;
    return (
        <div>
            <p>{t('Are you sure you want to delete this agent?')}</p>
            <div className="mt-3">
                <DescriptionList
                    items={[
                        {
                            label: t('Name'),
                            value: name,
                        },
                    ]}
                />
            </div>
        </div>
    );
}
/* eslint-enable react/prop-types */

const TableComponent = withStatusManager(Table);

function Agents() {
    const { t } = useTranslation();
    const [, { setPageTitle }] = useConfigContext();

    useEffect(() => {
        setPageTitle(t('Agents'));
    }, []);

    const tableConfig = {
        id: 'agents',
        columns: React.useMemo(
            () => [
                {
                    field: 'name',
                    label: t('Name'),
                    pinned: true,
                    type: 'string',
                    cellRenderer: NameCell,
                },
                {
                    field: 'issuer',
                    label: t('Issuer'),
                    type: 'string',
                },
                {
                    field: 'nip',
                    label: t('Nip'),
                    type: 'string',
                },
                {
                    field: 'phone',
                    label: t('Phone'),
                    type: 'string',
                    cellRenderer: PhoneCell,
                },
                {
                    field: 'email',
                    label: t('Email'),
                    type: 'string',
                    cellRenderer: EmailCell,
                },
                {
                    field: 'address',
                    label: t('Address'),
                    type: 'string',
                    width: 'auto',
                },
            ],
            [],
        ),
        headerActions: [
            {
                label: 'Add Agent',
                icon: HiPlus,
                to: `${get(urls, 'agentForm.route')}/create`,
                useSeparately: true,
            },
        ],
        rowActions: [
            {
                label: 'Edit',
                icon: HiOutlinePencil,
                to: ({ data: { id } = {} }) => `${get(urls, 'agentForm.route')}/${id}`,
            },
        ],
        initConfig: {
            sort: { field: 'name', direct: 'ASC' },
            filters: { isActive: { value: true, operator: 'eq' } },
        },
        contextApi: agentApi,
        deleteMessage: DeleteMessage,
        withExport: true,
    };

    return (
        <Grid.Container fluid>
            <Grid.Row>
                <Grid.Col>
                    <TableComponent {...tableConfig} />
                </Grid.Col>
            </Grid.Row>
        </Grid.Container>
    );
}

export default Agents;
