import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import { useNotification } from '../../../../../../contexts/NotificationContext';
import { sendRequest } from '../../../../../../utils/request';
import { getServices } from '../../../../../../api';

export const useDictionaries = () => {
    const { t } = useTranslation();
    const [, { addError }] = useNotification();
    const [dictionaries, setDictionaries] = useState({
        loading: false,
        services: [],
    });

    useEffect(() => {
        const fetchServices = async () => {
            setDictionaries((state) => ({
                ...state,
                loading: true,
            }));

            try {
                const response = await sendRequest(getServices(), {
                    method: 'POST',
                    data: {
                        filter: { isActive: { value: true, operator: 'eq' } },
                        sort: {
                            field: 'name',
                            direct: 'ASC',
                        },
                        fields: ['name', 'netPrice', 'currency'],
                        limit: {
                            size: 999,
                            page: 0,
                        },
                    },
                });

                if (response.status !== 200) {
                    addError(t(response.data.error));
                }

                setDictionaries((state) => ({
                    ...state,
                    loading: false,
                    services: [
                        ...get(response.data.data, 'items', []).map(({ _id: value, name, netPrice, currency }) => ({
                            value,
                            label: `${name} - ${netPrice} ${currency}`,
                            name,
                            price: netPrice,
                            currency,
                        })),
                    ],
                }));
            } catch (e) {
                addError(t(e));
                setDictionaries((state) => ({
                    ...state,
                    loading: false,
                }));
            }
        };

        fetchServices();
    }, []);

    return dictionaries;
};
