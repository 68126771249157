import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { debounce } from 'lodash';
import classNames from 'classnames';

import { convertToHTML, convertFromHTML } from 'draft-convert';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

function FormWysiwig({ editorClassName, isInvalid, ...props }) {
    const { name } = props;
    const [, meta, helpers] = useField(name);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const htmlDecode = (input) => {
        const doc = new DOMParser().parseFromString(input, 'text/html');
        return doc.documentElement.textContent;
    };

    const onValueChange = (value) => {
        helpers.setError(undefined);
        helpers.setValue(value || '', false);
    };

    const debouncedOnValueChange = debounce(onValueChange, 500);

    useEffect(() => {
        setEditorState(EditorState.createWithContent(convertFromHTML(htmlDecode(meta.initialValue))));
    }, [meta.initialValue]);

    useEffect(() => {
        debouncedOnValueChange(convertToHTML(editorState.getCurrentContent()));
    }, [editorState]);

    const handleChange = (editorState) => {
        setEditorState(editorState);
    };

    const editorClass = classNames(
        'bg-white px-3 text-gray-800 rounded-md border border-gray-300 shadow-sm hover:border-blue-300',
        editorClassName,
        {
            '!border-red-600 focus:!ring-red-600/30': isInvalid,
        },
    );

    return (
        <Editor
            editorState={editorState}
            toolbarClassName="rounded-md border border-gray-300"
            editorClassName={editorClass}
            toolbar={{
                options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history'],
            }}
            onEditorStateChange={handleChange}
        />
    );
}

FormWysiwig.propTypes = {
    name: PropTypes.string.isRequired,
    editorClassName: PropTypes.string,
    isInvalid: PropTypes.bool,
};

FormWysiwig.defaultProps = {
    editorClassName: '',
    isInvalid: false,
};

export default FormWysiwig;
