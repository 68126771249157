import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { HiOutlineLockClosed } from 'react-icons/hi';
import isString from 'lodash/isString';
import Grid from '../Grid';
import Section from '../Section';
import LinkButton from '../LinkButton';

function NoAccess({ icon, title, message }) {
    const { t } = useTranslation();
    const Icon = icon || null;
    return (
        <Grid.Container fluid>
            <Grid.Row className="justify-center">
                <Grid.Col md={10} lg={8}>
                    <Section>
                        <div className="mx-auto max-w-screen-sm text-center pb-4">
                            <div className="flex justify-center mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-blue-600 dark:text-primary-500">
                                <Icon />
                            </div>
                            <h5 className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">
                                {isString(title) ? t(title) : title}
                            </h5>
                            <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">
                                {isString(message) ? t(message) : message}
                            </p>
                            <div className="flex justify-center">
                                <LinkButton to={-1} outline={false}>
                                    {t('Go back')}
                                </LinkButton>
                            </div>
                        </div>
                    </Section>
                </Grid.Col>
            </Grid.Row>
        </Grid.Container>
    );
}

NoAccess.propTypes = {
    icon: PropTypes.any,
    title: PropTypes.any,
    message: PropTypes.any,
};
NoAccess.defaultProps = {
    icon: HiOutlineLockClosed,
    title: 'Access Denied.',
    message: 'Seems like you are not allowed to access this page.',
};

export default NoAccess;
