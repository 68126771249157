import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { HiOutlineTrash } from 'react-icons/hi';
import { FieldArray } from 'formik';
import Grid from '../../../../../../components/Grid';
import PricingList from '../../../../../../components/PricingList';
import FormItem from '../../../../../../components/FormItem';
import Button from '../../../../../../components/Button';
import ErrorMessage from '../../../../../../components/FormItem/components/ErrorMessage';

import { ADD_SERVICE_FIELD_NAME, useServices } from './useServices';
import { units } from '../../../../../../utils/dictionaries';
import { getFormattedPrice } from '../../../../../../utils/price';

function Services(props) {
    const { disabled, formSchema } = props;

    const { t } = useTranslation();
    const [
        { loading, services, field, meta, totalCost, totalProvision },
        {
            handleAddService,
            handleNumberChange,
            handlePriceChange,
            handleUnitChange,
            handleCostChange,
            handleProvisionPercentChange,
            handleApplyPriceToWords,
            getCalculatedCost,
            getCalculatedProvision,
        },
    ] = useServices();

    return (
        <Fragment>
            <Grid.Row>
                <Grid.Col>
                    <FieldArray
                        name="services"
                        render={(arrayHelpers) => (
                            <div className="overflow-x-auto">
                                <div className="divide-y divide-gray-200 ">
                                    <div className="flex flex-row justify-start gap-4 flex-nowrap items-top">
                                        <div className="flex flex-col items-start justify-start pt-2 shrink-0 w-80">
                                            <span className="mb-1 text-xs font-semibold text-gray-700 uppercase dark:text-slate-300">
                                                {t('Service')}
                                            </span>
                                        </div>
                                        <div className="flex flex-none w-32 pt-2">
                                            <span className="mb-1 text-xs font-semibold text-gray-700 uppercase dark:text-slate-300">
                                                {t('Number')}
                                            </span>
                                        </div>
                                        <div className="flex flex-none w-48 pt-2">
                                            <span className="mb-1 text-xs font-semibold text-gray-700 uppercase dark:text-slate-300">
                                                {t('Price')}
                                            </span>
                                        </div>
                                        <div className="flex flex-none pt-2 w-28">
                                            <span className="mb-1 text-xs font-semibold text-gray-700 uppercase dark:text-slate-300">
                                                {t('Unit')}
                                            </span>
                                        </div>
                                        <div className="flex flex-none w-48 px-2 pt-2 bg-blue-200 dark:bg-slate-500 rounded-t-md">
                                            <span className="mb-1 text-xs font-semibold text-gray-700 uppercase dark:text-slate-300">
                                                {t('Cost')}
                                            </span>
                                        </div>
                                        <div className="flex flex-none w-48 pt-2">
                                            <span className="mb-1 text-xs font-semibold text-gray-700 uppercase dark:text-slate-300">
                                                {t('Provision percent')}
                                            </span>
                                        </div>
                                        <div className="flex flex-none w-48 px-2 pt-2 bg-blue-200 dark:bg-slate-500 rounded-t-md">
                                            <span className="mb-1 text-xs font-semibold text-gray-700 uppercase dark:text-slate-300">
                                                {t('Provision')}
                                            </span>
                                        </div>
                                        <div className="flex flex-none w-16" />
                                    </div>
                                    {!!field.value && !!field.value.length && (
                                        <div className="divide-y divide-gray-200 ">
                                            {field.value.map(({ name, currency, ...restProps }, index) => (
                                                <div
                                                    key={index}
                                                    className="flex flex-row justify-start gap-4 flex-nowrap items-top"
                                                >
                                                    <div className="flex flex-col items-start justify-start py-4 shrink-0 w-80">
                                                        <p className="font-semibold">{name}</p>
                                                        <p className="mt-2 text-sm">
                                                            {getFormattedPrice(restProps.defaultPrice)} {currency}
                                                        </p>
                                                    </div>
                                                    <div className="flex flex-none w-32 py-4">
                                                        <FormItem
                                                            name={`services.${index}.number`}
                                                            fieldClassName="mb-0"
                                                            type="number"
                                                            disabled={disabled || loading}
                                                            validationSchema={formSchema}
                                                            onChange={(e) =>
                                                                handleNumberChange({
                                                                    index,
                                                                    value: e.target.value,
                                                                    arrayHelpers,
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                    <div className="flex flex-none w-48 py-4">
                                                        <FormItem
                                                            name={`services.${index}.price`}
                                                            fieldClassName="mb-0"
                                                            disabled={disabled || loading}
                                                            validationSchema={formSchema}
                                                            type="price"
                                                            hint={
                                                                restProps.defaultPrice !== restProps.price
                                                                    ? t('Manually entered price')
                                                                    : ''
                                                            }
                                                            onChange={(value) =>
                                                                handlePriceChange({
                                                                    index,
                                                                    value,
                                                                    arrayHelpers,
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                    <div className="flex flex-none py-4 w-28">
                                                        <FormItem
                                                            name={`services.${index}.unit`}
                                                            fieldClassName="mb-0"
                                                            type="dropdown"
                                                            options={units}
                                                            isDeletable={false}
                                                            disabled={disabled || loading}
                                                            validationSchema={formSchema}
                                                            onChange={(value) =>
                                                                handleUnitChange({ index, value, arrayHelpers })
                                                            }
                                                        />
                                                    </div>
                                                    <div className="flex flex-none w-48 px-2 py-4 bg-blue-200 dark:bg-slate-500">
                                                        <FormItem
                                                            name={`services.${index}.cost`}
                                                            fieldClassName="mb-0"
                                                            disabled={disabled || loading}
                                                            validationSchema={formSchema}
                                                            type="price"
                                                            hint={
                                                                getCalculatedCost({ ...restProps }) !== restProps.cost
                                                                    ? t('Manually entered cost')
                                                                    : ''
                                                            }
                                                            onChange={(value) =>
                                                                handleCostChange({
                                                                    index,
                                                                    value,
                                                                    arrayHelpers,
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                    <div className="flex flex-none w-48 py-4 flex-nowrap">
                                                        <FormItem
                                                            name={`services.${index}.provisionPercent`}
                                                            type="number"
                                                            fieldClassName="mb-0"
                                                            disabled={disabled || loading}
                                                            validationSchema={formSchema}
                                                            onChange={(e) =>
                                                                handleProvisionPercentChange({
                                                                    index,
                                                                    value: e.target.value,
                                                                    arrayHelpers,
                                                                })
                                                            }
                                                        />
                                                        <div className="flex items-center p-3 -ml-[4px] h-[42px] bg-slate-600 rounded-r-md text-white font-semibold ">
                                                            %
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-none w-48 px-2 py-4 bg-blue-200 dark:bg-slate-500">
                                                        <FormItem
                                                            name={`services.${index}.provision`}
                                                            fieldClassName="mb-0"
                                                            disabled={disabled || loading}
                                                            validationSchema={formSchema}
                                                            type="price"
                                                            hint={
                                                                getCalculatedProvision({ ...restProps }) !==
                                                                restProps.provision
                                                                    ? t('Manually entered provision')
                                                                    : ''
                                                            }
                                                        />
                                                    </div>
                                                    <div className="flex items-start justify-center flex-none w-16 py-4">
                                                        <Button
                                                            outline
                                                            color="danger"
                                                            iconOnly
                                                            onClick={() => arrayHelpers.remove(index)}
                                                            title={t('Remove service')}
                                                        >
                                                            <HiOutlineTrash className="text-xl" />
                                                        </Button>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                    <div className="flex flex-row items-stretch justify-start gap-4 bg-slate-200 dark:bg-slate-600 rounded-b-md w-fit">
                                        <div className="flex flex-col items-stretch justify-center p-4 shrink-0 w-80">
                                            <FormItem
                                                name={ADD_SERVICE_FIELD_NAME}
                                                label="Add Services"
                                                type="dropdown"
                                                options={services}
                                                onChange={(value) => {
                                                    handleAddService(value, arrayHelpers);
                                                }}
                                                disabled={disabled}
                                            />
                                        </div>
                                        <div className="flex flex-none w-32" />
                                        <div className="flex flex-none w-48" />
                                        <div className="flex flex-none w-28" />
                                        <div className="flex flex-none w-48 px-2 py-4 bg-blue-200 dark:bg-slate-500 rounded-b-md">
                                            <PricingList title={t('total cost')} items={totalCost} />
                                        </div>
                                        <div className="flex flex-none w-48" />
                                        <div className="flex flex-none w-48 px-2 py-4 bg-blue-200 dark:bg-slate-500 rounded-b-md">
                                            <PricingList title={t('total provision')} items={totalProvision} />
                                        </div>
                                        <div className="flex flex-none w-16" />
                                    </div>
                                </div>
                            </div>
                        )}
                    />
                    <ErrorMessage isInvalid={meta.error && meta.touched} message={meta.error} />
                </Grid.Col>
            </Grid.Row>
            <Grid.Row>
                <Grid.Col>
                    <div className="flex items-start justify-start gap-3 mt-4">
                        <FormItem
                            fieldClassName="flex-auto"
                            name="amountInWords"
                            label="Amount in words"
                            disabled={loading}
                            validationSchema={formSchema}
                        />
                        <Button
                            className="mt-[19px] w-[140px] flex-grow-0 flex-shrink-0"
                            outline
                            color="secondary"
                            onClick={handleApplyPriceToWords}
                        >
                            {t('Apply amount')}
                        </Button>
                    </div>
                </Grid.Col>
            </Grid.Row>
        </Fragment>
    );
}

Services.propTypes = {
    disabled: PropTypes.bool,
    formSchema: PropTypes.object,
};

Services.defaultProps = {
    disabled: false,
    formSchema: {},
};

export default Services;
