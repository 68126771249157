import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Form, Formik } from 'formik';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import urls from '../../../../setup/urls';
import FormItem from '../../../../components/FormItem';
import Grid from '../../../../components/Grid';
import Header from '../../../../components/Header';
import Button from '../../../../components/Button';
import LinkButton from '../../../../components/LinkButton';
import ButtonsWrapper from '../../../../components/ButtonsWrapper';
import Services from './components/Services';
import AdvancePayment from './components/AdvancePayment';
import Purchaser from './components/Purchaser';
import Travellers from './components/Travellers';
import AddClientModal from './components/AddClientModal';

import { formSchema, useForm } from './useForm';
import { useDictionaries } from './useDictionaries';
import { useSettings } from '../../../../hooks/useSettings';
import { useModals } from './useModals';
import { statuses, yesno } from '../../../../utils/dictionaries';
import ReservationStatusTooltip from '../../../../components/ReservationStatusTooltip';

const initValues = {
    operator: '',
    reservationNo: '',
    agent: '',
    reservationType: [],
    groupNo: '',
    purchaser: '',
    clients: [],
    country: [],
    startPlace: '',
    hotel: '',
    hotelRate: '',
    startDate: '',
    endDate: '',
    adults: '0',
    kids: '0',
    babies: '0',
    services: [],
    amountInWords: '',
    surchargeDate: '',
    status: 1,
    source: '',
    notes: '',
    innerNotes: '',
    advancePayment: [],
};

function ServiceForm({ id, details }) {
    const { t } = useTranslation();
    const { loading, handleSubmit, handleSubmitAndPrint } = useForm({ id, details });
    const { loadingDictionaries, dictionaries } = useDictionaries();
    const [, { getCountries }] = useSettings();
    const [modalData, { handleOpenModal, handleCloseModal }] = useModals();

    if (loadingDictionaries) return <p>{t('Loading')}</p>;

    return (
        <Fragment>
            <Formik
                initialValues={
                    !isEmpty(details)
                        ? {
                              ...details,
                              operator: get(details, 'operator', '') || '',
                              purchaser: get(details, 'purchaser._id', '') || '',
                              hotelRate: details.hotelRate || '',
                          }
                        : initValues
                }
                validationSchema={formSchema}
                enableReinitialize
                onSubmit={handleSubmit}
            >
                {({ handleSubmit, setFieldValue }) => (
                    <Form>
                        <Grid.Container fluid noPadding>
                            <Grid.Row>
                                <Grid.Col>
                                    <Header as="h3" dividing>
                                        {t('Basic')}
                                    </Header>
                                    <Grid.Row>
                                        <Grid.Col md={6} lg={4}>
                                            <FormItem
                                                name="operator"
                                                label="Operator"
                                                type="dropdown"
                                                options={get(dictionaries, 'operators', [])}
                                                disabled={loading}
                                                validationSchema={formSchema}
                                            />
                                        </Grid.Col>
                                        <Grid.Col md={6} lg={4}>
                                            <FormItem
                                                name="reservationNo"
                                                label="Reservation No"
                                                disabled={loading}
                                                validationSchema={formSchema}
                                            />
                                        </Grid.Col>
                                        <Grid.Col md={6} lg={4}>
                                            <FormItem
                                                name="agent"
                                                label="Agent"
                                                type="dropdown"
                                                options={get(dictionaries, 'agents', [])}
                                                disabled={loading}
                                                validationSchema={formSchema}
                                            />
                                        </Grid.Col>
                                        <Grid.Col md={6} lg={4}>
                                            <FormItem
                                                name="reservationType"
                                                label="Reservation Type"
                                                type="dropdown"
                                                options={get(dictionaries, 'reservationTypes', [])}
                                                disabled={loading}
                                                validationSchema={formSchema}
                                                isMultiple
                                            />
                                        </Grid.Col>
                                        <Grid.Col md={6} lg={4}>
                                            <FormItem
                                                name="groupNo"
                                                label="Group No"
                                                disabled={loading}
                                                validationSchema={formSchema}
                                            />
                                        </Grid.Col>
                                        <Grid.Col md={6} lg={4}>
                                            <div className="flex items-center justify-start gap-4 flex-nowrap">
                                                <FormItem
                                                    name="status"
                                                    label="Status"
                                                    type="dropdown"
                                                    options={statuses}
                                                    disabled={loading}
                                                    validationSchema={formSchema}
                                                />
                                                <ReservationStatusTooltip />
                                            </div>
                                        </Grid.Col>
                                    </Grid.Row>
                                </Grid.Col>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Col>
                                    <Header as="h3" dividing>
                                        {t('Event')}
                                    </Header>
                                    <Grid.Row>
                                        <Grid.Col md={6} lg={4}>
                                            <FormItem
                                                name="country"
                                                label="Country"
                                                type="dropdown"
                                                options={getCountries()}
                                                disabled={loading}
                                                validationSchema={formSchema}
                                                isMultiple
                                            />
                                        </Grid.Col>
                                        <Grid.Col md={6} lg={8}>
                                            <FormItem
                                                name="startPlace"
                                                label="Start Place"
                                                disabled={loading}
                                                validationSchema={formSchema}
                                            />
                                        </Grid.Col>
                                        <Grid.Col md={6} lg={4}>
                                            <FormItem
                                                label="Date Range"
                                                nameFrom="startDate"
                                                nameTo="endDate"
                                                type="dateRange"
                                                disabled={loading}
                                                validationSchema={formSchema}
                                            />
                                        </Grid.Col>
                                        <Grid.Col md={6} lg={4}>
                                            <FormItem
                                                name="hotel"
                                                label="Hotel"
                                                disabled={loading}
                                                validationSchema={formSchema}
                                            />
                                        </Grid.Col>
                                        <Grid.Col md={6} lg={4}>
                                            <FormItem
                                                name="hotelRate"
                                                label="Hotel type"
                                                type="dropdown"
                                                options={get(dictionaries, 'hotelTypes', [])}
                                                disabled={loading}
                                                validationSchema={formSchema}
                                            />
                                        </Grid.Col>
                                        <Grid.Col md={6} lg={4}>
                                            <FormItem
                                                name="adults"
                                                label="Adults"
                                                type="number"
                                                disabled={loading}
                                                validationSchema={formSchema}
                                            />
                                        </Grid.Col>
                                        <Grid.Col md={6} lg={4}>
                                            <FormItem
                                                name="kids"
                                                label="Kids"
                                                type="number"
                                                disabled={loading}
                                                validationSchema={formSchema}
                                            />
                                        </Grid.Col>
                                        <Grid.Col md={6} lg={4}>
                                            <FormItem
                                                name="babies"
                                                label="Babies"
                                                type="number"
                                                disabled={loading}
                                                validationSchema={formSchema}
                                            />
                                        </Grid.Col>
                                    </Grid.Row>
                                </Grid.Col>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Col>
                                    <Header as="h3" dividing>
                                        {t('Purchaser')}
                                    </Header>
                                    <Grid.Row>
                                        <Grid.Col>
                                            <Purchaser
                                                client={get(details, 'purchaser', {})}
                                                onAddClient={handleOpenModal}
                                            />
                                        </Grid.Col>
                                    </Grid.Row>
                                </Grid.Col>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Col>
                                    <Header as="h3" dividing>
                                        {t('Participants/Travellers')}
                                    </Header>
                                    <Grid.Row>
                                        <Grid.Col>
                                            <Travellers disabled={loading} formSchema={formSchema} />
                                        </Grid.Col>
                                    </Grid.Row>
                                </Grid.Col>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Col>
                                    <Header as="h3" dividing>
                                        {t('Services')}
                                    </Header>
                                    <Services disabled={loading} formSchema={formSchema} />
                                </Grid.Col>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Col>
                                    <Header as="h3" dividing>
                                        {t('Benefit Schedule')}
                                    </Header>
                                    <AdvancePayment disabled={loading} formSchema={formSchema} />
                                    <Grid.Row className="mt-4">
                                        <Grid.Col md={6} lg={4}>
                                            <FormItem
                                                name="surchargeDate"
                                                label="Surcharge Date"
                                                type="date"
                                                disabled={loading}
                                                validationSchema={formSchema}
                                            />
                                        </Grid.Col>
                                        <Grid.Col md={6} lg={4}>
                                            <FormItem
                                                name="paidOff"
                                                label="Paid Off"
                                                type="dropdown"
                                                options={yesno}
                                                disabled={loading}
                                                validationSchema={formSchema}
                                            />
                                        </Grid.Col>
                                    </Grid.Row>
                                </Grid.Col>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Col>
                                    <Header as="h3" dividing>
                                        {t('Others')}
                                    </Header>
                                    <Grid.Row>
                                        <Grid.Col md={6} lg={4}>
                                            <FormItem
                                                name="source"
                                                label="Source"
                                                disabled={loading}
                                                validationSchema={formSchema}
                                            />
                                        </Grid.Col>

                                        <Grid.Col>
                                            <FormItem
                                                name="innerNotes"
                                                label="Inner Notes"
                                                type="wysiwig"
                                                disabled={loading}
                                                validationSchema={formSchema}
                                                editorClassName="min-h-[400px]"
                                            />
                                        </Grid.Col>
                                    </Grid.Row>
                                </Grid.Col>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Col>
                                    <Header as="h3" dividing>
                                        {t('Comment Notes')}
                                    </Header>
                                    <Grid.Row>
                                        <Grid.Col>
                                            <FormItem
                                                name="notes"
                                                type="wysiwig"
                                                disabled={loading}
                                                validationSchema={formSchema}
                                                editorClassName="min-h-[400px]"
                                            />
                                        </Grid.Col>
                                    </Grid.Row>
                                </Grid.Col>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Col mobile={16}>
                                    <ButtonsWrapper>
                                        <Button type="submit" color="primary" loading={loading}>
                                            {t('Save')}
                                        </Button>
                                        <Button
                                            color="primary"
                                            loading={loading}
                                            onClick={() => handleSubmitAndPrint(handleSubmit, setFieldValue)}
                                        >
                                            {t('Save and Print')}
                                        </Button>
                                        <LinkButton to={get(urls, 'reservations.route')} disabled={loading}>
                                            {t('Cancel')}
                                        </LinkButton>
                                    </ButtonsWrapper>
                                </Grid.Col>
                            </Grid.Row>
                        </Grid.Container>
                    </Form>
                )}
            </Formik>
            <AddClientModal {...modalData} onClose={handleCloseModal} />
        </Fragment>
    );
}

ServiceForm.propTypes = {
    id: PropTypes.string,
    details: PropTypes.object,
    dictionaries: PropTypes.shape({
        operators: PropTypes.array,
    }),
};

ServiceForm.defaultProps = {
    id: null,
    details: {},
    dictionaries: {
        operators: [],
    },
};

export default ServiceForm;
