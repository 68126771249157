import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useConfigContext } from '../../contexts/ConfigContext';
import LoginForm from './components/Form';

function Login() {
    const { t } = useTranslation();
    const [, { setPageTitle }] = useConfigContext();

    useEffect(() => {
        setPageTitle(t('Sign In'));
    }, []);

    return (
        <div>
            <h2 className="text-center mb-2 text-lg lg:text-xl font-semibold">{t('Login to your account')}</h2>
            <LoginForm />
        </div>
    );
}
export default Login;
