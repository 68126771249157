import React, { useState } from 'react';
import { HiOutlineXCircle } from 'react-icons/hi';
import NoAccess from '../../components/NoAccess';

function withNoDetailsWrapper(Component) {
    function WrappedField(props) {
        const [noIdFound, setNoIdFound] = useState(false);

        const hasNoId = () => {
            setNoIdFound(true);
        };

        if (noIdFound) {
            return (
                <NoAccess
                    icon={HiOutlineXCircle}
                    title="Something's missing."
                    message="We couldn't find the page you're looking for."
                />
            );
        }

        return <Component {...props} hasNoId={hasNoId} />;
    }

    WrappedField.propTypes = {};

    WrappedField.defaultProps = {};

    return WrappedField;
}

export default withNoDetailsWrapper;
