import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import { forEach, isFunction } from 'lodash';
import { HiOutlineCloudUpload, HiOutlineTrash } from 'react-icons/hi';
import { useNotification } from '../../contexts/NotificationContext';
import Button from '../Button';

function DropzonePlaceholder({ placeholder, files }) {
    const { t } = useTranslation();

    if (files.length) {
        return (
            <div className="text-center">
                {t('Selected file')}:<br />
                {files.map((file) => {
                    const { name, type } = file;
                    const isImage = type.includes('image');
                    return (
                        <div>
                            {isImage && <img src={window.URL.createObjectURL(file)} alt="" />}
                            <strong key={name}>{name}</strong>
                        </div>
                    );
                })}
            </div>
        );
    }
    let Placeholder;
    if (isFunction(placeholder)) {
        Placeholder = placeholder;
    }

    return (
        <Fragment>
            <HiOutlineCloudUpload className="mb-2 text-5xl" />
            <div className="max-w-xs px-4 text-sm text-center ">{Placeholder ? <Placeholder /> : t(placeholder)}</div>
        </Fragment>
    );
}

DropzonePlaceholder.propTypes = {
    files: PropTypes.array,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

DropzonePlaceholder.defaultProps = {
    files: [],
    placeholder: "Drag 'n' drop some files here, or click to select files",
};

function Dropzone({ config, placeholderMessage, setFiles }) {
    const { t } = useTranslation();
    const [acceptedFiles, setAcceptedFiles] = useState([]);
    const [, { addError }] = useNotification();

    useEffect(() => {
        setFiles(acceptedFiles);
    }, [acceptedFiles]);

    const handleDrop = (acceptedFiles) => {
        setAcceptedFiles(acceptedFiles);
    };
    const handleReject = (files) => {
        forEach(files, ({ errors }) => {
            forEach(errors, ({ message }) => {
                addError(t(message));
            });
        });
    };

    const handleError = (error) => {
        addError(error);
    };

    const handleReset = () => {
        setAcceptedFiles([]);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        ...config,
        onDropAccepted: handleDrop,
        onDropRejected: handleReject,
        onError: handleError,
    });

    return (
        <div className="relative">
            <div
                {...getRootProps()}
                className={`
                    flex flex-col items-center justify-center w-full h-48
                    bg-slate-100 dark:bg-slate-600 text-slate-500 dark:text-slate-300
                    border border-dashed border-slate-300 dark:border-slate-800
                    rounded-lg cursor-pointer
                `}
            >
                <input {...getInputProps()} />
                {isDragActive ? (
                    <p>{t('Drop the files here ...')}</p>
                ) : (
                    <DropzonePlaceholder files={acceptedFiles} placeholder={placeholderMessage} />
                )}
            </div>
            {!!acceptedFiles.length && (
                <div className="absolute inset-x-0 flex justify-center mx-auto bottom-4">
                    <Button iconLeft={HiOutlineTrash} size="sm" color="danger" outline onClick={handleReset}>
                        Remove
                    </Button>
                </div>
            )}
        </div>
    );
}

Dropzone.propTypes = {
    config: PropTypes.object,
    placeholderMessage: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    setFiles: PropTypes.func,
};

Dropzone.defaultProps = {
    config: {},
    placeholderMessage: "Drag 'n' drop some files here, or click to select files",
    setFiles: () => {},
};
export default Dropzone;
