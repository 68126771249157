import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import { useAuthContext } from '../../contexts/AuthContext';
import urls from '../../setup/urls';

function NotFoundPage() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [{ isLogged, loading: userIsLoading }] = useAuthContext();

    useEffect(() => {
        if (!isLogged && !userIsLoading) {
            navigate(get(urls, 'login.route'));
        }
    }, [isLogged, userIsLoading]);

    return (
        <div>
            <h2 className="mb-2 text-6xl font-semibold text-center lg:text-8xl">
                {t('404')}
                <div className="text-sm font-medium">
                    {t('Maybe the page you are looking for has been removed, or you typed in the wrong URL.')}
                </div>
            </h2>
            <div className="text-center">
                <Link to={get(urls, 'login.route')}>{t('Back to Dashbaord')}</Link>
            </div>
        </div>
    );
}
export default NotFoundPage;
