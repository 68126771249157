import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Navigate } from 'react-router-dom';
import get from 'lodash/get';
import { useConfigContext } from '../../contexts/ConfigContext';
import { useAuthContext } from '../../contexts/AuthContext';
import NotLoggedLayout from './NotLoggedLayout';
import urls from '../../setup/urls';

function withContext(Component) {
    function WrappedField(props) {
        const [{ loading, pageTitle }] = useConfigContext();

        const [{ isLogged, loading: userIsLoading }] = useAuthContext();

        if (isLogged) return <Navigate to={get(urls, 'reservations.route')} replace />;

        return (
            <Fragment>
                <Helmet>
                    <title>
                        {process.env.REACT_APP_TITLE} - {pageTitle}
                    </title>
                </Helmet>
                <Component {...props} loading={loading || userIsLoading} pageTitle={pageTitle} />
            </Fragment>
        );
    }

    return WrappedField;
}

export default withContext(NotLoggedLayout);
