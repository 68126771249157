import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import get from 'lodash/get';
import Yup from '../../../../utils/yup';
import { useNotification } from '../../../../contexts/NotificationContext';
import { sendRequest } from '../../../../utils/request';
import { createDictionaryItem, updateDictionaryItem } from '../../../../api';
import urls from '../../../../setup/urls';

export const formSchema = Yup.object().shape({
    value: Yup.string().required().max(255),
});

export const useForm = ({ id, group }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const [, { addError, addSuccess }] = useNotification();

    const handleSubmit = async (values, actions) => {
        setLoading(true);
        const api = id ? updateDictionaryItem({ id }) : createDictionaryItem();

        const response = await sendRequest(api, {
            method: id ? 'PUT' : 'POST',
            data: {
                group,
                ...values,
            },
        });

        actions.setSubmitting(false);
        setLoading(false);
        if (response.status === 200) {
            addSuccess(t('Dictionary value was saved.'));
            navigate(`${get(urls, 'dictionary.route')}/${group}`);
        } else {
            addError(t(response.data.error));
        }
    };

    return {
        loading,
        handleSubmit,
    };
};
