/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';
// Yup.setLocale({
//     mixed: {
//         default: 'Field is invalid',
//         required: 'Field is required',
//         defined: 'Field must be defined',
//         notNull: 'Field cannot be null',
//         oneOf: 'Field must be one of the following values: ${values}',
//         notOneOf: 'Field must not be one of the following values: ${values}',
//     },
//     string: {
//         length: 'Field must be exactly ${length} characters',
//         min: 'Field must be at least ${min} characters',
//         max: 'Field must be at most ${max} characters',
//         matches: 'Field must match the following: "${regex}"',
//         email: 'Field must be a valid email',
//         url: 'Field must be a valid URL',
//         uuid: 'Field must be a valid UUID',
//         trim: 'Field must be a trimmed string',
//         lowercase: 'Field must be a lowercase string',
//         uppercase: 'Field must be a upper case string',
//     },
//     number: {
//         min: 'Field must be greater than or equal to ${min}',
//         max: 'Field must be less than or equal to ${max}',
//         lessThan: 'Field must be less than ${less}',
//         moreThan: 'Field must be greater than ${more}',
//         positive: 'Field must be a positive number',
//         negative: 'Field must be a negative number',
//         integer: 'Field must be an integer',
//     },
//     date: {
//         min: 'Field must be later than ${min}',
//         max: 'Field must be at earlier than ${max}',
//     },
//     boolean: {
//         isValue: 'Field must be ${value}',
//     },
//     object: {
//         noUnknown: 'Field has unspecified keys: ${unknown}',
//     },
//     array: {
//         min: 'Field must have at least ${min} items',
//         max: 'Field must have less than or equal to ${max} items',
//         length: 'Field must have ${length} items',
//     },
// });

Yup.setLocale({
    mixed: {
        default: 'Pole jest niepoprawne',
        required: 'Pole jest wymagane',
        defined: 'Pole musi być określone',
        notNull: 'Pole nie może być puste',
        oneOf: 'Pole musi mieć wartość jedną z ${values}',
        notOneOf: 'Pole nie może mieć wartość jedną z ${values}',
    },
    string: {
        length: 'Pole musi mieć dokładnie ${length} znaków',
        min: 'Pole musi mieć conajmniej ${min} znaków',
        max: 'Pole musi mieć conajwyżej ${max} znaków',
        matches: 'Pole musi pasować do "${regex}"',
        email: 'Pole musi być poprawnym adresem e-mail',
        url: 'Pole musi być poprawnym URL',
        uuid: 'Pole musi być poprawnym UUID',
        trim: 'Wartośc nie może zawierać pustych znaków na początku i końcu',
        lowercase: 'Wartośc musi zawierać tylko małe litery',
        uppercase: 'Wartośc musi zawierać tylko duże litery',
    },
    number: {
        min: 'Wartość musi wynosić co najmniej ${min}',
        max: 'Wartość nie może przekraczać ${max}',
        lessThan: 'Field must be less than ${less}',
        moreThan: 'Field must be greater than ${more}',
        positive: 'Field must be a positive number',
        negative: 'Field must be a negative number',
        integer: 'Field must be an integer',
    },
    date: {
        min: 'Field must be later than ${min}',
        max: 'Field must be at earlier than ${max}',
    },
    boolean: {
        isValue: 'Pole musi być ${value}',
    },
    object: {
        noUnknown: 'Field has unspecified keys: ${unknown}',
    },
    array: {
        min: 'Pole musi zawierać chociaż ${min} element',
        max: 'Pole nie może zawierać więcej niż ${max} elementów',
        length: 'Pole musi mieć dokładnie ${length} elementów',
    },
});

export default Yup;
