import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HiOutlineCheckCircle, HiOutlineBan } from 'react-icons/hi';
import get from 'lodash/get';
import urls from '../../../../setup/urls';
import { useForm } from './useForm';

function ForgotPasswordForm(props) {
    const { t } = useTranslation();
    const { status } = useForm(props);

    if (status === 0) {
        return null;
    }

    return (
        <div className="flex flex-col items-center justify-center w-full h-full gap-2">
            <div className={`${status === 1 ? 'text-green-600' : 'text-red-600'} text-8xl`}>
                {status === 1 ? <HiOutlineCheckCircle /> : <HiOutlineBan />}
            </div>
            <p>{t(status === 1 ? 'Your account has activated!' : "Your account hasn't activated.")}</p>
            <p>
                <Link to={get(urls, 'login.route')}>{t('Back to login page')}</Link>
            </p>
        </div>
    );
}
export default ForgotPasswordForm;
