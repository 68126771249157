import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import Yup from '../../../../../../utils/yup';
import { useNotification } from '../../../../../../contexts/NotificationContext';
import { sendRequest } from '../../../../../../utils/request';
import { searchClients } from '../../../../../../api';

export const formSchema = Yup.object().shape({
    search: Yup.string().required().min(2).max(255),
});

export const useClientSearchList = () => {
    const { t } = useTranslation();
    const [searchInput, setSearchInput] = useState('');
    const [data, setData] = useState({
        loading: false,
        items: [],
    });
    const [, { addError }] = useNotification();

    const handleToggleSelection = (id) => {
        setData((state) => ({
            ...state,
            items: state.items.map((item) => ({
                ...item,
                isSelected: item.id === id ? !get(item, 'isSelected', false) : get(item, 'isSelected', false),
            })),
        }));
    };

    useEffect(() => {
        if (searchInput && searchInput.length >= 3) {
            const search = async (searchInput) => {
                setData((state) => ({
                    ...state,
                    loading: true,
                }));

                const response = await sendRequest(searchClients(), {
                    method: 'POST',
                    data: {
                        search: searchInput,
                    },
                });

                if (response.status === 200) {
                    setData((state) => ({
                        ...state,
                        items: get(response, 'data.data.items', []).map((item) => ({
                            ...item,
                            id: get(item, '_id', get(item, 'id')),
                        })),
                        loading: false,
                    }));
                } else {
                    addError(t(response.data.error));
                    setData((state) => ({
                        ...state,
                        items: [],
                        loading: false,
                    }));
                }
            };

            search(searchInput);
        }
    }, [searchInput]);

    const handleChangeSearchInput = (value) => {
        setSearchInput(value);
    };

    return [
        data,
        {
            handleToggleSelection,
            handleChangeSearchInput: useCallback(debounce(handleChangeSearchInput, 500), []),
        },
    ];
};
