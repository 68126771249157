import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';

import { Form, Formik } from 'formik';
import FormItem from '../../../../components/FormItem';
import Button from '../../../../components/Button';
import ButtonsWrapper from '../../../../components/ButtonsWrapper';
import Grid from '../../../../components/Grid';

import { formSchema, useForm } from './useForm';

const initValues = {
    regulations: '',
};

function AgreementForm({ details }) {
    const { t } = useTranslation();
    const { loading, handleSubmit } = useForm({ details });

    const data = JSON.parse(get(details, 'data', '{}'));

    return (
        <Formik
            initialValues={{ ...initValues, ...data }}
            validationSchema={formSchema}
            enableReinitialize
            onSubmit={handleSubmit}
        >
            {() => (
                <Form>
                    <Grid.Container fluid>
                        <Grid.Row>
                            <Grid.Col>
                                <FormItem
                                    name="regulations"
                                    label="Regulations"
                                    type="wysiwig"
                                    disabled={loading}
                                    validationSchema={formSchema}
                                    className="h-64"
                                />
                            </Grid.Col>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Col>
                                <ButtonsWrapper>
                                    <Button type="submit" color="primary" loading={loading}>
                                        {t('Save')}
                                    </Button>
                                </ButtonsWrapper>
                            </Grid.Col>
                        </Grid.Row>
                    </Grid.Container>
                </Form>
            )}
        </Formik>
    );
}

AgreementForm.propTypes = {
    details: PropTypes.object,
};

AgreementForm.defaultProps = {
    details: {},
};

export default AgreementForm;
