import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import { HiMinusSm } from 'react-icons/hi';
import FormItem from '../../../../../FormItem';
import Label from '../../../../../FormItem/components/Label';

function NumbeRangeFilterField({ column, filters = {}, onFilterChange = () => {} }) {
    const { label, field } = column;
    const [value, setValue] = useState(['', '']);

    useEffect(() => {
        setValue(get(filters[field], 'value', ['', '']));
    }, [filters[field]]);

    function handleDebounceFn(value) {
        onFilterChange(field, { value, operator: 'between' });
    }
    const debounceFn = useCallback(debounce(handleDebounceFn, 500), []);

    const handleChangeFrom = (event) => {
        setValue([event.target.value, value[1]]);
        debounceFn([event.target.value ? parseFloat(event.target.value) : '', value[1]]);
    };

    const handleChangeTo = (event) => {
        setValue([value[0], event.target.value]);
        debounceFn([value[0], event.target.value ? parseFloat(event.target.value) : '']);
    };

    return (
        <div>
            <Label label={label} />
            <div className="flex flex-nowrap justify-center items-start">
                <FormItem name={field} type="number" value={value[0]} onChange={handleChangeFrom} />
                <HiMinusSm className="h-[46px]" />
                <FormItem name={field} type="number" value={value[1]} onChange={handleChangeTo} />
            </div>
        </div>
    );
}

NumbeRangeFilterField.propTypes = {
    column: PropTypes.object,
    filters: PropTypes.object,
    onFilterChange: PropTypes.func,
};

NumbeRangeFilterField.defaultProps = {
    column: {},
    filters: {},
    onFilterChange: () => {},
};

export default NumbeRangeFilterField;
