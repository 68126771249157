import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useConfigContext } from '../../contexts/ConfigContext/ConfigContext';

import ActivaAccountForm from './components/Form';

function ActiveAccount() {
    const { t } = useTranslation();
    const { token } = useParams();
    const [, { setPageTitle }] = useConfigContext();

    useEffect(() => {
        setPageTitle(t('Activate your account'));
    }, []);

    return (
        <div>
            <h2 className="text-lg font-semibold text-center lg:text-xl">{t('Activate your account')}</h2>
            <ActivaAccountForm token={token} />
        </div>
    );
}
export default ActiveAccount;
