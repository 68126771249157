import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useConfigContext } from '../../contexts/ConfigContext/ConfigContext';
import ResetPasswordForm from './components/Form';

function ResetPassword() {
    const { t } = useTranslation();
    const { token } = useParams();
    const [, { setPageTitle }] = useConfigContext();

    useEffect(() => {
        setPageTitle(t('Reset your password'));
    }, []);

    return (
        <div>
            <h2 className="text-center mb-2 text-lg lg:text-xl font-semibold">{t('Reset your password')}</h2>
            <ResetPasswordForm token={token} />
        </div>
    );
}
export default ResetPassword;
