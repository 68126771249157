import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import get from 'lodash/get';

import Yup from '../../../../utils/yup';
import { useNotification } from '../../../../contexts/NotificationContext';
import { passwordStrong, passwordMatch } from '../../../../utils/validationMessages';
import { passwordRegex } from '../../../../utils/password';
import { sendRequest } from '../../../../utils/request';
import { authResetPassword } from '../../../../api';
import urls from '../../../../setup/urls';

export const formSchema = Yup.object().shape({
    password: Yup.string().min(8).max(255).matches(passwordRegex, passwordStrong()).required(),
    repeatPassword: Yup.string()
        .required()
        .oneOf([Yup.ref('password'), null], passwordMatch()),
});

export const useForm = ({ token }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [, { addSuccess, addError }] = useNotification();
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (values, actions) => {
        setLoading(true);
        const response = await sendRequest(authResetPassword(token), {
            method: 'PUT',
            data: { password: values.password },
        });

        actions.setSubmitting(false);
        setLoading(false);
        if (response.status === 200) {
            addSuccess(t('Send an email'));
            navigate(get(urls, 'login.route'));
        } else {
            addError(t(response.data.error));
        }
    };

    return {
        loading,
        handleSubmit,
    };
};
