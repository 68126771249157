import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import classNames from 'classnames';
import ErrorMessage from './ErrorMessage';
import HintMessage from './HintMessage';
import Label from './Label';

function withFormFieldWrapper(FieldComponent) {
    function WrappedField(props) {
        const { hint, label, name, shouldBeTouched, fieldClassName, validationSchema } = props;

        const [, meta] = useField(name);

        const isInvalid = meta.error && (!shouldBeTouched || meta.touched);

        const fieldClass = classNames('mb-4 w-full', fieldClassName, {
            error: isInvalid,
        });

        return (
            <div className={fieldClass}>
                {label && <Label label={label} field={name} validationSchema={validationSchema} />}
                <div>
                    <FieldComponent isInvalid={isInvalid} {...props} />
                </div>
                {isInvalid && <ErrorMessage isInvalid={isInvalid} message={meta.error} />}
                <HintMessage message={hint} />
            </div>
        );
    }

    WrappedField.propTypes = {
        name: PropTypes.string,
        label: PropTypes.string,
        hint: PropTypes.string,
        fieldClassName: PropTypes.string,
        validationSchema: PropTypes.object,
        shouldBeTouched: PropTypes.bool,
    };

    WrappedField.defaultProps = {
        name: '',
        label: '',
        hint: '',
        fieldClassName: '',
        validationSchema: {},
        shouldBeTouched: true,
    };

    return WrappedField;
}

export default withFormFieldWrapper;
