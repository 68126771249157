import { useField } from 'formik';
import { get, find } from 'lodash';

import { useDictionaries } from './useDictionaries';
import { useTotals } from './useTotals';
import { getWordsForPrice } from '../../../../../../utils/priceToWords';

export const ADD_SERVICE_FIELD_NAME = 'add_service';

export const useServices = () => {
    const [field, meta] = useField('services');
    const [, , amountInWordsHelpers] = useField('amountInWords');
    const [, , selectionServiceHelpers] = useField(ADD_SERVICE_FIELD_NAME);

    const { loading, services } = useDictionaries();
    const { totalCost, totalProvision } = useTotals({ field });

    const handleAddService = (value, arrayHelpers) => {
        if (value) {
            const service = find(services, ['value', value]);
            arrayHelpers.push({
                id: get(service, 'value', ''),
                number: 1,
                name: get(service, 'name', ''),
                defaultPrice: get(service, 'price', 0),
                price: get(service, 'price', 0),
                currency: get(service, 'currency', 0),
                unit: 'piece',
                cost: get(service, 'price', 0),
                provisionPercent: '',
                provision: 0,
            });
            selectionServiceHelpers.setValue('', false);
        }
    };

    const getCalculatedCost = ({ unit, number, price }) => {
        if (unit === 'piece') {
            return Math.floor(number * parseFloat(price) * 100) / 100;
        }
        return Math.floor(parseFloat(price) * number) / 100;
    };

    const getCalculatedProvision = ({ cost, provisionPercent }) =>
        Math.floor(parseFloat(cost) * provisionPercent) / 100;

    const setCost = ({ index, unit, number, price, arrayHelpers }) => {
        const rowValues = meta.value[index];
        rowValues.unit = unit;
        rowValues.number = number;
        rowValues.price = price;
        rowValues.cost = getCalculatedCost({ unit, number, price });
        rowValues.provisionPercent = meta.value[index].provisionPercent;
        rowValues.provision = getCalculatedProvision({
            cost: rowValues.cost,
            provisionPercent: meta.value[index].provisionPercent,
        });
        arrayHelpers.replace(index, rowValues);
    };

    const setProvision = ({ index, cost, provisionPercent, arrayHelpers }) => {
        const rowValues = meta.value[index];
        rowValues.cost = cost;
        rowValues.provisionPercent = provisionPercent;
        rowValues.provision = getCalculatedProvision({ cost, provisionPercent });
        arrayHelpers.replace(index, rowValues);
    };

    const handleNumberChange = ({ index, value, arrayHelpers }) => {
        field.onChange(value);
        setCost({
            index,
            unit: meta.value[index].unit,
            number: value,
            price: meta.value[index].price,
            arrayHelpers,
        });
    };

    const handlePriceChange = ({ index, value, arrayHelpers }) => {
        field.onChange(value);
        setCost({
            index,
            unit: meta.value[index].unit,
            number: meta.value[index].number,
            price: value,
            arrayHelpers,
        });
    };

    const handleUnitChange = ({ index, value, arrayHelpers }) => {
        field.onChange(value);
        setCost({
            index,
            unit: value,
            number: meta.value[index].number,
            price: meta.value[index].price,
            arrayHelpers,
        });
    };

    const handleCostChange = ({ index, value, arrayHelpers }) => {
        field.onChange(value);
        setProvision({
            index,
            cost: value,
            provisionPercent: meta.value[index].provisionPercent,
            arrayHelpers,
        });
    };

    const handleProvisionPercentChange = ({ index, value, arrayHelpers }) => {
        field.onChange(value);
        setProvision({
            index,
            cost: meta.value[index].cost,
            provisionPercent: value,
            arrayHelpers,
        });
    };

    const handleApplyPriceToWords = () => {
        amountInWordsHelpers.setValue(getWordsForPrice(get(totalCost, '0.value', 0)), false);
    };

    return [
        { loading, services, field, meta, totalCost, totalProvision },
        {
            handleAddService,
            handleNumberChange,
            handlePriceChange,
            handleUnitChange,
            handleCostChange,
            handleProvisionPercentChange,
            handleApplyPriceToWords,
            getCalculatedCost,
            getCalculatedProvision,
        },
    ];
};
