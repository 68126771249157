import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import FormInput from './components/FormInput';
import FormPrice from './components/FormPrice';
import FormTextarea from './components/FormTextarea';
import FormWysiwig from './components/FormWysiwig';
import FormDropdown from './components/FormDropdown';
import FormDate from './components/FormDate';
import FormDateRange from './components/FormDateRange';
import withFormFieldWrapper from './components/withFormFieldWrapper';

const fieldTypeMapper = {
    date: withFormFieldWrapper(FormDate),
    dateRange: FormDateRange,
    dropdown: withFormFieldWrapper(FormDropdown),
    price: withFormFieldWrapper(FormPrice),
    textarea: withFormFieldWrapper(FormTextarea),
    wysiwig: withFormFieldWrapper(FormWysiwig),
    other: withFormFieldWrapper(FormInput),
};

function FormItem({ type, ...restProps }) {
    const FormField = get(fieldTypeMapper, type, fieldTypeMapper.other);
    return <FormField type={type} {...restProps} />;
}

FormItem.propTypes = {
    type: PropTypes.string,
};
FormItem.defaultProps = {
    type: 'text',
};

export default FormItem;
