import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import 'moment/locale/pl';

import i18n from '../../config/i18n';
import AppContextProvider from '../../contexts/AppContext';
import PagesRoot from '../../routes';

i18n(process.env.REACT_APP_LANGUAGE || 'en');

function App() {
    moment.locale(process.env.REACT_APP_LANGUAGE || 'en');

    return (
        <AppContextProvider>
            <Fragment>
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                    <link rel="shortcut icon" href={`${process.env.REACT_APP_BACKEND_URI}/favicon.ico`} />
                    <link
                        href="https://fonts.googleapis.com/css?family=Oxygen:400,500,600,700&display=swap"
                        rel="stylesheet"
                    />
                </Helmet>
                <PagesRoot />
            </Fragment>
        </AppContextProvider>
    );
}

export default App;
