import React from 'react';
import PropTypes from 'prop-types';
import { Tab } from '@headlessui/react';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

function Tabs({ selectedIndex, panes }) {
    const getTabGroupProps = () => {
        if (selectedIndex) {
            return {
                selectedIndex,
            };
        }

        return {};
    };
    return (
        <Tab.Group {...getTabGroupProps()}>
            <Tab.List className="flex p-2 bg-white rounded-md shadow dark:bg-slate-800">
                {panes.map(({ menuItem, render, ...restProps }) => (
                    <Tab
                        key={menuItem}
                        className={({ selected }) =>
                            classNames(
                                'rounded-md px-4 py-2.5 mr-2 text-md font-semibold leading-5',
                                'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none',
                                selected
                                    ? 'bg-blue-600 border-2 border-blue-400 text-white hover:bg-blue-700 hover:text-white dark:bg-white dark:border-slate-100 dark:text-blue-600 dark:hover:text-blue-700'
                                    : 'text-slate-500 border-2 border-white dark:border-slate-800 dark:text-slate-300 hover:bg-slate-100 hover:border-slate-100 dark:hover:bg-white/[0.12] dark:hover:border-white/[0.12] hover:text-slate-600  dark:hover:text-slate-100',
                            )
                        }
                        {...restProps}
                    >
                        {menuItem}
                    </Tab>
                ))}
            </Tab.List>
            <Tab.Panels className="mt-2">
                {panes
                    .filter(({ render }) => render)
                    .map(({ menuItem, render }) => (
                        <Tab.Panel
                            key={menuItem}
                            className="w-full bg-white rounded-lg shadow-lg ring-slate-900 dark:bg-slate-800"
                        >
                            {render}
                        </Tab.Panel>
                    ))}
            </Tab.Panels>
        </Tab.Group>
    );
}

Tabs.propTypes = {
    selectedIndex: PropTypes.number,
    panes: PropTypes.arrayOf(
        PropTypes.shape({
            menuItem: PropTypes.string,
            render: PropTypes.func,
        }),
    ),
};

Tabs.defaultProps = {
    selectedIndex: null,
    panes: [],
};
export default Tabs;
