import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useField } from 'formik';
import { omit } from 'lodash';
import { omitProps } from '../../constants';

function FormInput({
    type,
    icon,
    iconPosition,
    inputWrapperClassName,
    inpuClassName,
    inputWrapperChildren,
    isInvalid,
    ...props
}) {
    const { name } = props;
    const [field] = useField(name);

    const inputWrapperClass = classNames(inputWrapperClassName, {
        'left icon': !!icon && iconPosition === 'left',
        'right icon': !!icon && iconPosition === 'right',
    });

    const inputClass = classNames(inpuClassName, {
        '!border-red-600 focus:!ring-red-600/30': isInvalid,
    });

    return (
        <div className={inputWrapperClass}>
            <input type={type} id={name} className={inputClass} {...field} {...omit(props, omitProps)} />
            {icon && <i className={`${icon} icon`} />}
            {inputWrapperChildren}
        </div>
    );
}

FormInput.propTypes = {
    name: PropTypes.string.isRequired,
    icon: PropTypes.string,
    type: PropTypes.string,
    iconPosition: PropTypes.oneOf(['right', 'left']),
    inputWrapperClassName: PropTypes.string,
    inputWrapperChildren: PropTypes.any,
    inpuClassName: PropTypes.string,
    isInvalid: PropTypes.bool,
};

FormInput.defaultProps = {
    icon: '',
    type: 'text',
    iconPosition: 'left',
    inputWrapperClassName: '',
    inputWrapperChildren: null,
    inpuClassName: '',
    isInvalid: false,
};

export default FormInput;
