function getWordsForAmount(amount) {
    const unity = ['', ' jeden', ' dwa', ' trzy', ' cztery', ' pięć', ' sześć', ' siedem', ' osiem', ' dziewięć'];
    const nascie = [
        '',
        ' jedenaście',
        ' dwanaście',
        ' trzynaście',
        ' czternaście',
        ' piętnaście',
        ' szesnaście',
        ' siedemnaście',
        ' osiemnaście',
        ' dziewietnaście',
    ];
    const tens = [
        '',
        ' dziesięć',
        ' dwadzieścia',
        ' trzydzieści',
        ' czterdzieści',
        ' pięćdziesiąt',
        ' sześćdziesiąt',
        ' siedemdziesiąt',
        ' osiemdziesiąt',
        ' dziewięćdziesiąt',
    ];
    const setki = [
        '',
        ' sto',
        ' dwieście',
        ' trzysta',
        ' czterysta',
        ' pięćset',
        ' sześćset',
        ' siedemset',
        ' osiemset',
        ' dziewięćset',
    ];
    const groups = [
        ['', '', ''],
        [' tysiąc', ' tysiące', ' tysięcy'],
        [' milion', ' miliony', ' milionów'],
        [' miliard', ' miliardy', ' miliardów'],
        [' bilion', ' biliony', ' bilionów'],
        [' biliard', ' biliardy', ' biliardów'],
        [' trylion', ' tryliony', ' trylionów'],
    ];

    if (!Number.isInteger(amount)) {
        // eslint-disable-next-line no-console
        console.error('Invalid value entered');
        return amount;
    }

    let number = amount;
    let result = '';
    let sign = '';
    if (number === 0) result = 'zero';
    if (number < 0) {
        sign = 'minus';
        number = -number;
    }

    let g = 0;
    while (number > 0) {
        const s = Math.floor((number % 1000) / 100);
        let n = 0;
        let d = Math.floor((number % 100) / 10);
        let j = Math.floor(number % 10);

        if (d === 1 && j > 0) {
            n = j;
            d = 0;
            j = 0;
        }

        let k = 2;
        if (j === 1 && s + d + n === 0) k = 0;
        if (j === 2 || j === 3 || j === 4) k = 1;
        if (s + d + n + j > 0) result = setki[s] + tens[d] + nascie[n] + unity[j] + groups[g][k] + result;

        // eslint-disable-next-line no-plusplus
        g++;
        number = Math.floor(number / 1000);
    }
    return `${sign + result}`.trim();
}

function getMainUnity(amount) {
    if (amount === 1) return 'złoty';

    const amountAsString = amount.toString();
    const lastDigit = parseInt(amountAsString[amountAsString.length - 1], 10);
    if ((amount < 10 || amount > 20) && [2, 3, 4].includes(lastDigit)) {
        return 'złote';
    }

    return 'złotych';
}

function getRestUnity(amount) {
    if (amount === 1) return 'grosz';

    const amountAsString = amount.toString();
    const lastDigit = parseInt(amountAsString[amountAsString.length - 1], 10);
    if ((amount < 10 || amount > 20) && [2, 3, 4].includes(lastDigit)) {
        return 'grosze';
    }

    return 'groszy';
}

export function getWordsForPrice(price, separator = '.') {
    const stringPrice = price.toString();
    const splittedPrice = stringPrice.split(separator);

    const mainPart = parseInt(splittedPrice[0] || 0, 10);
    const restPart = parseInt(splittedPrice[1] || 0, 10);

    return `${getWordsForAmount(mainPart)} ${getMainUnity(mainPart)} i ${getWordsForAmount(restPart)} ${getRestUnity(
        restPart,
    )}`;
}
