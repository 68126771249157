import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import find from 'lodash/find';
import { useConfigContext } from '../../contexts/ConfigContext';
import { useNotification } from '../../contexts/NotificationContext';
import { sendRequest } from '../../utils/request';
import CompanyForm from './components/CompanyForm';
import AgreementForm from './components/AgreementForm';
import ApplicationForm from './components/ApplicationForm';
import Tabs from '../../components/Tabs';
import Grid from '../../components/Grid';
import Section from '../../components/Section';

import { getSettings } from '../../api';

function Settings() {
    const { t } = useTranslation();
    const [, { setPageTitle }] = useConfigContext();
    const [, { addError }] = useNotification();

    const [loading, setLoading] = useState(false);
    const [settings, setSettings] = useState([]);

    useEffect(() => {
        setPageTitle(t('Settings'));

        const fetchSettings = async () => {
            setLoading(true);
            try {
                const response = await sendRequest(getSettings(), {
                    method: 'GET',
                });

                if (response.status !== 200) {
                    addError(t(response.data.error));
                }

                setSettings(response.data.data);
                setLoading(false);
            } catch (e) {
                addError(t(e));
                setLoading(false);
            }
        };

        fetchSettings();
    }, []);

    const panes = [
        {
            menuItem: t('Company'),
            render: () => <CompanyForm details={find(settings, ['code', 'company'])} />,
        },
        {
            menuItem: t('Agreement'),
            render: () => <AgreementForm details={find(settings, ['code', 'reservation'])} />,
        },
        {
            menuItem: t('Application'),
            render: () => <ApplicationForm details={find(settings, ['code', 'application'])} />,
        },
    ];

    return (
        <Grid.Container fluid>
            <Grid.Row>
                <Grid.Col>{loading ? <Section loading={loading} /> : <Tabs panes={panes} />}</Grid.Col>
            </Grid.Row>
        </Grid.Container>
    );
}

export default Settings;
