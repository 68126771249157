import { formatValue } from 'react-currency-input-field';
import { set, forEach, reduce, has, keys } from 'lodash';

export const DEFAULT_CURRENCY_CONFIG = {
    groupSeparator: ' ',
    decimalSeparator: '.',
    decimalScale: 2,
};
export const getFormattedPrice = (value, config = { ...DEFAULT_CURRENCY_CONFIG }) =>
    formatValue({
        value: `${value}`,
        ...config,
    });

export const getReservationFinances = (reservation) => {
    const { services = [], advancePayment = [] } = reservation;
    const currencies = {};
    forEach(services, ({ cost, provision, currency }) => {
        if (!has(currencies, currency)) {
            currencies[currency] = {};
        }
        if (!has(currencies[currency], 'costs')) {
            currencies[currency].costs = 0;
        }

        currencies[currency].costs = currencies[currency].costs + parseFloat(cost) + parseFloat(provision);
    });

    forEach(advancePayment, ({ amount, currency }) => {
        if (!has(currencies, currency)) {
            currencies[currency] = {};
        }
        if (!has(currencies[currency], 'advancePayments')) {
            currencies[currency].advancePayments = 0;
        }
        currencies[currency].advancePayments += parseFloat(amount);
    });

    return reduce(
        keys(currencies),
        (result, currency) => {
            const { costs = 0, advancePayments = 0 } = currencies[currency];

            set(result, currency, {
                ...currencies[currency],
                toPay: costs - advancePayments,
            });

            return result;
        },
        {},
    );
};
