import { countryTuples } from 'country-region-data';

const currency_code = [
    'AFA',
    'ALL',
    'DZD',
    'AOA',
    'ARS',
    'AMD',
    'AWG',
    'AUD',
    'AZN',
    'BSD',
    'BHD',
    'BDT',
    'BBD',
    'BYR',
    'BEF',
    'BZD',
    'BMD',
    'BTN',
    'BTC',
    'BOB',
    'BAM',
    'BWP',
    'BRL',
    'GBP',
    'BND',
    'BGN',
    'BIF',
    'KHR',
    'CAD',
    'CVE',
    'KYD',
    'XOF',
    'XAF',
    'XPF',
    'CLP',
    'CNY',
    'COP',
    'KMF',
    'CDF',
    'CRC',
    'HRK',
    'CUC',
    'CZK',
    'DKK',
    'DJF',
    'DOP',
    'XCD',
    'EGP',
    'ERN',
    'EEK',
    'ETB',
    'EUR',
    'FKP',
    'FJD',
    'GMD',
    'GEL',
    'DEM',
    'GHS',
    'GIP',
    'GRD',
    'GTQ',
    'GNF',
    'GYD',
    'HTG',
    'HNL',
    'HKD',
    'HUF',
    'ISK',
    'INR',
    'IDR',
    'IRR',
    'IQD',
    'ILS',
    'ITL',
    'JMD',
    'JPY',
    'JOD',
    'KZT',
    'KES',
    'KWD',
    'KGS',
    'LAK',
    'LVL',
    'LBP',
    'LSL',
    'LRD',
    'LYD',
    'LTL',
    'MOP',
    'MKD',
    'MGA',
    'MWK',
    'MYR',
    'MVR',
    'MRO',
    'MUR',
    'MXN',
    'MDL',
    'MNT',
    'MAD',
    'MZM',
    'MMK',
    'NAD',
    'NPR',
    'ANG',
    'TWD',
    'NZD',
    'NIO',
    'NGN',
    'KPW',
    'NOK',
    'OMR',
    'PKR',
    'PAB',
    'PGK',
    'PYG',
    'PEN',
    'PHP',
    'PLN',
    'QAR',
    'RON',
    'RUB',
    'RWF',
    'SVC',
    'WST',
    'SAR',
    'RSD',
    'SCR',
    'SLL',
    'SGD',
    'SKK',
    'SBD',
    'SOS',
    'ZAR',
    'KRW',
    'XDR',
    'LKR',
    'SHP',
    'SDG',
    'SRD',
    'SZL',
    'SEK',
    'CHF',
    'SYP',
    'STD',
    'TJS',
    'TZS',
    'THB',
    'TOP',
    'TTD',
    'TND',
    'TRY',
    'TMT',
    'UGX',
    'UAH',
    'AED',
    'UYU',
    'USD',
    'UZS',
    'VUV',
    'VEF',
    'VND',
    'YER',
    'ZMK',
];

export const currencies = currency_code.map((currency) => ({
    label: currency,
    value: currency,
}));

export const countries = countryTuples.map((countryData) => ({
    label: countryData[0],
    value: countryData[1],
}));

export const genders = [
    {
        value: 'male',
        label: 'Male',
    },
    {
        value: 'female',
        label: 'Female',
    },
];

export const statuses = [
    {
        value: 1,
        label: 'Initial',
        color: 'warning',
    },
    {
        value: 2,
        label: 'Confirmed',
        color: 'success',
    },
    {
        value: 3,
        label: 'Cancelled',
        color: 'danger',
    },
    {
        value: 4,
        label: 'Realized',
        color: 'primary',
    },
    {
        value: 5,
        label: 'Blocked',
        color: 'dark',
    },
];

export const units = [
    {
        value: 'percent',
        label: 'Percent',
    },
    {
        value: 'piece',
        label: 'Pcs.',
    },
];

export const yesno = [
    {
        value: false,
        label: 'No',
    },
    {
        value: true,
        label: 'Yes',
    },
];
