import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import find from 'lodash/find';
import keys from 'lodash/keys';
import reduce from 'lodash/reduce';
import startCase from 'lodash/startCase';
import { useTranslation } from 'react-i18next';
import { HiX } from 'react-icons/hi';
import { getDateFormat, getMomentObject } from '../../../../utils/date';

function FilterBar({ columns, filters, onFilterChange, resetRows }) {
    const { t } = useTranslation();

    const handleClearFilter = (field, payload) =>
        onFilterChange(
            {
                ...filters,
                [field]: payload,
            },
            () => resetRows(),
        );

    const handleClearAll = () => {
        onFilterChange({}, () => resetRows());
    };

    const formattedFilters = reduce(
        keys(filters),
        (result, field) => {
            // eslint-disable-next-line no-param-reassign
            result[field] = { ...filters[field] };

            const fieldType = get(find(columns, ['field', field]), 'type');

            if (fieldType === 'date') {
                // eslint-disable-next-line no-param-reassign
                result[field].value = `${
                    filters[field].value[0] ? getMomentObject(filters[field].value[0]).format(getDateFormat()) : ''
                } - ${filters[field].value[1] ? getMomentObject(filters[field].value[1]).format(getDateFormat()) : ''}`;
            }
            if (fieldType === 'number') {
                // eslint-disable-next-line no-param-reassign
                result[field].value = filters[field].value.join(' - ');
            } else if (fieldType === 'dropdown') {
                const options = get(find(columns, ['field', field]), 'options', []);
                // eslint-disable-next-line no-param-reassign
                result[field].value = t(
                    get(find(options, ['value', filters[field].value]), 'label', filters[field].value),
                );
            } else if (fieldType === 'multiDropdown') {
                const options = get(find(columns, ['field', field]), 'options', []);
                // eslint-disable-next-line no-param-reassign
                result[field].value = filters[field].value
                    .map((item) => t(get(find(options, ['value', item]), 'label', item)))
                    .join(', ');
            }
            return result;
        },
        {},
    );

    if (!keys(filters).length) return null;
    return (
        <ul className="flex items-baseline px-1 py-1.5 border-t bg-blue-500 dark:bg-blue-800">
            {keys(formattedFilters).map((key) => (
                <li key={key}>
                    <span className="badge">
                        <span>
                            {t(startCase(key))}: {get(formattedFilters, `${key}.value`, '')}
                        </span>
                        <button
                            type="button"
                            className="w-4 h-4 p-0 ml-1 text-sm btn-icon lg:h-4 lg:w-4"
                            onClick={() => handleClearFilter(key, { value: '' })}
                        >
                            <HiX />
                        </button>
                    </span>
                </li>
            ))}
            {keys(formattedFilters).length > 1 && (
                <li>
                    <button type="button" className="flex items-center badge" onClick={handleClearAll}>
                        <span>{t('Clear All')}</span>
                        <span className="ml-2">
                            <HiX />
                        </span>
                    </button>
                </li>
            )}
        </ul>
    );
}

FilterBar.propTypes = {
    columns: PropTypes.array,
    filters: PropTypes.object,
    onFilterChange: PropTypes.func,
    resetRows: PropTypes.func,
};

FilterBar.defaultProps = {
    columns: [],
    filters: {},
    onFilterChange: () => {},
    resetRows: () => {},
};

export default FilterBar;
