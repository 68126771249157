import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import { useNotification } from '../../../../contexts/NotificationContext';
import { sendRequest } from '../../../../utils/request';
import { getDictionary, getAgents } from '../../../../api';

export const useDictionaries = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [dictionaries, setDictionaries] = useState({
        operators: [],
        hotelTypes: [],
        reservationTypes: [],
        agents: [],
    });

    const [, { addError }] = useNotification();

    useEffect(() => {
        const fetchDictionaries = async () => {
            setLoading(true);
            try {
                const response = await sendRequest(getDictionary(), {
                    method: 'POST',
                    data: {
                        filter: { isActive: { value: true, operator: 'eq' } },
                        sort: {
                            field: 'value',
                            direct: 'ASC',
                        },
                        fields: ['value', 'group'],
                        limit: {
                            size: 999,
                            page: 0,
                        },
                    },
                });

                if (response.status !== 200) {
                    addError(t(response.data.error));
                }

                const agentResponse = await sendRequest(getAgents(), {
                    method: 'POST',
                    data: {
                        filter: { isActive: { value: true, operator: 'eq' } },
                        sort: {
                            field: 'name',
                            direct: 'ASC',
                        },
                        fields: ['name', 'phone'],
                        limit: {
                            size: 999,
                            page: 0,
                        },
                    },
                });

                if (agentResponse.status !== 200) {
                    addError(t(agentResponse.data.error));
                }

                const dictionaries = get(response.data.data, 'items', []).map(
                    ({ _id: value, value: label, group }) => ({
                        value,
                        label,
                        group,
                    }),
                );

                const agents = get(agentResponse.data.data, 'items', []).map(({ _id: value, name, phone }) => ({
                    value,
                    label: `${name} ${phone}`,
                }));

                setDictionaries((state) => ({
                    ...state,
                    agents,
                    operators: dictionaries.filter(({ group }) => group === 'operators'),
                    hotelTypes: dictionaries.filter(({ group }) => group === 'hotel_types'),
                    reservationTypes: dictionaries.filter(({ group }) => group === 'reservation_type'),
                }));
                setLoading(false);
            } catch (e) {
                addError(t(e));
                setLoading(false);
            }
        };

        fetchDictionaries();
    }, []);

    return {
        loading,
        dictionaries,
    };
};
