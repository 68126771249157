import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import FormItem from '../../../../../FormItem';

function InputFilterField({ column, filters, onFilterChange }) {
    const { label, field } = column;
    const [value, setValue] = useState('');

    useEffect(() => {
        setValue(get(filters[field], 'value', ''));
    }, [filters[field]]);

    function handleDebounceFn(value) {
        onFilterChange(field, { value, operator: 'like' });
    }
    const debounceFn = useCallback(debounce(handleDebounceFn, 500), [filters, field]);

    const handleChange = (event) => {
        setValue(event.target.value);
        debounceFn(event.target.value);
    };
    return <FormItem label={label} name={field} value={value} onChange={handleChange} />;
}

InputFilterField.propTypes = {
    column: PropTypes.object,
    filters: PropTypes.object,
    onFilterChange: PropTypes.func,
};

InputFilterField.defaultProps = {
    column: {},
    filters: {},
    onFilterChange: () => {},
};

export default InputFilterField;
