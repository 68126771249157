import { useState } from 'react';

const useConfig = () => {
    const [loading, setLoading] = useState(false);
    const [pageTitle, setPageTitle] = useState('');

    return [
        { loading, pageTitle },
        { setLoading, setPageTitle },
    ];
};

export default useConfig;
