import React from 'react';
import PropTypes from 'prop-types';
import { ToastProvider } from 'react-toast-notifications';

export { default as useNotification } from './useNotification';

function NotificationContextProvider({ children }) {
    return <ToastProvider autoDismiss>{children}</ToastProvider>;
}

NotificationContextProvider.propTypes = {
    children: PropTypes.any,
};

NotificationContextProvider.defaultProps = {
    children: null,
};

export default NotificationContextProvider;
