import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { get, omit } from 'lodash';
import Yup from '../../../../utils/yup';
import { downloadReservationPdf } from '../../../../components/ReservationPdf/ReservationPdf';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useNotification } from '../../../../contexts/NotificationContext';
import { sendRequest } from '../../../../utils/request';
import { printReservation, createReservationItem, updateReservationItem } from '../../../../api';

import urls from '../../../../setup/urls';

const WITH_PRINT = 'withPrint';

export const formSchema = Yup.object().shape({
    operator: Yup.string().max(255),
    reservationNo: Yup.string().required(),
    agent: Yup.string().required().max(255),
    reservationType: Yup.array().of(Yup.string()),
    groupNo: Yup.string().max(255),

    country: Yup.array().of(Yup.string().max(255)),
    startPlace: Yup.string().max(255),
    hotel: Yup.string().max(255),
    hotelRate: Yup.string().max(255),
    startDate: Yup.date().required(),
    endDate: Yup.date().required(),
    adults: Yup.number(),
    kids: Yup.number(),
    babies: Yup.number(),

    status: Yup.number().required(),
    source: Yup.string().max(255),
    innerNotes: Yup.string(),
    paidOff: Yup.bool(),
    notes: Yup.string(),

    services: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().max(255).required(),
            number: Yup.number().min(1),
            price: Yup.number().required(),
            unit: Yup.string(),
            cost: Yup.number().required(),
            provisionPercent: Yup.number().min(0).max(100),
            provision: Yup.number().min(0),
        }),
    ),
    amountInWords: Yup.string().required(),

    advancePayment: Yup.array().of(
        Yup.object().shape({
            date: Yup.date(),
            amount: Yup.number().min(0),
            currency: Yup.string().required().max(255),
        }),
    ),
    surchargeDate: Yup.date().required(),

    clients: Yup.array().of(
        Yup.object().shape({
            lastName: Yup.string().max(255).required(),
            firstName: Yup.string().max(255).required(),
            birthDate: Yup.date().required(),
        }),
    ),
    purchaser: Yup.string().required().max(255),
});

export const useForm = ({ id }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [{ user }] = useAuthContext();
    const [loading, setLoading] = useState(false);

    const [, { addError, addSuccess }] = useNotification();

    const handlePrint = async ({ _id: id, reservationNo }) => {
        try {
            const response = await sendRequest(printReservation({ id }), {
                method: 'GET',
            });
            setLoading(false);
            if (response.status !== 200) {
                addError(t(response.data.error));
                return;
            }

            await downloadReservationPdf({ data: { ...response.data.data, user }, name: reservationNo });
            navigate(get(urls, 'reservations.route'));
        } catch (e) {
            setLoading(false);
            addError(e);
        }
    };

    const handleSubmit = async (values, actions) => {
        setLoading(true);
        const withPrint = get(values, WITH_PRINT, false);
        const api = id ? updateReservationItem({ id }) : createReservationItem();

        const response = await sendRequest(api, {
            method: id ? 'PUT' : 'POST',
            data: omit(values, [WITH_PRINT]),
        });
        actions.setSubmitting(false);
        if (response.status === 200) {
            addSuccess(t('Reservation was saved.'));
            if (withPrint) {
                handlePrint(response.data.data);
            } else {
                setLoading(false);
                navigate(get(urls, 'reservations.route'));
            }
        } else {
            setLoading(false);
            addError(t(response.data.error));
        }
    };

    const handleSubmitAndPrint = async (handleSubmit, setFieldValue) => {
        await setFieldValue(WITH_PRINT, true);
        handleSubmit();
    };

    return {
        loading,
        handleSubmit,
        handleSubmitAndPrint,
    };
};
