import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { get, find, flatten, keys } from 'lodash';
import {
    HiOutlinePencil,
    HiPlus,
    HiOutlineDownload,
    HiOutlinePrinter,
    HiOutlineDuplicate,
    HiOutlineCheckCircle,
    HiOutlineXCircle,
    HiOutlineEye,
} from 'react-icons/hi';

import { useChangeBulkStatus } from './useChangeBulkStatus';
import { useAuthContext } from '../../contexts/AuthContext';
import { useConfigContext } from '../../contexts/ConfigContext';
import { useNotification } from '../../contexts/NotificationContext';
import Badge from '../../components/Badge';
import ReservationStatusTooltip from '../../components/ReservationStatusTooltip';
import DescriptionList from '../../components/DescriptionList';
import { downloadReservationPdf } from '../../components/ReservationPdf/ReservationPdf';
import urls from '../../setup/urls';

import { useDictionaries } from '../../hooks/useDictionaries';
import { useSettings } from '../../hooks/useSettings';
import { reservationsApi } from '../../api';
import { statuses, yesno } from '../../utils/dictionaries';
import { getFormattedPrice, getReservationFinances } from '../../utils/price';
import { getFEFormattedDate } from '../../utils/date';
import { sendRequest } from '../../utils/request';

/* eslint-disable react/prop-types */
function LinkCell({ value, data: { id } = {} }) {
    return (
        <div className="rgt-cell-inner">
            <Link to={`${get(urls, 'reservationForm.route')}/${id}`}>{value}</Link>
        </div>
    );
}

function PurchaserCell({ data }) {
    return `${get(data, 'purchaser.lastName', '')} ${get(data, 'purchaser.firstName', '')}`;
}

function ReservationTypeCell({ data }) {
    return (
        <div className="flex flex-row flex-wrap gap-1 py-1">
            {get(data, 'reservationType', []).map(({ value }) => (
                <Badge key={value}>{value}</Badge>
            ))}
        </div>
    );
}

function CountryCell(countries) {
    return function ({ data }) {
        const { t } = useTranslation();
        return get(data, 'country', [])
            .map((countryCode) => t(get(find(countries, ['value', countryCode]), 'label', countryCode)))
            .join(', ');
    };
}

function StatusCell(statuses) {
    return function ({ value }) {
        const { t } = useTranslation();
        const status = get(find(statuses, ['value', parseInt(value, 10)]), 'label', value);
        const color = get(find(statuses, ['value', parseInt(value, 10)]), 'color', 'primary');
        return <Badge color={color}>{t(status)}</Badge>;
    };
}

function CostCell({ data }) {
    const { t } = useTranslation();

    const costs = getReservationFinances(data);

    return (
        <div className="flex flex-col items-start">
            {keys(costs).map((currency) => (
                <div>
                    <div className="">
                        {getFormattedPrice(get(costs[currency], 'costs', 0))} {currency}
                    </div>
                    <div className="">
                        <small title={t('Left to pay')}>
                            ({getFormattedPrice(get(costs[currency], 'toPay', 0))} {currency})
                        </small>
                    </div>
                </div>
            ))}
        </div>
    );
}

function PaidOffCell({ value }) {
    return (
        <div className="rgt-cell-inner">
            <div className="flex justify-center">
                {value === 'true' ? (
                    <HiOutlineCheckCircle className="text-3xl text-green-500" />
                ) : (
                    <HiOutlineXCircle className="text-3xl text-red-500" />
                )}
            </div>
        </div>
    );
}

function DeleteMessage({ item }) {
    const { t } = useTranslation();
    const { reservationNo, purchaser, services = [] } = item;

    const items = [
        {
            label: t('Reservation No'),
            value: reservationNo,
        },
    ];

    if (purchaser) {
        items.push({
            label: t('Purchaser'),
            value: `${purchaser.firstName} ${purchaser.lastName}, ${purchaser.email}`,
        });
    }

    if (services.length) {
        items.push({
            label: t('Services'),
            value: services.map(({ name }) => <p className="mb-0">{name}</p>),
        });
    }

    return (
        <div>
            <p>{t('Are you sure you want to delete this reservation?')}</p>
            <div className="mt-3">
                <DescriptionList items={items} />
            </div>
        </div>
    );
}
/* eslint-enable react/prop-types */

export const useReservations = () => {
    const { t } = useTranslation();
    const [{ user }] = useAuthContext();
    const [, { setLoading, setPageTitle }] = useConfigContext();
    const [, { addSuccess, addError }] = useNotification();
    const [{ data: dictionaries, isLoading: dictionariesLoading }] = useDictionaries(['reservation_type']);
    const [, { getCountries }] = useSettings();
    const [{ headerActions: changeBulkStatusHeaderActions, modalProps: changeStatusModalProps }] = useChangeBulkStatus({
        statuses,
    });
    const [csvInsuranceData, setCsvInsuranceData] = useState({
        data: [],
        fileName: 'insuranceCsv',
    });

    useEffect(() => {
        setPageTitle(t('Reservations'));
    }, []);

    const handleDuplicate = async (props) => {
        const { tableManager, api, data: { id } = {} } = props;
        api.onLoadingStarts();
        const response = await sendRequest(reservationsApi.duplicate({ id }), {
            method: 'GET',
        });

        if (response.status === 200) {
            addSuccess(t('Reservation was duplicated.'));
            api.onLoadingEnds();
            tableManager.asyncApi.resetRows();
        } else {
            addError(t(response.data.error));
            api.onLoadingEnds();
        }
    };

    const handlePrint = async (props) => {
        const { data: { id, reservationNo } = {} } = props;
        setLoading(true);
        try {
            const response = await sendRequest(reservationsApi.print({ id }), {
                method: 'GET',
            });
            setLoading(false);
            if (response.status !== 200) {
                addError(t(response.data.error));
                return;
            }
            await downloadReservationPdf({ data: { ...response.data.data, user }, name: reservationNo, t });
        } catch (e) {
            setLoading(false);
            addError(e);
        }
    };

    const clearCsvInusernceData = () => {
        setCsvInsuranceData({
            data: [],
            fileName: '',
        });
    };

    const blockedStatusId = get(find(statuses, ['label', 'Blocked']), 'value');
    const cancelStatusId = get(find(statuses, ['label', 'Cancelled']), 'value');
    const countries = getCountries();
    const tableConfig = {
        id: 'reservations',
        columns: React.useMemo(
            () => [
                {
                    field: 'reservationNo',
                    label: t('Reservation No'),
                    pinned: true,
                    type: 'string',
                    cellRenderer: LinkCell,
                },
                {
                    field: 'purchaser',
                    label: t('Purchaser'),
                    cellRenderer: PurchaserCell,
                },
                {
                    field: 'hotel',
                    label: t('Hotel'),
                },
                {
                    field: 'reservationType',
                    label: t('Reservation Type'),
                    type: 'multiDropdown',
                    sortable: false,
                    options: get(dictionaries, 'reservation_type', []),
                    width: '210px',
                    cellRenderer: ReservationTypeCell,
                },
                {
                    field: 'country',
                    label: t('Country'),
                    type: 'multiDropdown',
                    options: countries,
                    cellRenderer: CountryCell(countries),
                },
                {
                    field: 'created_at',
                    label: t('Create Date'),
                    type: 'date',
                    width: '160px',
                },
                {
                    field: 'startDate',
                    label: t('Start Date'),
                    type: 'date',
                    width: '150px',
                },
                {
                    field: 'endDate',
                    label: t('End Date'),
                    type: 'date',
                    width: '150px',
                },

                {
                    field: 'cost',
                    label: t('Cost'),
                    filterable: false,
                    sortable: false,
                    cellRenderer: CostCell,
                },
                {
                    field: 'paidOff',
                    label: t('Paid Off'),
                    type: 'dropdown',
                    options: yesno,
                    width: '100px',
                    cellRenderer: PaidOffCell,
                },
                {
                    field: 'status',
                    label: (
                        <div className="flex items-center justify-start gap-2 flex-nowrap">
                            {t('Status')}
                            <ReservationStatusTooltip />
                        </div>
                    ),
                    type: 'multiDropdown',
                    options: statuses,
                    width: '140px',
                    cellRenderer: StatusCell(statuses),
                },
            ],
            [dictionaries],
        ),
        headerActions: [
            {
                label: 'Add Reservation',
                icon: HiPlus,
                to: `${get(urls, 'reservationForm.route')}/create`,
                useSeparately: true,
            },
            {
                label: 'Export selected for insurance',
                icon: HiOutlineDownload,
                type: 'button',
                onClick: ({ rowsApi, rowSelectionApi }) => {
                    const clients = rowsApi.rows
                        .filter(({ id }) => rowSelectionApi.selectedRowsIds.includes(id))
                        .map(({ reservationNo, clients = [] }) =>
                            clients.map(({ birthDate, firstName, lastName }) => [
                                reservationNo,
                                lastName,
                                firstName,
                                birthDate ? getFEFormattedDate(birthDate) : '',
                            ]),
                        );

                    const fileName = rowsApi.rows
                        .filter(({ id }) => rowSelectionApi.selectedRowsIds.includes(id))
                        .map(({ reservationNo }) => reservationNo)
                        .join('_');

                    setCsvInsuranceData({
                        data: [['reservation', 'lastname', 'firstname', 'birthday'], ...flatten(clients)],
                        fileName,
                    });
                },
                disabled: ({ rowSelectionApi }) => rowSelectionApi.selectedRowsIds.length === 0,
                order: 100,
            },
            ...changeBulkStatusHeaderActions,
        ],
        rowActions: [
            {
                label: 'Edit',
                icon: HiOutlinePencil,
                to: ({ data: { id } = {} }) => `${get(urls, 'reservationForm.route')}/${id}`,
                isHidden: ({ data }) => get(data, 'status') === blockedStatusId,
            },
            {
                label: 'Preview',
                icon: HiOutlineEye,
                to: ({ data: { id } = {} }) => `${get(urls, 'reservationPreview.route')}/${id}`,
            },
            {
                label: 'Print',
                icon: HiOutlinePrinter,
                type: 'button',
                onClick: handlePrint,
                isHidden: ({ data }) => {
                    const statusId = get(data, 'status');
                    return statusId === blockedStatusId || statusId === cancelStatusId;
                },
            },
            {
                label: 'Duplicate',
                icon: HiOutlineDuplicate,
                type: 'button',
                onClick: handleDuplicate,
                isHidden: ({ data }) => get(data, 'status') === blockedStatusId,
            },
        ],
        initConfig: {
            sort: { field: 'created_at', direct: 'DESC' },
            filters: { status: { value: [1, 2], operator: 'in' } },
        },
        contextApi: reservationsApi,
        deleteMessage: DeleteMessage,
        withDelete: ({ data }) => get(data, 'status') !== blockedStatusId,
        isSelectable: true,
        withExport: true,
        withSearch: true,
        searchMessage: t('Search data by reservation No, country, hotel, purchaser or travellers'),
    };

    return {
        dictionariesLoading,
        changeStatusModalProps,
        csvInsuranceData,
        clearCsvInusernceData,
        tableConfig,
    };
};
