import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import values from 'lodash/values';

import { publicUrls, privateUrls } from '../setup/urls';
import desertBg from '../assets/desert.jpg';
import { useAuthContext } from '../contexts/AuthContext';
import LoadingMask from '../components/LoadingMask';
import NotLoggedLayout from '../layouts/NotLoggedLayout';
import NotLoggedLayoutWithContext from '../layouts/NotLoggedLayout/withContext';
import AdminLayoutWithContext from '../layouts/AdminLayout/withContext';
import NotFoundPage from '../pages/NotFoundPage';

function RouteComp({ children }) {
    return <Suspense fallback={<LoadingMask />}>{children}</Suspense>;
}

RouteComp.propTypes = {
    children: PropTypes.any,
};

RouteComp.defaultProps = {
    children: null,
};

function PagesRoot() {
    const [{ user, isLogged, loading: userIsLoading }] = useAuthContext();

    if (userIsLoading) return <LoadingMask />;
    return (
        <BrowserRouter>
            <Routes>
                {values(publicUrls).map(({ path, component }) => {
                    const PageComponent = component;
                    return (
                        <Route
                            key={path}
                            path={path}
                            element={
                                <Suspense fallback={<NotLoggedLayout loading />}>
                                    <NotLoggedLayoutWithContext>
                                        <PageComponent />
                                    </NotLoggedLayoutWithContext>
                                </Suspense>
                            }
                        />
                    );
                })}
                <Route path="/admin" element={<AdminLayoutWithContext />}>
                    {isLogged &&
                        values(privateUrls)
                            .filter(({ permissions = [] }) => {
                                if (!permissions.length) return true;
                                return permissions.includes(user.role);
                            })
                            .map(({ path, component }) => {
                                const PageComponent = component;
                                return (
                                    <Route
                                        key={path}
                                        path={path}
                                        element={
                                            <RouteComp>
                                                <PageComponent />
                                            </RouteComp>
                                        }
                                    />
                                );
                            })}
                </Route>
                <Route path="*" element={<NotLoggedLayoutWithContext background={desertBg} />}>
                    <Route path="*" element={<NotFoundPage />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default PagesRoot;
