import { useState } from 'react';

export const useModals = () => {
    const [modalData, setModalData] = useState({
        open: false,
    });

    const handleOpenModal = (payload) => {
        setModalData((state) => ({
            ...state,
            ...payload,
            open: true,
        }));
    };

    const handleCloseModal = () => {
        setModalData(() => ({
            open: false,
        }));
    };

    return [modalData, { handleOpenModal, handleCloseModal }];
};
