import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import get from 'lodash/get';
import FormItem from '../../../../../FormItem';

function DropdownFilterField({ column, filters, onFilterChange }) {
    const { label, field: name, options } = column;
    const [value, setValue] = useState('');
    const [field] = useField(name);

    useEffect(() => {
        setValue(get(filters[name], 'value', ''));
    }, [filters[name]]);

    useEffect(() => {
        if (field.value !== value) {
            setValue(field.value);
            onFilterChange(name, { value: field.value, operator: 'eq' });
        }
    }, [field]);

    return (
        <FormItem
            label={label}
            name={name}
            value={value}
            options={options}
            type="dropdown"
            isPortable={false}
            preselectSingleOption={false}
        />
    );
}

DropdownFilterField.propTypes = {
    column: PropTypes.object,
    filters: PropTypes.object,
    onFilterChange: PropTypes.func,
};

DropdownFilterField.defaultProps = {
    column: {},
    filters: {},
    onFilterChange: () => {},
};

export default DropdownFilterField;
