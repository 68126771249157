import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { upperFirst } from 'lodash';

import { useNotification } from '../NotificationContext';
import { authMe, authLogout } from '../../api';
import { sendRequest } from '../../utils/request';

export const ADMIN_ROLE = 'admin';
export const MANAGER_ROLE = 'manager';
export const WORKER_ROLE = 'worker';

export const AUTH_ROLES = [
    {
        value: WORKER_ROLE,
        label: upperFirst(WORKER_ROLE),
    },
    {
        value: MANAGER_ROLE,
        label: upperFirst(MANAGER_ROLE),
    },
    {
        value: ADMIN_ROLE,
        label: upperFirst('administrator'),
    },
];

const useAuth = () => {
    const { t } = useTranslation();
    const [, { addError }] = useNotification();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    const logout = async () => {
        const response = await sendRequest(authLogout(), {
            method: 'POST',
        });
        if (response.status === 200) {
            setUser(null);
        }
    };

    const me = async () => {
        setLoading(true);
        const response = await sendRequest(authMe());
        if (response.status === 200) {
            setUser(response.data.data);
        } else if (response.status === 400) {
            logout();
        } else {
            setLoading(false);
            throw Error(response.data.error, { cause: response.status });
        }
        setLoading(false);
    };

    useEffect(() => {
        const loadUser = async () => {
            try {
                await me();
            } catch (error) {
                if (error.cause === 401 && !window.location.pathname.includes('/admin')) {
                    return;
                }
                addError(t(error.message));
            }
        };
        if (
            window.location.pathname !== '/' &&
            !window.location.pathname.includes('forgot-password') &&
            !window.location.pathname.includes('reset-password') &&
            !window.location.pathname.includes('active-account') &&
            !window.location.pathname.includes('register')
        ) {
            loadUser();
        } else {
            setLoading(false);
        }
    }, []);

    return [
        {
            user,
            isLogged: !!user && !!user.name,
            loading,
            iAmAdmin: user && user.role === ADMIN_ROLE,
            iAmManager: user && user.role === MANAGER_ROLE,
            iAmWorker: user && user.role === WORKER_ROLE,
        },
        {
            setUser,
            setLoading,
            me,
            logout,
        },
    ];
};

export default useAuth;
