import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useField } from 'formik';
import { SingleDatePicker } from 'react-dates';
import { getDateFormat, getMomentObject } from '../../../../utils/date';

export const DEFAULT_DATE_PICKER_PROPS = {
    showDefaultInputIcon: true,
    showClearDate: true,
    hideKeyboardShortcutsPanel: true,
    isOutsideRange: () => false,
    firstDayOfWeek: 1,
    numberOfMonths: 1,
    displayFormat: getDateFormat(),
};

function FormDropdown({ inputWrapperClassName, datePickerProps, inputWrapperChildren, isInvalid, value, ...props }) {
    const { name } = props;
    const [, meta, helpers] = useField(name);
    const [focused, setFocused] = useState(false);

    useEffect(() => {
        if (!focused) {
            helpers.setTouched(true, true);
        }
    }, [focused]);

    const handleDateChange = (value) => {
        helpers.setError(undefined);
        helpers.setValue(value || '', false);
        helpers.setTouched(true, false);
    };

    const inputWrapperClass = classNames(inputWrapperClassName, {
        'date-field-focus': focused,
        'date-field-invalid': isInvalid,
    });

    const mergedDatePickerProps = {
        placeholder: '',
        ...DEFAULT_DATE_PICKER_PROPS,
        ...datePickerProps,
        id: name,
        focused,
        date: meta.value ? getMomentObject(meta.value) : null,
        onDateChange: handleDateChange,
        onFocusChange: ({ focused }) => setFocused(focused),
    };
    return (
        <div className={inputWrapperClass}>
            <SingleDatePicker {...mergedDatePickerProps} />
            {inputWrapperChildren}
        </div>
    );
}

FormDropdown.propTypes = {
    name: PropTypes.string.isRequired,
    inputWrapperClassName: PropTypes.string,
    inputWrapperChildren: PropTypes.any,
    isInvalid: PropTypes.bool,
    value: PropTypes.string,
    datePickerProps: PropTypes.object,
};

FormDropdown.defaultProps = {
    inputWrapperClassName: '',
    inputWrapperChildren: null,
    isInvalid: false,
    value: '',
    datePickerProps: {},
};

export default FormDropdown;
