import {
    HiOutlineDocumentText,
    HiUserGroup,
    HiBookOpen,
    HiOutlineCog,
    HiOutlineClipboardList,
    HiOutlineIdentification,
    HiOutlineUsers,
} from 'react-icons/hi';
import get from 'lodash/get';
import { MANAGER_ROLE, ADMIN_ROLE } from '../contexts/AuthContext/useAuth';
import urls from './urls';

function getNavItems({ t }) {
    return [
        {
            to: get(urls, 'reservations.route'),
            icon: HiOutlineDocumentText,
            label: t('Reservations'),
        },
        {
            to: get(urls, 'clients.route'),
            icon: HiUserGroup,
            label: t('Clients'),
        },
        {
            divider: true,
        },
        {
            to: get(urls, 'services.route'),
            icon: HiOutlineClipboardList,
            label: t('Services'),
            permissions: [MANAGER_ROLE, ADMIN_ROLE],
        },
        {
            to: get(urls, 'agents.route'),
            icon: HiOutlineIdentification,
            label: t('Agents'),
            permissions: [MANAGER_ROLE, ADMIN_ROLE],
        },
        {
            to: `${get(urls, 'dictionary.route')}/reservation-types`,
            icon: HiBookOpen,
            label: t('Dictionaries'),
            permissions: [MANAGER_ROLE, ADMIN_ROLE],
        },
        {
            divider: true,
        },
        {
            to: get(urls, 'users.route'),
            icon: HiOutlineUsers,
            label: t('Users'),
            permissions: [ADMIN_ROLE],
        },
        {
            to: get(urls, 'settings.route'),
            icon: HiOutlineCog,
            label: t('Settings'),
            permissions: [ADMIN_ROLE],
        },
    ];
}

export default getNavItems;
