import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { HiChevronDoubleLeft, HiChevronLeft, HiChevronRight, HiChevronDoubleRight } from 'react-icons/hi';
import './styles.module.css';

function getPaginationButtons(current_page, last_page, onSides = 3) {
    const pages = [];
    for (let i = 1; i <= last_page; i += 1) {
        const offset = i === 1 || last_page ? onSides + 1 : onSides;
        if ((current_page - offset <= i && current_page + offset >= i) || i === current_page) {
            pages.push(i);
        } else if (i === current_page - (offset + 1) || i === current_page + (offset + 1)) {
            pages.push('...');
        }
    }
    return pages;
}

function Pagination({ tableManager }) {
    const { t } = useTranslation();
    const { paginationApi, asyncApi } = tableManager;

    const handlePaginationChange = (activePage) => {
        asyncApi.resetRows();
        paginationApi.setPage(activePage);
    };

    const pages = getPaginationButtons(paginationApi.page, paginationApi.totalPages, 1);
    return (
        <nav aria-label="Page navigation example">
            <ul className="inline-flex items-center -space-x-px">
                {pages.length ? (
                    <Fragment>
                        <li>
                            <button
                                type="button"
                                onClick={() => handlePaginationChange(1)}
                                className="pagination-button"
                                title={t('First Page')}
                                disabled={paginationApi.page === 1}
                            >
                                <span className="sr-only">{t('First Page')}</span>
                                <HiChevronDoubleLeft />
                            </button>
                        </li>
                        <li>
                            <button
                                type="button"
                                onClick={() => handlePaginationChange(paginationApi.page - 1)}
                                className="pagination-button"
                                title={t('Previous')}
                                disabled={paginationApi.page === 1}
                            >
                                <span className="sr-only">{t('Previous')}</span>
                                <HiChevronLeft />
                            </button>
                        </li>
                        {pages.map((page) => (
                            <li key={page}>
                                {page === '...' && <span className="pagination-span">{page}</span>}
                                {page === paginationApi.page && <span className="pagination-current-page">{page}</span>}
                                {page !== '...' && page !== paginationApi.page && (
                                    <button
                                        type="button"
                                        onClick={() => handlePaginationChange(page)}
                                        className="pagination-button"
                                        title={page}
                                    >
                                        <span>{page}</span>
                                    </button>
                                )}
                            </li>
                        ))}
                        <li>
                            <button
                                type="button"
                                onClick={() => handlePaginationChange(paginationApi.page + 1)}
                                className="pagination-button"
                                title={t('Next')}
                                disabled={paginationApi.page === paginationApi.totalPages}
                            >
                                <span className="sr-only">{t('Next')}</span>
                                <HiChevronRight />
                            </button>
                        </li>
                        <li>
                            <button
                                type="button"
                                onClick={() => handlePaginationChange(paginationApi.totalPages)}
                                className="pagination-button"
                                title={t('Last Page')}
                                disabled={paginationApi.page === paginationApi.totalPages}
                            >
                                <span className="sr-only">{t('Last Page')}</span>
                                <HiChevronDoubleRight />
                            </button>
                        </li>
                    </Fragment>
                ) : (
                    <li>
                        <span className="pagination-current-page">1</span>
                    </li>
                )}
            </ul>
        </nav>
    );
}

Pagination.propTypes = {
    tableManager: PropTypes.object.isRequired,
};

Pagination.defaultProps = {};

export default Pagination;
