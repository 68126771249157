import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { HiOutlinePlus } from 'react-icons/hi';
import { Form, Formik } from 'formik';
import { BiSquare, BiCheckSquare } from 'react-icons/bi';
import Button from '../../../../../../components/Button';
import FormItem from '../../../../../../components/FormItem';
import Loader from '../../../../../../components/Loader';
import List from '../../../../../../components/List';
import { getFEFormattedDate } from '../../../../../../utils/date';
import { formSchema, useClientSearchList } from './useClientSearchList';

function ClientSearchList({ multi, onSelection }) {
    const { t } = useTranslation();
    const [{ loading, items }, { handleToggleSelection, handleChangeSearchInput }] = useClientSearchList();

    const selectedItemsCount = items.filter((item) => item.isSelected).length;
    return (
        <div>
            <Formik
                initialValues={{
                    search: '',
                }}
                validationSchema={formSchema}
                enableReinitialize
                onSubmit={() => {}}
            >
                {({ handleChange }) => (
                    <Form>
                        <FormItem
                            name="search"
                            validationSchema={formSchema}
                            onChange={(e) => {
                                handleChange(e);
                                handleChangeSearchInput(e.target.value);
                            }}
                            placeholder={t('Type to start searching')}
                        />
                        {loading && (
                            <div className="absolute right-[32px] mt-[-44px] text-blue-500">
                                <Loader />
                            </div>
                        )}
                    </Form>
                )}
            </Formik>
            <div className="overflow-y-auto max-h-80">
                <List items={items}>
                    {(item) => {
                        const { id, firstName, lastName, birthDate, email, phone, isSelected = false } = item;
                        return (
                            <div key={email} className="flex flex-row items-center justify-between w-full py-2">
                                <div className="pr-2 truncate">
                                    <p className="truncate">
                                        {lastName} {firstName} ({getFEFormattedDate(birthDate)})
                                    </p>
                                    <p className="text-sm font-medium truncate">{email}</p>
                                    <p className="text-sm font-medium truncate">{phone}</p>
                                </div>
                                {multi && (
                                    <div className="text-[32px]">
                                        {isSelected ? (
                                            <BiCheckSquare
                                                className="text-blue-600 cursor-pointer"
                                                onClick={() => handleToggleSelection(id)}
                                            />
                                        ) : (
                                            <BiSquare
                                                className="text-gray-600 cursor-pointer"
                                                onClick={() => handleToggleSelection(id)}
                                            />
                                        )}
                                    </div>
                                )}
                                {!multi && (
                                    <div>
                                        <Button onClick={() => onSelection(item)} iconOnly>
                                            <HiOutlinePlus />
                                        </Button>
                                    </div>
                                )}
                            </div>
                        );
                    }}
                </List>
            </div>
            {multi && (
                <div className="flex flex-col items-stretch pt-2 justify-stretch">
                    <Button
                        onClick={() => onSelection(items.filter((item) => item.isSelected))}
                        disabled={!selectedItemsCount}
                    >
                        {t('Select')}
                        {selectedItemsCount > 0 && ` (${selectedItemsCount})`}
                    </Button>
                </div>
            )}
        </div>
    );
}

ClientSearchList.propTypes = {
    multi: PropTypes.bool,
    onSelection: PropTypes.func,
};

ClientSearchList.defaultProps = {
    multi: false,
    onSelection: () => {},
};

export default ClientSearchList;
