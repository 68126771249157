import React, { useEffect, useState, useRef, Fragment, useCallback } from 'react';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import has from 'lodash/has';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import omit from 'lodash/omit';
import keys from 'lodash/keys';
import reduce from 'lodash/reduce';
import isMatch from 'lodash/isMatch';
import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';
import { useTranslation } from 'react-i18next';
import { HiOutlineTrash, HiDotsVertical } from 'react-icons/hi';
import { useNotification } from '../../contexts/NotificationContext';
import Menu from '../Menu';
import { sendRequest } from '../../utils/request';
import { getStorage, setStorage } from '../../utils/localStorage';
import { getDateFormat, getMomentObject } from '../../utils/date';
import { createBlobFromResponse } from '../../utils/blob';
import useTableReducer from './useReducer';

let controller = new AbortController();

const LOCAL_STORAGE_PREFIX = 'grid-';

function useTable({
    id,
    columns,
    initConfig,
    contextApi,
    isSelectable,
    rowActions = [],
    onSelectionChange,
    getCustomPayload,
    withDelete,
}) {
    const rowsRef = useRef([]);
    const callbackRef = useRef();
    const { t } = useTranslation();
    const [, { addError, addSuccess }] = useNotification();
    const [state, api] = useTableReducer();
    const [deleteModalConfig, setDeleteModalConfig] = useState({
        isOpen: false,
        item: null,
        tableManager: null,
    });

    useEffect(() => {
        if (!state.isLoading) {
            setStorage(`${LOCAL_STORAGE_PREFIX}-${id}`, JSON.stringify(omit(state, ['rows', 'totalSize'])));
        }
    }, [state]);

    useEffect(() => {
        if (callbackRef.current) {
            callbackRef.current();
        }
    }, [state.filters]);

    useEffect(() => {
        const savedData = JSON.parse(getStorage(`${LOCAL_STORAGE_PREFIX}-${id}`, JSON.stringify(initConfig)));
        api.init(savedData);
    }, []);

    const getFormattedFilters = (filters) =>
        reduce(
            keys(filters),
            (result, key) => {
                const { operator, value } = filters[key];
                // eslint-disable-next-line no-param-reassign
                result[key] = {
                    operator,
                    value,
                };

                if (operator === 'between') {
                    const starDate = !Number.isNaN(new Date(value[0]));
                    const endDate = !Number.isNaN(new Date(value[1]));

                    if (value[0] && starDate) {
                        // eslint-disable-next-line no-param-reassign
                        result[key].value[0] = `${value[0]} 00:00:00`;
                    }
                    if (value[1] && endDate) {
                        // eslint-disable-next-line no-param-reassign
                        result[key].value[1] = `${value[1]} 23:59:59`;
                    }
                }

                return result;
            },
            {},
        );

    const getFieldToRequest = () =>
        columns.filter(({ field }) => !state.hiddenColumns.includes(field)).map(({ field }) => field);

    const handleOpenDeleteModal = ({ data, tableManager }) => {
        setDeleteModalConfig((state) => ({
            ...state,
            isOpen: true,
            item: data,
            tableManager,
        }));
    };

    const handleCloseDeleteModal = () => {
        setDeleteModalConfig((state) => ({
            ...state,
            isOpen: false,
            item: null,
            tableManager: null,
        }));
    };

    const handleRowsRequest = async (requestData, tableManager) => {
        controller.abort();

        if (!tableManager.isInitialized) {
            return;
        }

        api.onLoadingStarts();
        controller = new AbortController();
        try {
            const payload = {
                fields: getFieldToRequest(),
                sort: {
                    direct: requestData.sort.isAsc ? 'ASC' : 'DESC',
                    field: requestData.sort.colId,
                },
                limit: {
                    page: state.page - 1,
                    size: state.pageSize,
                },
                filter: getFormattedFilters({ ...cloneDeep(state.filters) }),
            };
            const response = await sendRequest(contextApi.get(), {
                method: 'POST',
                data: getCustomPayload(payload),
                signal: controller.signal,
            });

            if (response.status !== 200 && response.data.error) {
                addError(t(response.data.error));
            }

            if (response.data.success) {
                tableManager.rowSelectionApi.setSelectedRowsIds([]);
                rowsRef.current = tableManager.asyncApi.mergeRowsAt(
                    [],
                    get(response, 'data.data.items', []).map((item) => ({
                        ...item,
                        id: get(item, '_id', get(item, 'id')),
                    })),
                    requestData.from,
                );

                api.onRowsChange({
                    rows: get(response, 'data.data.items', []),
                    totalSize: get(response, 'data.data.totalSize', 0),
                });
            }
        } catch (e) {
            addError(t(e));

            rowsRef.current = tableManager.asyncApi.mergeRowsAt(rowsRef.current, [], requestData.from);
            api.onRowsChange({
                rows: [],
                totalSize: 0,
            });
        }
    };

    const handleDeleteItemRequest = async () => {
        try {
            const response = await sendRequest(contextApi.delete(deleteModalConfig.item), {
                method: 'DELETE',
                signal: controller.signal,
            });

            if (response.status !== 200) {
                addError(t(response.data.error));
            } else {
                addSuccess(t('Item was deleted.'));
                deleteModalConfig.tableManager.asyncApi.resetRows();
            }
        } catch (e) {
            addError(t(e));
        }
        handleCloseDeleteModal();
    };

    const handleImportRequest = async ({ file, tableManager, onSuccess }) => {
        const formData = new FormData();
        formData.append('csv', file);

        try {
            const response = await sendRequest(contextApi.import(), {
                method: 'POST',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                signal: controller.signal,
            });

            if (response.status !== 200) {
                addError(t(response.data.error));
            } else {
                addSuccess(t('{{number}} items was imported.', { number: response.data.data.items }));
                tableManager.asyncApi.resetRows();
                onSuccess();
            }
        } catch (e) {
            addError(t(e));
        }
    };

    const handleExportRequest = async ({ isCurrentPage }) => {
        let payload = {};
        if (isCurrentPage) {
            payload = {
                fields: getFieldToRequest(),
                limit: {
                    page: state.page - 1,
                    size: state.pageSize,
                },
                filter: { ...state.filters },
            };
        }
        const response = await sendRequest(contextApi.export(), {
            method: 'POST',
            data: getCustomPayload(payload),
            headers: {
                'Content-Type': 'application/json',
            },
            signal: controller.signal,
        });

        if (response.status !== 200) {
            addError(t(response.data.error));
            return;
        }
        const fileName = `${id}-export${isCurrentPage ? '-page' : ''}.csv`;
        createBlobFromResponse(response, fileName, 'text/csv');
    };

    const handleRowsReset = () => {
        rowsRef.current = [];
        controller.abort();
    };

    const handleFiltersChange = (payload, callback) => {
        const filteredPayload = reduce(
            keys(payload),
            (result, field) => {
                const value = get(payload[field], 'value');

                if (
                    (isArray(value) && !!value.filter((value) => !!value).length) ||
                    (!isArray(value) && value !== '')
                ) {
                    set(result, field, payload[field]);
                }

                return result;
            },
            {},
        );

        if (
            isMatch(state.filters, filteredPayload) &&
            JSON.stringify(state.filters) === JSON.stringify(filteredPayload)
        ) {
            return;
        }
        callbackRef.current = callback;

        api.onFiltersChange(filteredPayload);
    };
    const handlePageChange = (nextPage) => {
        api.onPageChange(nextPage);
    };

    const handlePageSizeChange = (newPageSize) => {
        api.onPageSizeChange(newPageSize);
    };

    const handleSortChange = ({ colId, isAsc }) => {
        api.onSortChange({ field: colId, direct: isAsc ? 'ASC' : 'DESC' });
    };

    const handleColumnsChange = (columns) => {
        api.onColumnsChange({
            hiddenColumns: columns.filter(({ visible }) => !visible).map(({ id }) => id),
        });
    };

    const handleSelectedRowsChange = (selectedRowsIds) => {
        onSelectionChange(columns.filter(({ id }) => selectedRowsIds.includes(id)));
    };

    const getActionColRender = useCallback(
        (props) => {
            const actionToRender = [...rowActions];
            if (contextApi.delete && withDelete(props)) {
                actionToRender.push({
                    onClick: get(props, 'tableManager.config.additionalProps.api.onOpenDeleteModal'),
                    label: t('Delete'),
                    icon: HiOutlineTrash,
                    type: 'button',
                    className: 'hover:text-red-600 dark:hover:text-red-600',
                });
            }

            const menuItems = actionToRender
                .filter(({ isHidden = () => false }) => !isHidden(props))
                .map((item) => {
                    const newItem = { ...item };
                    if (has(item, 'to') && isFunction(item.to)) {
                        set(newItem, 'to', item.to({ ...props, api }));
                    }

                    if (has(item, 'onClick') && isFunction(item.onClick)) {
                        const itemOnClick = get(item, 'onClick');
                        set(newItem, 'onClick', () => itemOnClick({ ...props, api }));
                    }

                    set(newItem, 'label', t(newItem.label));
                    return newItem;
                });

            if (!menuItems.length) return null;

            return (
                <Menu
                    wrapperClassName="z-50"
                    triggerClassName="btn-icon mx-2"
                    trigger={() => (
                        <Fragment>
                            <HiDotsVertical />
                            <span className="sr-only">{t('Menu')}</span>
                        </Fragment>
                    )}
                    hideChevron
                    items={menuItems}
                    position="right"
                />
            );
        },
        [contextApi.delete, rowActions],
    );

    const getFormattedColumns = () => {
        const extraColumns = [];

        if (isSelectable) {
            extraColumns.push({
                id: 'checkbox',
                field: 'checkbox',
                pinned: true,
            });
        }

        if (rowActions.length > 0 && findIndex(columns, ['field', 'actions']) < 0) {
            columns.push({
                field: 'actions',
                label: '',
                width: 'max-content',
                pinned: true,
                sortable: false,
                resizable: false,
                cellRenderer: getActionColRender,
            });
        }

        return [
            ...extraColumns,
            ...columns.map((column) => {
                if (get(column, 'type') === 'date' && !has(column, 'cellRenderer')) {
                    set(column, 'cellRenderer', ({ value }) =>
                        value ? getMomentObject(value).format(getDateFormat()) : '',
                    );
                }

                return {
                    ...column,
                    id: column.field,
                    // resizable: false,
                    visible:
                        !state.hiddenColumns.includes(column.field) || ['checkbox', 'actions'].includes(column.field),
                };
            }),
        ];
    };

    return {
        state,
        rowsRef,
        formattedColumns: getFormattedColumns(),
        deleteModalConfig,
        api: {
            handleRowsRequest,
            handlePageChange,
            handlePageSizeChange,
            handleSortChange,
            handleColumnsChange,
            handleSelectedRowsChange,
            handleFiltersChange,
            handleRowsReset,
            handleCloseDeleteModal,
            handleDeleteItemRequest,
            handleExportRequest,
            handleImportRequest,
            handleOpenDeleteModal,
        },
    };
}

export default useTable;
