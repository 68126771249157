import { useMemo } from 'react';
import get from 'lodash/get';
import { values, reduce, set } from 'lodash';

export const ADD_SERVICE_FIELD_NAME = 'add_service';

export const useTotals = ({ field }) => {
    const getTotals = (services, field) =>
        values(
            reduce(
                services,
                (result, service) => {
                    const { currency } = service;
                    const currentTotalForCurrency = get(result, `${currency}.value`, 0);
                    set(result, currency, {
                        value: parseFloat(currentTotalForCurrency) + parseFloat(service[field]),
                        currency,
                    });
                    return result;
                },
                {},
            ),
        );

    const totalCost = useMemo(() => getTotals(field.value, 'cost'), [field.value]);
    const totalProvision = useMemo(() => getTotals(field.value, 'provision'), [field.value]);

    return {
        totalCost,
        totalProvision,
    };
};
