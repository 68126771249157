import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useConfigContext } from '../../contexts/ConfigContext/ConfigContext';
import ForgotPasswordForm from './components/Form';

function ForgotPassword() {
    const { t } = useTranslation();
    const [, { setPageTitle }] = useConfigContext();

    useEffect(() => {
        setPageTitle(t('Forgot Password'));
    }, []);

    return (
        <div>
            <h2 className="text-lg font-semibold text-center lg:text-xl">{t('Recover your password')}</h2>
            <div className="mb-2 text-sm font-medium text-center">
                {t('Enter the email address associated with your account.')}
            </div>
            <ForgotPasswordForm />
        </div>
    );
}
export default ForgotPassword;
