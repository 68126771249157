import React, { useEffect, useRef } from 'react';
import { CSVLink } from 'react-csv';

function CSVDownload(props) {
    const btnRef = useRef(null);

    useEffect(() => btnRef.current?.click(), [btnRef]);

    return (
        <CSVLink {...props}>
            <span ref={btnRef} />
        </CSVLink>
    );
}

export default CSVDownload;
