import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import omit from 'lodash/omit';
import { HiChevronDown } from 'react-icons/hi';

import './styles.module.scss';

function InnerItem({ label, icon }) {
    const Icon = icon;
    return (
        <Fragment>
            {icon && (
                <span className="mr-4 text-xl">
                    <Icon />
                </span>
            )}
            {label && <span className="truncate">{label}</span>}
        </Fragment>
    );
}

InnerItem.propTypes = {
    icon: PropTypes.func,
    label: PropTypes.string,
};

InnerItem.defaultProps = {
    icon: null,
    label: null,
};

function Link({ to, ...restProps }) {
    return (
        <NavLink
            to={to}
            className="flex flex-wrap items-center h-12 px-4 text-white grow hover:text-white focus:text-white dark:text-white dark:hover:text-white dark:focus:text-white"
        >
            <InnerItem {...restProps} />
        </NavLink>
    );
}

Link.propTypes = {
    to: PropTypes.string,
};

Link.defaultProps = {
    to: null,
};

function ItemWithSubNav({ subNav, ...restProps }) {
    const [isOpen, setOpen] = useState(false);
    return (
        <span data-open={isOpen}>
            <span className="relative flex items-strech">
                <Link {...restProps} />
                <button
                    type="button"
                    className={`w-12 text-white/[.50] flex items-center justify-center text-lg hover:text-white 
                    ${isOpen ? 'rotate-180' : ''} transition-transform duration-1000 ease-in-out`}
                    onClick={() => setOpen((state) => !state)}
                >
                    <HiChevronDown />
                </button>
            </span>
            <span
                className={`${
                    isOpen ? 'h-fit shadow-inner ring-4 ring-black/5' : 'h-0'
                }  block overflow-hidden bg-black/[.10]  transition-[height] duration-1000 ease-in-out`}
            >
                <Nav items={subNav} />
            </span>
        </span>
    );
}

ItemWithSubNav.propTypes = {
    subNav: PropTypes.array,
};

ItemWithSubNav.defaultProps = {
    subNav: [],
};

function Nav({ items }) {
    return (
        <ul>
            {items
                .sort(({ order: orderA }, { order: orderB }) => orderB - orderA)
                .map(({ subNav, divider, ...restProps }, index) => (
                    <Fragment key={index}>
                        {divider ? (
                            <li className="h-0 border-t border-white/[.20]" />
                        ) : (
                            <li className="hover:bg-black/[.40]">
                                {subNav ? (
                                    <ItemWithSubNav subNav={subNav} {...omit(restProps, ['order'])} />
                                ) : (
                                    <Link {...omit(restProps, ['order'])} />
                                )}
                            </li>
                        )}
                    </Fragment>
                ))}
        </ul>
    );
}

Nav.propTypes = {
    items: PropTypes.array,
};

Nav.defaultProps = {
    items: [],
};

export default Nav;
