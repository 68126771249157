import { MANAGER_ROLE, ADMIN_ROLE } from '../contexts/AuthContext/useAuth';

import ActiveAccount from '../pages/ActiveAccount';
import Agents from '../pages/Agents';
import AgentForm from '../pages/AgentForm';
import Clients from '../pages/Clients';
import ClientForm from '../pages/ClientForm';
import Services from '../pages/Services';
import ServiceForm from '../pages/ServiceForm';
import Login from '../pages/Login';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import Profile from '../pages/Profile';
import RegisterForm from '../pages/RegisterForm';
import Reservations from '../pages/Reservations';
import ReservationForm from '../pages/ReservationForm';
import ReservationPreview from '../pages/ReservationPreview';
import Users from '../pages/Users';
import UserForm from '../pages/UserForm';
import Settings from '../pages/Settings';
import Dictionaries from '../pages/Dictionaries';
import DictionaryForm from '../pages/DictionaryForm';

const getPublicUrls = () => {
    const urls = {
        forgotpassword: {
            path: '/forgot-password',
            route: '/forgot-password',
            component: ForgotPassword,
        },
        resetpassword: {
            path: '/reset-password/:token',
            route: '/reset-password',
            component: ResetPassword,
        },
        activeAccount: {
            path: '/active-account/:token',
            route: '/active-account',
            component: ActiveAccount,
        },
        login: {
            path: '/',
            route: '/',
            component: Login,
        },
    };

    if (parseInt(process.env.REACT_APP_REGISTRATION_ENABLED, 10)) {
        urls.register = {
            path: '/register',
            route: '/register',
            component: RegisterForm,
        };
    }

    return urls;
};

export const publicUrls = getPublicUrls();

export const privateUrls = {
    agents: {
        path: '/admin/agents',
        route: '/admin/agents',
        component: Agents,
        permissions: [MANAGER_ROLE, ADMIN_ROLE],
    },
    agentForm: {
        path: '/admin/agent/:id',
        route: '/admin/agent',
        component: AgentForm,
        permissions: [MANAGER_ROLE, ADMIN_ROLE],
    },
    clients: {
        path: '/admin/clients',
        route: '/admin/clients',
        component: Clients,
    },
    clientForm: {
        path: '/admin/client/:id',
        route: '/admin/client',
        component: ClientForm,
    },
    dictionary: {
        path: '/admin/dictionaries/:group',
        route: '/admin/dictionaries',
        component: Dictionaries,
        permissions: [MANAGER_ROLE, ADMIN_ROLE],
    },
    dictionaryForm: {
        path: '/admin/dictionary/:id',
        route: '/admin/dictionary',
        component: DictionaryForm,
        permissions: [MANAGER_ROLE, ADMIN_ROLE],
    },

    profile: {
        path: '/admin/profile',
        route: '/admin/profile',
        component: Profile,
    },
    reservations: {
        path: '/admin/reservations',
        route: '/admin/reservations',
        component: Reservations,
    },
    reservationPreview: {
        path: '/admin/reservations/preview/:id',
        route: '/admin/reservations/preview',
        component: ReservationPreview,
    },
    reservationForm: {
        path: '/admin/reservations/:id',
        route: '/admin/reservations',
        component: ReservationForm,
    },
    users: {
        path: '/admin/users',
        route: '/admin/users',
        component: Users,
        permissions: [ADMIN_ROLE],
    },
    userForm: {
        path: '/admin/user/:id',
        route: '/admin/user',
        component: UserForm,
        permissions: [ADMIN_ROLE],
    },
    services: {
        path: '/admin/services',
        route: '/admin/services',
        component: Services,
        permissions: [MANAGER_ROLE, ADMIN_ROLE],
    },
    serviceForm: {
        path: '/admin/service/:id',
        route: '/admin/service',
        component: ServiceForm,
        permissions: [MANAGER_ROLE, ADMIN_ROLE],
    },
    settings: {
        path: '/admin/settings',
        route: '/admin/settings',
        component: Settings,
        permissions: [ADMIN_ROLE],
    },
};

export default {
    ...publicUrls,
    ...privateUrls,
};
