import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Form, Formik } from 'formik';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import urls from '../../../../setup/urls';
import FormItem from '../../../../components/FormItem';
import Grid from '../../../../components/Grid';
import RolesTooltip from '../../../../components/RolesTooltip';
import Button from '../../../../components/Button';
import LinkButton from '../../../../components/LinkButton';
import ButtonsWrapper from '../../../../components/ButtonsWrapper';
import { WORKER_ROLE, AUTH_ROLES } from '../../../../contexts/AuthContext/useAuth';

import { formSchema, useForm } from './useForm';

const initValues = {
    name: '',
    email: '',
    role: WORKER_ROLE,
    password: '',
};
function UserForm({ id, details }) {
    const { t } = useTranslation();
    const { loading, handleSubmit } = useForm({ id, details });

    return (
        <Formik
            initialValues={
                !isEmpty(details)
                    ? {
                          name: get(details, 'name', ''),
                          role: get(details, 'role', WORKER_ROLE),
                          email: get(details, 'email', ''),
                          password: '',
                      }
                    : initValues
            }
            validationSchema={formSchema}
            enableReinitialize
            onSubmit={handleSubmit}
        >
            {() => (
                <Form>
                    <Grid.Container fluid noPadding>
                        <Grid.Row>
                            <Grid.Col md={6}>
                                <FormItem name="name" label="Name" disabled={loading} validationSchema={formSchema} />
                            </Grid.Col>
                            <Grid.Col md={6}>
                                <div className="flex items-center justify-start gap-4 flex-nowrap">
                                    <FormItem
                                        name="role"
                                        label="Role"
                                        type="dropdown"
                                        options={AUTH_ROLES}
                                        disabled={loading}
                                        validationSchema={formSchema}
                                    />
                                    <RolesTooltip />
                                </div>
                            </Grid.Col>
                            <Grid.Col>
                                <FormItem
                                    name="email"
                                    label="Email"
                                    type="email"
                                    disabled={id !== null || loading}
                                    validationSchema={formSchema}
                                />
                            </Grid.Col>
                            <Grid.Col>
                                <FormItem
                                    name="password"
                                    label="Your Password"
                                    type="password"
                                    autocomplete="off"
                                    disabled={loading}
                                    validationSchema={formSchema}
                                    hint="For security reason you have to enter your current password to confirm that it's you"
                                />
                            </Grid.Col>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Col>
                                <ButtonsWrapper>
                                    <Button type="submit" color="primary" loading={loading}>
                                        {t('Save')}
                                    </Button>
                                    <LinkButton to={get(urls, 'users.route')} disabled={loading}>
                                        {t('Cancel')}
                                    </LinkButton>
                                </ButtonsWrapper>
                            </Grid.Col>
                        </Grid.Row>
                    </Grid.Container>
                </Form>
            )}
        </Formik>
    );
}

UserForm.propTypes = {
    id: PropTypes.string,
    details: PropTypes.object,
};

UserForm.defaultProps = {
    id: null,
    details: {},
};

export default UserForm;
