import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNotification } from '../../../../../../contexts/NotificationContext';
import { sendRequest } from '../../../../../../utils/request';
import { createClient } from '../../../../../../api';

export const useAddClientModal = ({ onClose, onSuccess, onFailure }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const [, { addError, addSuccess }] = useNotification();

    const handleSubmit = async (values, actions) => {
        setLoading(true);
        const api = createClient();

        const response = await sendRequest(api, {
            method: 'POST',
            data: values,
        });

        actions.setSubmitting(false);
        setLoading(false);
        if (response.status === 200) {
            addSuccess(t('Client was saved.'));
            onSuccess(response.data.data);
            onClose();
        } else {
            addError(t(response.data.error));
            onFailure();
        }
    };

    return {
        loading,
        handleSubmit,
    };
};
