import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Yup from '../../../../utils/yup';
import { useNotification } from '../../../../contexts/NotificationContext';
import { passwordMatch, passwordStrong } from '../../../../utils/validationMessages';
import { sendRequest } from '../../../../utils/request';
import { passwordRegex } from '../../../../utils/password';
import { authChangePassword } from '../../../../api';

export const formSchema = Yup.object().shape({
    password: Yup.string().required(),
    newPassword: Yup.string().min(8).max(255).matches(passwordRegex, passwordStrong()).required(),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword')], passwordMatch())
        .required(),
});

export const useForm = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const [, { addError, addSuccess }] = useNotification();

    const handleSubmit = async (values, actions) => {
        setLoading(true);
        const response = await sendRequest(authChangePassword(), {
            method: 'PUT',
            data: values,
        });

        actions.setSubmitting(false);
        setLoading(false);
        if (response.status === 200) {
            addSuccess(t('Profil updated'));
        } else {
            addError(t(response.data.error));
        }
    };

    return {
        loading,
        handleSubmit,
    };
};
