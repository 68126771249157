import { get, sortBy } from 'lodash';

import { useSettingsContext } from '../contexts/SettingsContext';
import { currencies, countries } from '../utils/dictionaries';

export const useSettings = () => {
    const [{ settings }] = useSettingsContext();

    const getItems = ({ code, defaultItems }) => {
        const savedItems = get(settings, `application.${code}`, []);

        if (!savedItems.length) {
            return defaultItems;
        }

        return sortBy(
            defaultItems.filter(({ value }) => savedItems.includes(value)),
            ['label'],
        );
    };
    const getCurrencies = () => getItems({ code: 'currency', defaultItems: currencies });

    const getCountries = () => getItems({ code: 'countries', defaultItems: countries });

    return [{}, { getCurrencies, getCountries }];
};
