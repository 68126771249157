import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { DARK_MODE_CLASS } from '../DarkModeSwitch/DarkModeSwitch';

const DEFAULT_LOGO = '/logo.svg';
const DEFAULT_LOGO_LIGHT = '/logo-light.svg';

function Logo({ src, alt }) {
    const [darkMode, toggleMode] = useState(localStorage.getItem('color-theme') === DARK_MODE_CLASS);

    useEffect(() => {
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.attributeName === 'class') {
                    toggleMode(mutation.target.className === DARK_MODE_CLASS);
                }
            });
        });

        observer.observe(document.getElementsByTagName('html')[0], { attributes: true });
    }, []);

    const getSrc = (src) => {
        if (src) {
            return src;
        }
        return darkMode ? DEFAULT_LOGO_LIGHT : DEFAULT_LOGO;
    };
    return (
        <Link className="block" to="/">
            <img
                className="block object-scale-down m-0 h-7 lg:h-8"
                src={getSrc(src)}
                alt={alt || process.env.REACT_APP_NAME}
            />
        </Link>
    );
}

Logo.propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string,
};

Logo.defaultProps = {
    src: '',
    alt: process.env.REACT_APP_WEBSITE_NAME,
};
export default Logo;
