import React from 'react';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { HiOutlineCheckCircle } from 'react-icons/hi';
import { get } from 'lodash';
import { Form, Formik } from 'formik';
import urls from '../../../../setup/urls';
import FormItem from '../../../../components/FormItem';
import Button from '../../../../components/Button';

import { formSchema, useForm } from './useForm';

function RegisterForm(props) {
    const { t } = useTranslation();
    const { recaptcha, loading, isCreated, handleSubmit } = useForm(props);

    if (isCreated) {
        return (
            <div className="flex flex-col items-center justify-center w-full h-full gap-2">
                <div className="text-green-600 text-8xl">
                    <HiOutlineCheckCircle />
                </div>
                <p>{t('Your account has created!')}</p>
                <p>
                    <Link to={get(urls, 'login.route')}>{t('Back to login page')}</Link>
                </p>
            </div>
        );
    }

    return (
        <React.Fragment>
            <div className="mb-2 text-sm font-medium text-center ">
                {t('Register and step in to Paxis Reservation System!')}
            </div>
            <Formik
                initialValues={{
                    email: '',
                    password: '',
                    repeatPassword: '',
                }}
                validationSchema={formSchema}
                onSubmit={handleSubmit}
            >
                {() => (
                    <Form className="ui form">
                        <FormItem
                            name="email"
                            label="Email"
                            type="email"
                            disabled={loading}
                            validationSchema={formSchema}
                        />
                        <FormItem
                            name="password"
                            label="Password"
                            type="password"
                            autocomplete="off"
                            disabled={loading}
                            validationSchema={formSchema}
                        />
                        <FormItem
                            name="repeatPassword"
                            label="Repeat Password"
                            type="password"
                            autocomplete="off"
                            disabled={loading}
                            validationSchema={formSchema}
                        />
                        <div className="flex justify-center">
                            <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} />
                        </div>
                        <div className="flex flex-row items-center justify-between mt-4">
                            <Button type="submit" color="primary" loading={loading} className="w-full">
                                {t('Step in to Paxis')}
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </React.Fragment>
    );
}
export default RegisterForm;
