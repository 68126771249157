import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HiOutlinePencil, HiPlus } from 'react-icons/hi';
import get from 'lodash/get';
import { useConfigContext } from '../../contexts/ConfigContext';
import { serviceApi } from '../../api';
import Grid from '../../components/Grid';
import Table from '../../components/Table';
import DeleteMessage from '../../components/Table/components/DeleteMessage';
import withStatusManager from '../../components/Table/components/withStatusManager';
import urls from '../../setup/urls';
import { useSettings } from '../../hooks/useSettings';

const TableComponent = withStatusManager(Table);

/* eslint-disable react/prop-types */
function NameCell({ value, data }) {
    return (
        <div className="rgt-cell-inner">
            <Link to={`${get(urls, 'serviceForm.route')}/${data.id}`}>{value}</Link>
        </div>
    );
}

function DeleteMessageComponent({ item }) {
    const { t } = useTranslation();
    const { name = '' } = item;

    return (
        <DeleteMessage
            message={t('Are you sure you want to delete this service?')}
            descriptionItems={[
                {
                    label: t('Name'),
                    value: name,
                },
            ]}
        />
    );
}
/* eslint-enable react/prop-types */

function Services() {
    const { t } = useTranslation();
    const [, { setPageTitle }] = useConfigContext();
    const [, { getCurrencies }] = useSettings();

    useEffect(() => {
        setPageTitle(t('Services'));
    }, []);

    const tableConfig = {
        id: 'services',
        columns: React.useMemo(
            () => [
                {
                    field: 'name',
                    label: t('Name'),
                    pinned: true,
                    width: 'auto',
                    type: 'string',
                    cellRenderer: NameCell,
                },
                {
                    field: 'rate',
                    label: t('Rate'),
                    type: 'string',
                },
                {
                    field: 'letter',
                    label: t('Letter'),
                    type: 'string',
                },
                {
                    field: 'netPrice',
                    label: t('Net Price'),
                    type: 'number',
                },
                {
                    field: 'currency',
                    label: t('Currency'),
                    type: 'dropdown',
                    options: getCurrencies(),
                },
            ],
            [],
        ),
        headerActions: [
            {
                label: 'Add Service',
                icon: HiPlus,
                to: `${get(urls, 'serviceForm.route')}/create`,
                useSeparately: true,
            },
        ],
        rowActions: [
            {
                label: 'Edit',
                icon: HiOutlinePencil,
                to: ({ data: { id } = {} }) => `${get(urls, 'serviceForm.route')}/${id}`,
            },
        ],
        initConfig: {
            sort: { field: 'name', direct: 'ASC' },
            filters: { isActive: { value: true, operator: 'eq' } },
        },
        contextApi: serviceApi,
        deleteMessage: DeleteMessageComponent,
        withExport: true,
    };

    return (
        <Grid.Container fluid>
            <Grid.Row>
                <Grid.Col>
                    <TableComponent {...tableConfig} />
                </Grid.Col>
            </Grid.Row>
        </Grid.Container>
    );
}

export default Services;
