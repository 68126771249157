import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import omit from 'lodash/omit';
import { Link } from 'react-router-dom';
import { Menu as ReactMenu } from '@headlessui/react';
import { Float } from '@headlessui-float/react';
import { HiChevronDown } from 'react-icons/hi';
import classNames from 'classnames';

function MenuItem({ label, to, icon, className, ...restProps }) {
    const Icon = icon;
    return (
        <ReactMenu.Item as="li">
            <Link {...restProps} to={to} className={classNames('menu-link', className)}>
                {icon && <Icon className="w-4 mr-2 text-xl shrink-0 " />}
                <span className="text-left">{label}</span>
            </Link>
        </ReactMenu.Item>
    );
}

MenuItem.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    to: PropTypes.string,
    icon: PropTypes.any,
    className: PropTypes.string,
};

MenuItem.defaultProps = {
    label: null,
    to: null,
    className: null,
    icon: null,
};

function MenuItemButton({ label, onClick, icon, className, disabled }) {
    const Icon = icon;
    return (
        <ReactMenu.Item as="li">
            <button type="button" onClick={onClick} className={classNames('menu-link', className)} disabled={disabled}>
                {icon && <Icon className="w-4 mr-2 text-xl shrink-0" />}
                <span className="text-left ">{label}</span>
            </button>
        </ReactMenu.Item>
    );
}

MenuItemButton.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClick: PropTypes.func,
    icon: PropTypes.any,
    className: PropTypes.string,
    disabled: PropTypes.bool,
};

MenuItemButton.defaultProps = {
    label: null,
    onClick: () => {},
    className: null,
    icon: null,
    disabled: false,
};

function MenuItemDivider() {
    return (
        <ReactMenu.Item as="li">
            <div className="border-b border-b-slate-100 dark:border-b-slate-700" />
        </ReactMenu.Item>
    );
}

const itemMap = {
    item: MenuItem,
    button: MenuItemButton,
    divider: MenuItemDivider,
};

function Menu({
    trigger,
    triggerTitle,
    menuHeader,
    position,
    hideChevron,
    isPortable,
    items,
    wrapperClassName,
    triggerClassName,
    itemsClassName,
}) {
    return (
        <ReactMenu as="div" className={classNames('menu-wrapper', wrapperClassName)}>
            <Float
                portal={isPortable}
                placement="bottom-start"
                offset={0}
                enter="transition  ease-out duration-200"
                enterFrom="scale-80 opacity-0"
                enterTo="scale-100 opacity-100"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                tailwindcssOriginClass
                adaptiveWidth
            >
                <div>
                    <ReactMenu.Button
                        className={classNames('menu-trigger-button', triggerClassName)}
                        title={triggerTitle}
                    >
                        {trigger && trigger()}
                        {!hideChevron && <HiChevronDown className="w-5 h-5 ml-2 -mr-1" aria-hidden="true" />}
                    </ReactMenu.Button>
                </div>
                <ReactMenu.Items
                    className={classNames(
                        'menu-items',
                        itemsClassName,
                        `min-w-[140px] ${position}-0 origin-top-${position} `,
                    )}
                >
                    <div className="px-1 py-1">
                        {menuHeader && menuHeader()}
                        <ul>
                            {items
                                .sort(({ order: orderA }, { order: orderB }) => orderA - orderB)
                                .map(({ type = 'item', ...restProps }, index) => {
                                    const Component = get(itemMap, type, MenuItem);
                                    return <Component key={index} {...omit(restProps, ['order'])} />;
                                })}
                        </ul>
                    </div>
                </ReactMenu.Items>
            </Float>
        </ReactMenu>
    );
}

Menu.propTypes = {
    trigger: PropTypes.func,
    triggerTitle: PropTypes.string,
    menuHeader: PropTypes.func,
    position: PropTypes.oneOf(['right', 'left']),
    items: PropTypes.array,
    hideChevron: PropTypes.bool,
    isPortable: PropTypes.bool,
    wrapperClassName: PropTypes.string,
    triggerClassName: PropTypes.string,
    itemsClassName: PropTypes.string,
};
Menu.defaultProps = {
    trigger: null,
    triggerTitle: '',
    menuHeader: null,
    position: 'left',
    items: [],
    hideChevron: false,
    isPortable: true,
    wrapperClassName: '',
    triggerClassName: '',
    itemsClassName: '',
};

export default Menu;
