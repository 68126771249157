import { useReducer } from 'react';
import get from 'lodash/get';

const initialState = {
    page: 1,
    pageSize: 25,
    hiddenColumns: [],
    rows: [],
    totalSize: 0,
    sort: {},
    isLoading: true,
    filters: {},
};

const INIT = 'init';
const ON_LOADING_STARTS = 'onLoadingStarts';
const ON_LOADING_ENDS = 'onLoadingEnds';
const ON_PAGE_CHANGE = 'onPageChange';
const ON_PAGE_SIZE_CHANGE = 'onPageSizeChange';
const ON_SORT_CHANGE = 'onSortChange';
const ON_COLUMNS_CHANGE = 'onColumnsChange';
const ON_ROWS_CHANGE = 'onRowsChange';
const ON_FILTERS_CHANGE = 'onFiltersChange';

function reducer(state, action) {
    switch (action.type) {
        case INIT:
            return { ...state, ...action.payload, isLoading: false };
        case ON_LOADING_STARTS:
            return { ...state, isLoading: true };
        case ON_LOADING_ENDS:
            return { ...state, isLoading: false };
        case ON_PAGE_CHANGE:
            return { ...state, page: action.payload };
        case ON_PAGE_SIZE_CHANGE:
            return { ...state, pageSize: action.payload };
        case ON_SORT_CHANGE:
            return { ...state, sort: action.payload };
        case ON_COLUMNS_CHANGE:
            return { ...state, hiddenColumns: get(action.payload, 'hiddenColumns', []) };
        case ON_FILTERS_CHANGE:
            return { ...state, filters: action.payload };
        case ON_ROWS_CHANGE:
            return {
                ...state,
                rows: get(action.payload, 'rows', []),
                totalSize: get(action.payload, 'totalSize', 0),
                isLoading: false,
            };
        default:
            throw new Error();
    }
}

function useTableReducer() {
    const [state, dispatch] = useReducer(reducer, initialState);
    const init = (payload) => {
        dispatch({
            payload,
            type: INIT,
        });
    };

    const onLoadingStarts = (payload) => {
        dispatch({
            payload,
            type: ON_LOADING_STARTS,
        });
    };

    const onLoadingEnds = (payload) => {
        dispatch({
            payload,
            type: ON_LOADING_ENDS,
        });
    };

    const onPageChange = (payload) => {
        dispatch({
            payload,
            type: ON_PAGE_CHANGE,
        });
    };

    const onPageSizeChange = (payload) => {
        dispatch({
            payload,
            type: ON_PAGE_SIZE_CHANGE,
        });
    };

    const onSortChange = (payload) => {
        dispatch({
            payload,
            type: ON_SORT_CHANGE,
        });
    };

    const onColumnsChange = (payload) => {
        dispatch({
            payload,
            type: ON_COLUMNS_CHANGE,
        });
    };

    const onFiltersChange = (payload) => {
        dispatch({
            payload,
            type: ON_FILTERS_CHANGE,
        });
    };

    const onRowsChange = (payload = []) => {
        dispatch({
            payload,
            type: ON_ROWS_CHANGE,
        });
    };
    const api = {
        dispatch,
        init,
        onLoadingStarts,
        onLoadingEnds,
        onPageChange,
        onPageSizeChange,
        onSortChange,
        onColumnsChange,
        onFiltersChange,
        onRowsChange,
    };

    return [state, api];
}

export default useTableReducer;
