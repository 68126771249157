import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import { DateRangePicker } from 'react-dates';
import Label from '../../../../../FormItem/components/Label';
import { DEFAULT_DATE_RANGE_PICKER_PROPS } from '../../../../../FormItem/components/FormDateRange/FormDateRange';
import { getBEDateFormat, getMomentObject } from '../../../../../../utils/date';

function DateRangeFilterField({ column, filters = {}, onFilterChange = () => {} }) {
    const { label, field } = column;
    const [value, setValue] = useState(['', '']);
    const [focused, setFocused] = useState(null);

    useEffect(() => {
        const filterValues = get(filters[field], 'value', ['', '']);
        setValue([
            filterValues[0] ? getMomentObject(filterValues[0]) : '',
            filterValues[1] ? getMomentObject(filterValues[1]) : '',
        ]);
    }, [filters[field]]);

    function handleDebounceFn(value) {
        onFilterChange(field, { value, operator: 'between' });
    }
    const debounceFn = useCallback(debounce(handleDebounceFn, 500), []);

    const handleChangeTo = ({ startDate, endDate }) => {
        setValue([startDate, endDate]);
        debounceFn([
            startDate ? startDate.format(getBEDateFormat()) : '',
            endDate ? endDate.format(getBEDateFormat()) : '',
        ]);
    };

    const mergedDatePickerProps = {
        ...DEFAULT_DATE_RANGE_PICKER_PROPS,
        showClearDates: true,
        minimumNights: 1,
        startDateId: `${field}-startDate`,
        endDateId: `${field}-endDate`,
        startDate: value[0] ? value[0] : null,
        endDate: value[1] ? value[1] : null,
        focusedInput: focused,
        onDatesChange: handleChangeTo,
        onFocusChange: (focused) => setFocused(focused),
    };

    return (
        <div>
            <Label label={label} />
            <div>
                <DateRangePicker {...mergedDatePickerProps} />
            </div>
        </div>
    );
}

DateRangeFilterField.propTypes = {
    column: PropTypes.object,
    filters: PropTypes.object,
    onFilterChange: PropTypes.func,
};

DateRangeFilterField.defaultProps = {
    column: {},
    filters: {},
    onFilterChange: () => {},
};

export default DateRangeFilterField;
