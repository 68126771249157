import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'formik';
import { HiOutlineTrash } from 'react-icons/hi';
import Button from '../../../../../../components/Button';
import Modal from '../../../../../../components/Modal';
import FormItem from '../../../../../../components/FormItem';
import { useTravellers } from './useTravellers';
import ClientSearchList from '../ClientSearchList';

function Travellers({ disabled, formSchema }) {
    const { t } = useTranslation();
    const [{ modalOpen, field }, { handleOpen, handleClose, handleAddClient, handleSelection }] = useTravellers();
    return (
        <FieldArray
            name="clients"
            render={(arrayHelpers) => (
                <Fragment>
                    <div className="divide-y divide-gray-200 ">
                        {!!field.value && !!field.value.length && (
                            <div className="overflow-x-auto">
                                <div className="flex flex-row justify-start gap-4 flex-nowrap items-top">
                                    <div className="flex flex-col items-start justify-start pt-2 shrink-0 w-80">
                                        <span className="mb-1 text-xs font-semibold text-gray-700 uppercase dark:text-slate-300">
                                            {t('Last name')}
                                        </span>
                                    </div>
                                    <div className="flex flex-col items-start justify-start pt-2 shrink-0 w-80">
                                        <span className="mb-1 text-xs font-semibold text-gray-700 uppercase dark:text-slate-300">
                                            {t('First name')}
                                        </span>
                                    </div>
                                    <div className="flex flex-col items-start justify-start pt-2 shrink-0 w-80">
                                        <span className="mb-1 text-xs font-semibold text-gray-700 uppercase dark:text-slate-300">
                                            {t('Birthdate')}
                                        </span>
                                    </div>
                                    <div className="flex flex-none w-16" />
                                </div>
                                <div className="divide-y divide-gray-200 ">
                                    {field.value.map((client, index) => (
                                        <div
                                            key={index}
                                            className="flex flex-row justify-start gap-4 flex-nowrap items-top"
                                        >
                                            <div className="flex flex-none py-4 w-80">
                                                <FormItem
                                                    name={`clients.${index}.lastName`}
                                                    disabled={disabled}
                                                    validationSchema={formSchema}
                                                    fieldClassName="mb-0"
                                                />
                                            </div>
                                            <div className="flex flex-none py-4 w-80">
                                                <FormItem
                                                    name={`clients.${index}.firstName`}
                                                    disabled={disabled}
                                                    validationSchema={formSchema}
                                                    fieldClassName="mb-0"
                                                />
                                            </div>
                                            <div className="flex flex-none w-48 py-4">
                                                <FormItem
                                                    name={`clients.${index}.birthDate`}
                                                    disabled={disabled}
                                                    validationSchema={formSchema}
                                                    type="date"
                                                    fieldClassName="mb-0"
                                                />
                                            </div>
                                            <div className="flex items-start justify-center flex-none w-16 py-4 ">
                                                <Button
                                                    outline
                                                    color="danger"
                                                    iconOnly
                                                    onClick={() => arrayHelpers.remove(index)}
                                                    title={t('Remove traveller')}
                                                >
                                                    <HiOutlineTrash className="text-xl" />
                                                </Button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                    <div>
                        <div className="flex flex-col gap-3 sm:flex-row">
                            <Button onClick={() => handleAddClient(arrayHelpers)}>{t('Add Traveller')}</Button>
                            <Button onClick={handleOpen}>{t('Find Travellers and Add')}</Button>
                        </div>
                    </div>
                    <Modal title="Select Travellers" className="max-w-screen-md" open={modalOpen} onClose={handleClose}>
                        <ClientSearchList onSelection={(clients) => handleSelection(clients, arrayHelpers)} multi />
                    </Modal>
                </Fragment>
            )}
        />
    );
}

Travellers.propTypes = {
    disabled: PropTypes.bool,
    formSchema: PropTypes.object,
};

Travellers.defaultProps = {
    disabled: false,
    formSchema: {},
};

export default Travellers;
