import React from 'react';
import { useTranslation } from 'react-i18next';
import { get, find } from 'lodash';
import { HiSwitchHorizontal } from 'react-icons/hi';
import { useNotification } from '../../../contexts/NotificationContext';
import Badge from '../../Badge';

import { sendRequest } from '../../../utils/request';

const ACTIVATE_LABEL = 'Activate';
const DEACTIVATE_LABEL = 'Deactivate';
const ACTIVE_LABEL = 'Active';
const INACTIVE_LABEL = 'Inactive';
const STATUS_FIELD_CODE = 'isActive';

function StatusCell({ data: { isActive = false } = {} }) {
    const { t } = useTranslation();
    return <Badge color={isActive ? 'success' : 'danger'}>{t(isActive ? ACTIVE_LABEL : INACTIVE_LABEL)}</Badge>;
}

const controller = new AbortController();

function withStatusManager(
    FieldComponent,
    {
        getCustomizedCol = (data) => data,
        getCustomizedActivateAction = (data) => data,
        getCustomizedDeactivateAction = (data) => data,
    } = {},
) {
    function WrappedField(props) {
        const { columns, rowActions, contextApi } = props;
        const { t } = useTranslation();
        const [, { addError, addSuccess }] = useNotification();

        const handleSetStatus = async (props) => {
            const { tableManager: { asyncApi = {} } = {}, data: { id, isActive } = {} } = props;

            try {
                const response = await sendRequest(contextApi.setStatus({ id }), {
                    method: 'PUT',
                    data: {
                        isActive: !isActive,
                    },
                    signal: controller.signal,
                });

                if (response.status !== 200) {
                    addError(t(response.data.error));
                }

                addSuccess(t('Status was changed.'));
                asyncApi.resetRows();
            } catch (e) {
                addError(t(e));
            }
        };

        if (!find(columns, ['field', STATUS_FIELD_CODE])) {
            columns.push(
                getCustomizedCol({
                    field: STATUS_FIELD_CODE,
                    label: t('Status'),
                    type: 'dropdown',
                    options: [
                        { value: false, label: INACTIVE_LABEL },
                        { value: true, label: ACTIVE_LABEL },
                    ],
                    cellRenderer: StatusCell,
                }),
            );
        }

        if (!find(rowActions, ['label', ACTIVATE_LABEL])) {
            rowActions.push(
                getCustomizedActivateAction({
                    label: ACTIVATE_LABEL,
                    icon: HiSwitchHorizontal,
                    type: 'button',
                    onClick: (props) => handleSetStatus(props),
                    isHidden: ({ data }) => get(data, STATUS_FIELD_CODE, false),
                }),
            );
        }
        if (!find(rowActions, ['label', DEACTIVATE_LABEL])) {
            rowActions.push(
                getCustomizedDeactivateAction({
                    label: DEACTIVATE_LABEL,
                    icon: HiSwitchHorizontal,
                    type: 'button',
                    onClick: (props) => handleSetStatus(props),
                    isHidden: ({ data }) => !get(data, STATUS_FIELD_CODE, false),
                    className: 'hover:text-red-600',
                }),
            );
        }

        return <FieldComponent {...props} />;
    }

    return WrappedField;
}

export default withStatusManager;
