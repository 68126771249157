import React from 'react';
import PropTypes from 'prop-types';

function Header({ as, dividing, children }) {
    const Tag = as;

    return (
        <Tag
            className={`mb-2 text-md text-gray-800 dark:text-gray-300 font-semibold ${
                dividing ? 'border-b pb-2 border-gray-400' : ''
            }`}
        >
            {children}
        </Tag>
    );
}

Header.propTypes = {
    as: PropTypes.string,
    dividing: PropTypes.bool,
    children: PropTypes.any,
};

Header.defaultProps = {
    as: 'h1',
    dividing: false,
    children: null,
};
export default Header;
