import React, { useEffect, useState, useRef, Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import { get, find, isArray } from 'lodash';
import { Combobox } from '@headlessui/react';
import { Float } from '@headlessui-float/react';
import { HiX, HiChevronDown, HiCheck } from 'react-icons/hi';
import Badge from '../../../Badge';

function FormDropdown({
    options,
    inputWrapperClassName,
    inputWrapperChildren,
    preselectSingleOption,
    isInvalid,
    value,
    isPortable,
    isDeletable,
    isMultiple,
    onChange,
    ...props
}) {
    const { name } = props;
    const { t } = useTranslation();
    const comboBtn = useRef(null);
    const [field, meta, helpers] = useField(name);
    const [query, setQuery] = useState('');

    useEffect(() => {
        if (meta.value) {
            onChange(meta.value);
        }
    }, [meta.value]);

    const handleDropdownChange = (value) => {
        helpers.setError(undefined);
        helpers.setValue(value, false);
        helpers.setTouched(true, false);
    };

    // useEffect(() => {
    //     if (options.length === 1 && preselectSingleOption && options[0].value !== meta.value && !meta.value) {
    //         handleDropdownChange(options[0].value);
    //     }
    // }, [options]);

    const handleRemoveMultiOption = (item) => {
        helpers.setValue(
            field.value.filter((value) => value !== item),
            false,
        );
    };

    const dropdownOptions = useMemo(
        () =>
            options
                .map((option) => {
                    const { label } = option;
                    return {
                        ...option,
                        label: t(label),
                    };
                })
                .sort(({ label: labelA }, { label: labelB }) => {
                    if (labelA < labelB) {
                        return -1;
                    }
                    if (labelA > labelB) {
                        return 1;
                    }
                    return 0;
                }),
        [options],
    );

    const getLabel = (code, defaultReturn) => {
        const label = get(find(dropdownOptions, ['value', code]), 'label');

        return label || defaultReturn;
    };

    const filteredOptions =
        query === ''
            ? dropdownOptions
            : dropdownOptions.filter(({ label }) => label.toLowerCase().includes(query.toLowerCase()));

    return (
        <div className={inputWrapperClassName}>
            <Combobox value={meta.value} onChange={handleDropdownChange} multiple={isMultiple}>
                <div className="relative">
                    <Float
                        portal={isPortable}
                        placement="bottom-start"
                        offset={0}
                        enter="transition duration-200 ease-out"
                        enterFrom="scale-95 opacity-0"
                        enterTo="scale-100 opacity-100"
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        tailwindcssOriginClass
                        adaptiveWidth
                    >
                        {/* <div className="relative overflow-hidden text-left cursor-default"> */}
                        <div className="relative text-left cursor-default">
                            {isArray(meta.value) && meta.value.length > 0 && (
                                <div className="flex flex-row flex-wrap gap-1 py-1">
                                    {meta.value.map((item) => (
                                        <Badge key={item}>
                                            <span>
                                                <span>{getLabel(item, item)}</span>
                                                <button
                                                    type="button"
                                                    onClick={() => handleRemoveMultiOption(item)}
                                                    className="translate-y-[2px] ml-0.5 opacity-80 hover:opacity-100"
                                                >
                                                    <HiX />
                                                </button>
                                            </span>
                                        </Badge>
                                    ))}
                                </div>
                            )}
                            <div>
                                <Combobox.Input
                                    className={`${meta.value ? 'pr-16' : 'pr-8'} ${
                                        isInvalid ? '!border-red-600 focus:!ring-red-600/30' : ''
                                    }`}
                                    onChange={(event) => {
                                        setQuery(event.target.value);
                                    }}
                                    displayValue={(value) => getLabel(value, '')}
                                    onFocus={() => {
                                        setQuery('');
                                        comboBtn.current?.click();
                                    }}
                                    placeholder={t('Select...')}
                                />
                                {!!meta.value && isDeletable && !isMultiple && (
                                    <button
                                        type="button"
                                        className="absolute btn-icon bottom-px right-6 text-slate-300 hover:text-slate-500 hover:bg-transparent dark:hover:bg-transparent"
                                        onClick={() => handleDropdownChange(isMultiple ? [] : '')}
                                    >
                                        <HiX />
                                    </button>
                                )}
                                <Combobox.Button
                                    className="absolute right-0 text-lg pointer-events-none btn-icon bottom-px"
                                    ref={comboBtn}
                                >
                                    <HiChevronDown />
                                </Combobox.Button>
                            </div>
                        </div>

                        <Combobox.Options className="w-full mt-0 overflow-y-auto max-h-72 menu-items">
                            {filteredOptions.length === 0 && query !== '' ? (
                                <div className="relative px-4 py-2 text-gray-700 cursor-default select-none">
                                    {t('Nothing found')}
                                </div>
                            ) : (
                                filteredOptions.map(({ value, label, isActive = true }) => (
                                    <Combobox.Option
                                        className={({ selected }) =>
                                            `menu-link relative cursor-pointer ${
                                                selected && 'bg-slate-200 dark:bg-slate-500'
                                            }`
                                        }
                                        key={value}
                                        value={value}
                                    >
                                        {({ selected }) => (
                                            <Fragment>
                                                <span
                                                    className={`block truncate ${
                                                        selected ? 'font-semibold' : 'font-normal'
                                                    }`}
                                                >
                                                    {label}
                                                </span>
                                                {selected && <HiCheck className="absolute inset-y-0 my-auto right-3" />}
                                                {!isActive && (
                                                    <Badge color="danger" className="ml-2">
                                                        {t('Inactive')}
                                                    </Badge>
                                                )}
                                            </Fragment>
                                        )}
                                    </Combobox.Option>
                                ))
                            )}
                        </Combobox.Options>
                    </Float>
                </div>
            </Combobox>
            {inputWrapperChildren}
        </div>
    );
}

FormDropdown.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.array,
    inputWrapperClassName: PropTypes.string,
    inputWrapperChildren: PropTypes.any,
    isInvalid: PropTypes.bool,
    preselectSingleOption: PropTypes.bool,
    isPortable: PropTypes.bool,
    isDeletable: PropTypes.bool,
    isMultiple: PropTypes.bool,
    value: PropTypes.string,
    onChange: PropTypes.func,
};

FormDropdown.defaultProps = {
    options: [],
    inputWrapperClassName: '',
    inputWrapperChildren: null,
    isInvalid: false,
    preselectSingleOption: true,
    isPortable: true,
    isDeletable: true,
    isMultiple: false,
    value: '',
    onChange: () => {},
};

export default FormDropdown;
