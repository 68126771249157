import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import get from 'lodash/get';

import urls from '../../setup/urls';
import { useNotification } from '../../contexts/NotificationContext';
import { useConfigContext } from '../../contexts/ConfigContext';
import Grid from '../../components/Grid';
import Section from '../../components/Section';
import LinkButton from '../../components/LinkButton';
import withNoDetailsWrapper from '../../hocs/NoDetailsWrapper/withNoDetailsWrapper';

import Form from './components/Form';

import { sendRequest } from '../../utils/request';
import { getReservationItem } from '../../api';

function ReservationForm({ hasNoId }) {
    const { t } = useTranslation();
    const [, { addError }] = useNotification();
    const { id } = useParams();
    const [, { setPageTitle }] = useConfigContext();
    const [loading, setLoading] = useState(false);
    const [details, setDetails] = useState({});

    useEffect(() => {
        const fetchDetails = async (id) => {
            setLoading(true);
            try {
                const response = await sendRequest(getReservationItem({ id }), {
                    method: 'GET',
                });

                if (response.status !== 200) {
                    if (response.status === 404) {
                        hasNoId();
                    } else {
                        addError(t(response.data.error));
                    }
                }

                setDetails(response.data.data);
                setLoading(false);
            } catch (e) {
                addError(t(e));
                setLoading(false);
            }
        };

        if (id && id !== 'create') {
            fetchDetails(id);
        }
    }, [id]);

    useEffect(() => {
        setPageTitle(`${t('Reservations')} / ${get(details, 'reservationNo', t('New'))}`);
    }, [details]);

    return (
        <Grid.Container fluid>
            <Grid.Row>
                <Grid.Col>
                    {details.status === 5 ? (
                        <div className="max-w-lg p-4 bg-white border m-auto rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700">
                            <div className="mb-4">
                                <p className="text-center">
                                    {t("This reservation is blocked. You can't make an edition of that reservation.")}
                                </p>
                            </div>
                            <LinkButton to={get(urls, 'reservations.route')} disabled={loading}>
                                {t('Back')}
                            </LinkButton>
                        </div>
                    ) : (
                        <Section loading={loading}>
                            <Form details={details} id={id !== 'create' ? id : null} />
                        </Section>
                    )}
                </Grid.Col>
            </Grid.Row>
        </Grid.Container>
    );
}

export default withNoDetailsWrapper(ReservationForm);
