const csvExample = [
    [
        'firstName',
        'lastName',
        'birthDate',
        'email',
        'email2',
        'phone',
        'phone2',
        'postcode',
        'street',
        'city',
        'country',
        'nationality',
        'passportCode',
        'passportExpDate',
    ],
    [
        'Dalton',
        'Salas',
        '1979-12-13',
        'aliquet@protonmail.edu',
        'eu.odio.phasellus@icloud.net',
        '592-872-716',
        '672-872-636',
        '29-371',
        'Ap #681-2113',
        'Non St.',
        'USA',
        'ante.',
        'GDS55MOG6DW',
        '2026-04-04',
    ],
    [
        'Phoebe',
        'Riggs',
        '2015-07-20',
        'dictum.phasellus@yahoo.couk',
        'turpis.nulla@icloud.edu',
        '726-256-238',
        '277-546-124',
        '16-511',
        'Ap #110-7955',
        'Sed Ave',
        'PL',
        'Nulla',
        'LKX71YYY9TA',
        '2032-10-15',
    ],
    [
        'Xander',
        'Golden',
        '1978-02-27',
        'dui.fusce@icloud.couk',
        'mollis@hotmail.edu',
        '699-271-373',
        '812-337-497',
        '65-133',
        'P.O. Box 675, 5501 Lectus Street',
        'Sed Ave',
        'PL',
        'hendrerit',
        'VLR89FLF7CK',
        '2027-09-07',
    ],
];

export default csvExample;
