import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import useSettings from './useSettings';

const SettingsContext = createContext();

function SettingsContextProvider({ children }) {
    const contextValue = useSettings();
    return <SettingsContext.Provider value={contextValue}>{children}</SettingsContext.Provider>;
}

SettingsContextProvider.propTypes = {
    children: PropTypes.any,
};

SettingsContextProvider.defaultProps = {
    children: null,
};

export default SettingsContextProvider;
export const useSettingsContext = () => useContext(SettingsContext);
