import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import has from 'lodash/has';
import set from 'lodash/set';
import omit from 'lodash/omit';
import isFunction from 'lodash/isFunction';
import cloneDeep from 'lodash/cloneDeep';
import { useTranslation } from 'react-i18next';
import {
    HiOutlineUpload,
    HiOutlineDownload,
    HiDotsVertical,
    HiOutlineEye,
    HiOutlineEyeOff,
    HiOutlineRefresh,
    HiOutlineFilter,
    HiRefresh,
} from 'react-icons/hi';
import Menu from '../../../Menu';
import Popover from '../../../Popover';
import FilterForm from '../FilterForm';
import FilterBar from '../FilterBar';
import ImportModal from '../ImportModal';
import SearchForm from '../SearchForm';
import './styles.module.css';

function Header({ tableManager }) {
    const {
        config: {
            additionalProps: { header: additionalProps = {} },
        },
        columnsVisibilityApi,
        columnsApi,
        asyncApi,
    } = tableManager;
    const { resetRows } = asyncApi;
    const { toggleColumnVisibility } = columnsVisibilityApi;
    const { columns } = columnsApi;
    const {
        state,
        withSearch = false,
        searchMessage = '',
        filters = {},
        importConfig = {},
        onFilterChange = () => {},
        onImportRequest = () => {},
        onExportRequest = () => {},
    } = additionalProps;

    const { isLoading } = state;
    const { t } = useTranslation();
    const [importModalDisplay, toggleImportModal] = useState(false);

    const handleToggleVisibility = (value) => {
        toggleColumnVisibility(value);
    };

    const handleToggleImportModal = () => {
        toggleImportModal((state) => !state);
    };

    const columnsVisibilityOptions = columns
        .filter(({ label }) => label)
        .map(({ label, id, visible }) => ({
            label,
            onClick: () => handleToggleVisibility(id),
            visible,
        }));

    const visibleColumns = columns.filter(({ label, visible }) => label && visible);

    const getMenuItems = () => {
        const clonedActions = cloneDeep([...get(additionalProps, 'actions', [])]);
        const items = clonedActions.map((item) => {
            if (has(item, 'to') && isFunction(item.to)) {
                set(item, 'to', item.to(tableManager));
            }

            if (has(item, 'onClick') && isFunction(item.onClick)) {
                const itemOnClick = get(item, 'onClick');
                set(item, 'onClick', () => itemOnClick(tableManager));
            }

            if (has(item, 'disabled') && isFunction(item.disabled)) {
                const disabled = get(item, 'disabled');
                set(item, 'disabled', disabled(tableManager));
            }
            set(item, 'label', t(item.label));
            return item;
        });
        if (get(additionalProps, 'withImport', false)) {
            items.push({
                icon: HiOutlineUpload,
                label: t('Import'),
                onClick: handleToggleImportModal,
                type: 'button',
                order: 49,
            });
        }

        if (get(additionalProps, 'withExport', false)) {
            items.push({
                type: 'divider',
                order: 50,
            });
            items.push({
                icon: HiOutlineDownload,
                label: t('Export All'),
                onClick: () => onExportRequest({ isCurrentPage: false }),
                type: 'button',
                order: 51,
            });
            items.push({
                icon: HiOutlineDownload,
                label: t('Export Current Page'),
                onClick: () => onExportRequest({ isCurrentPage: true }),
                type: 'button',
                order: 52,
            });
        }

        return items.sort(({ order: orderA }, { order: orderB }) => {
            if (orderA < orderB) return -1;
            if (orderA > orderB) return 1;
            return 0;
        });
    };

    const getSeparetedHeaderItems = () => {
        const clonedActions = cloneDeep([...get(additionalProps, 'actions', [])]);
        const items = clonedActions
            .filter(({ useSeparately = false }) => useSeparately)
            .map((item) => {
                if (has(item, 'to') && isFunction(item.to)) {
                    set(item, 'to', item.to(tableManager));
                }

                if (has(item, 'onClick') && isFunction(item.onClick)) {
                    const itemOnClick = get(item, 'onClick');
                    set(item, 'onClick', () => itemOnClick(tableManager));
                }

                if (has(item, 'disabled') && isFunction(item.disabled)) {
                    const disabled = get(item, 'disabled');
                    set(item, 'disabled', disabled(tableManager));
                }

                return item;
            });

        return items.sort(({ order: orderA }, { order: orderB }) => {
            if (orderA < orderB) return -1;
            if (orderA > orderB) return 1;
            return 0;
        });
    };

    const classNames =
        `table-header flex items-stretch flex-col w-full rounded-t-md bg-blue-700 dark:bg-blue-900 shadow-md border-b-2 border-b-blue-200 ${
            additionalProps.className || ''
        }`.trim();

    return (
        <div className={classNames}>
            <div className="flex flex-row justify-between flex-auto p-1 flex-nowrap">
                <div className="flex justify-center flex-nowrap">
                    <Menu
                        triggerClassName="btn-icon"
                        trigger={() => (
                            <Fragment>
                                <HiDotsVertical />
                                <span className="sr-only">{t('Menu')}</span>
                            </Fragment>
                        )}
                        triggerTitle={t('Menu')}
                        hideChevron
                        items={getMenuItems()}
                    />
                    {getSeparetedHeaderItems().map(({ label, icon, onClick, to, ...restProps }, index) => {
                        const Icon = icon;
                        const Component = to ? Link : 'button';

                        const props = to
                            ? {
                                  to,
                              }
                            : {
                                  type: 'button',
                                  onClick,
                                  title: t(label),
                              };

                        return (
                            <Component
                                key={index}
                                className="btn-icon"
                                title={t(label)}
                                {...props}
                                {...omit(restProps, ['useSeparately'])}
                            >
                                {icon && <Icon />}
                                <span className="sr-only">{t(label)}</span>
                            </Component>
                        );
                    })}
                    {isLoading && (
                        <div className="inset-0 flex items-center justify-center gap-1 ml-4 text-sm font-semibold text-white">
                            <span className="animate-spin">
                                <HiRefresh className="scale-y-[-1]" />
                            </span>{' '}
                            {t('Loading')}...
                        </div>
                    )}
                </div>
                <div className="flex justify-center flex-nowrap">
                    {withSearch && (
                        <div className="relative flex items-center justify-center px-2">
                            <SearchForm
                                filters={filters}
                                onFilterChange={onFilterChange}
                                resetRows={resetRows}
                                searchMessage={searchMessage}
                            />
                        </div>
                    )}
                    <button
                        type="button"
                        className="btn-icon"
                        onClick={() => asyncApi.resetRows()}
                        title={t('Refresh')}
                    >
                        <HiOutlineRefresh />
                        <span className="sr-only">{t('Refresh Table')}</span>
                    </button>
                    <Popover
                        triggerClassName="btn-icon"
                        itemsClassName="w-[80vw] max-w-3xl"
                        trigger={() => (
                            <Fragment>
                                <HiOutlineFilter />
                                <span className="sr-only">{t('Filters')}</span>
                            </Fragment>
                        )}
                        triggerTitle={t('Filters')}
                        hideChevron
                        position="right"
                    >
                        <div className="px-1 py-1">
                            <FilterForm
                                columns={visibleColumns}
                                filters={filters}
                                onFilterChange={onFilterChange}
                                resetRows={resetRows}
                            />
                        </div>
                    </Popover>
                    <Popover
                        triggerClassName="btn-icon"
                        trigger={() => (
                            <Fragment>
                                <HiOutlineEye />
                                <span className="sr-only">{t('Column Visiblity')}</span>
                            </Fragment>
                        )}
                        triggerTitle={t('Column Visiblity')}
                        hideChevron
                        position="right"
                    >
                        <div className="px-1 py-1">
                            <ul>
                                {columnsVisibilityOptions.map(({ label, onClick, visible }) => {
                                    const Icon = visible ? HiOutlineEye : HiOutlineEyeOff;
                                    return (
                                        <li key={label}>
                                            <button
                                                type="button"
                                                onClick={onClick}
                                                className={`flex w-full justify-between items-center py-2 px-3 text-sm text-slade-600 hover:text-sky-600 hover:bg-gray-100 dark:text-slate-300 dark:hover:bg-gray-600 dark:hover:text-white ${
                                                    !visible ? 'opacity-50' : ''
                                                }`}
                                            >
                                                <span>{label}</span>
                                                <Icon className="ml-2 text-xl" />
                                            </button>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </Popover>
                </div>
            </div>
            <FilterBar
                columns={visibleColumns}
                filters={filters}
                onFilterChange={onFilterChange}
                resetRows={resetRows}
            />
            <ImportModal
                config={importConfig}
                isOpen={importModalDisplay}
                onClose={handleToggleImportModal}
                onSubmit={(payload) =>
                    onImportRequest({ ...payload, tableManager, onSuccess: handleToggleImportModal })
                }
            />
        </div>
    );
}

Header.propTypes = {
    tableManager: PropTypes.object.isRequired,
};

Header.defaultProps = {};

export default Header;
