export const omitProps = [
    'isInvalid',
    'label',
    'hint',
    'datePickerProps',
    'dateRangePickerProps',
    'preselectSingleOption',
    'options',
    'isPortable',
    'isDeletable',
    'isMultiple',
    'icon',
    'iconPosition',
    'shouldBeTouched',
    'fieldClassName',
    'validationSchema',
];

export const erroClassSet = '!border-red-600 focus:!ring-red-600/30';
