import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { forEach, keys } from 'lodash';
import Yup from '../../../../utils/yup';
import { useSettingsContext } from '../../../../contexts/SettingsContext';
import { useNotification } from '../../../../contexts/NotificationContext';
import { nip } from '../../../../utils/validationMessages';
import { sendRequest } from '../../../../utils/request';
import { updateSetting } from '../../../../api';

export const formSchema = Yup.object().shape({
    name: Yup.string().required().max(255),
    address: Yup.string().required().max(255),
    bankName: Yup.string().required().max(255),
    accountNumber: Yup.string().required().max(255),
    nip: Yup.string()
        .required()
        .matches(/^[0-9]{10}$/, nip()),
    regon: Yup.string().max(9).max(14),
    email: Yup.string().required().email().max(255),
    email2: Yup.string().email().max(255),
    phone: Yup.string().max(255),
    phone2: Yup.string().max(255),
    licenseNumber: Yup.string().max(255),
    insuranceGuaranteeNumber: Yup.string().max(255),
});

export const useForm = ({ details }) => {
    const { t } = useTranslation();
    const [, { updateSettings }] = useSettingsContext();
    const [, { addError, addSuccess }] = useNotification();

    const [loading, setLoading] = useState(false);
    const [uploadedLogo, setUploadedLogo] = useState(null);
    const [currentLogo, setCurrentLogo] = useState(null);

    useEffect(() => {
        if (details && details.data) {
            const companyData = JSON.parse(details.data);

            if (companyData.logo) {
                setCurrentLogo(companyData.logo);
            }
        }
    }, [details.data]);

    const handleRemoveLogo = () => {
        setCurrentLogo(null);
        setUploadedLogo(null);
    };

    const handleUploadLogo = (files) => {
        setUploadedLogo(files.length ? files[0] : null);
    };

    const handleSubmit = async (values, actions) => {
        setLoading(true);
        const formData = new FormData();
        forEach(keys(values), (attribute) => {
            if (attribute !== 'logo') {
                formData.append(attribute, values[attribute]);
            }
        });

        if (uploadedLogo) {
            formData.append('logo', uploadedLogo);
        } else if (!currentLogo) {
            formData.append('removeLogo', 1);
        }

        const response = await sendRequest(updateSetting({ id: details._id }), {
            method: 'PUT',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        actions.setSubmitting(false);
        setLoading(false);
        if (response.status === 200) {
            addSuccess(t('Company settings was saved.'));
            const companyData = JSON.parse(details.data);
            const { name = '', address = '', nip = '', email = '' } = companyData;
            if (!name || !address || !nip || !email) {
                window.location.reload();
            } else {
                updateSettings('company', JSON.parse(response.data.data.data));
            }
        } else {
            addError(t(response.data.error));
        }
    };

    return {
        loading,
        currentLogo,
        uploadedLogo,
        handleRemoveLogo,
        handleUploadLogo,
        handleSubmit,
    };
};
