import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import concat from 'lodash/concat';
import get from 'lodash/get';
import set from 'lodash/set';
import reduce from 'lodash/reduce';
import { useNotification } from '../contexts/NotificationContext';

import { getDictionary } from '../api';
import { sendRequest } from '../utils/request';

export const useDictionaries = (codes = []) => {
    const { t } = useTranslation();
    const [, { addError }] = useNotification();
    const [dictionaries, setDictionaries] = useState({
        data: {},
        isLoading: true,
    });

    useEffect(() => {
        const fetchDictionary = async () => {
            setDictionaries((state) => ({ ...state, isLoading: true }));
            try {
                const response = await sendRequest(getDictionary(), {
                    method: 'POST',
                    data: {
                        filter: { group: { value: codes, operator: 'in' } },
                        fields: ['group', 'value', 'isActive'],
                        sort: { direct: 'ASC', field: 'value' },
                    },
                });

                if (response.status !== 200) {
                    addError(t(response.data.error));
                }

                const groupedItems = reduce(
                    get(response.data.data, 'items', []),
                    (result, { _id: id, value, isActive, group }) => {
                        set(
                            result,
                            group,
                            concat(get(result, group, []), {
                                value: id,
                                label: value,
                                isActive,
                            }),
                        );
                        return result;
                    },
                    {},
                );

                setDictionaries((state) => ({
                    ...state,
                    data: {
                        ...groupedItems,
                    },
                    isLoading: false,
                }));
            } catch (e) {
                addError(t(e));
                setDictionaries((state) => ({ ...state, isLoading: false }));
            }
        };

        fetchDictionary();
    }, []);

    return [dictionaries, null];
};
