import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DateRangePicker } from 'react-dates';
import classNames from 'classnames';

import { useField } from 'formik';
import isNull from 'lodash/isNull';
import Label from '../Label';
import ErrorMessage from '../ErrorMessage';
import HintMessage from '../HintMessage';
import { getDateFormat, getMomentObject } from '../../../../utils/date';
import './styles.module.css';

export const DEFAULT_DATE_RANGE_PICKER_PROPS = {
    showDefaultInputIcon: true,
    showClearDates: true,
    hideKeyboardShortcutsPanel: true,
    customArrowIcon: '-',
    isOutsideRange: () => false,
    firstDayOfWeek: 1,
    displayFormat: getDateFormat(),
    minimumNights: 0,
    startDatePlaceholderText: '',
    endDatePlaceholderText: '',
};

function FormDateRange({
    label,
    hint,
    fieldClassName,
    inputWrapperClassName,
    shouldBeTouched,
    nameFrom,
    nameTo,
    disabled,
    validationSchema,
    dateRangePickerProps,
}) {
    const [fieldFrom, metaFrom, helpersFrom] = useField(nameFrom);
    const [fieldTo, metaTo, helpersTo] = useField(nameTo);
    const [focused, setFocused] = useState(null);

    const isFromInvalid = metaFrom.error && (!shouldBeTouched || metaFrom.touched);
    const isToInvalid = metaTo.error && (!shouldBeTouched || metaTo.touched);

    useEffect(() => {
        if (!focused) {
            helpersFrom.setTouched(true, true);
            helpersTo.setTouched(true, true);
        }
    }, [focused]);

    const handleDateChange = ({ startDate, endDate }) => {
        helpersFrom.setValue(!isNull(startDate) ? startDate : null, false);

        helpersTo.setValue(!isNull(endDate) ? endDate : null, false);
    };

    const fieldClass = classNames('mb-4', fieldClassName, {
        error: isFromInvalid || isToInvalid,
    });

    const inputWrapperClass = classNames(inputWrapperClassName, {
        'date-field-focus': focused,
        'date-field-invalid': isFromInvalid || isToInvalid,
    });

    const mergedDateRangePickerProps = {
        ...DEFAULT_DATE_RANGE_PICKER_PROPS,
        ...dateRangePickerProps,
        // className: inputClass,
        startDate: fieldFrom.value ? getMomentObject(fieldFrom.value) : null,
        startDateId: nameFrom,
        endDate: fieldTo.value ? getMomentObject(fieldTo.value) : null,
        endDateId: nameTo,
        onDatesChange: handleDateChange,
        focusedInput: focused,
        onFocusChange: (focused) => setFocused(focused),
        disabled,
    };

    return (
        <div className={fieldClass}>
            {label && <Label label={label} field={nameFrom} validationSchema={validationSchema} />}
            <div className={inputWrapperClass}>
                <DateRangePicker {...mergedDateRangePickerProps} />
            </div>
            <div className="flex">
                {isFromInvalid && (
                    <ErrorMessage isInvalid={isFromInvalid} message={metaFrom.error} className="ml-[50px]" />
                )}
                {isToInvalid && <ErrorMessage isInvalid={isToInvalid} message={metaTo.error} className="ml-[25px]" />}
            </div>
            <HintMessage message={hint} />
        </div>
    );
}

FormDateRange.propTypes = {
    nameFrom: PropTypes.string.isRequired,
    nameTo: PropTypes.string.isRequired,
    fieldClassName: PropTypes.string,
    inputWrapperClassName: PropTypes.string,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    hint: PropTypes.string,
    shouldBeTouched: PropTypes.bool,
    validationSchema: PropTypes.object,
    dateRangePickerProps: PropTypes.object,
};

FormDateRange.defaultProps = {
    label: '',
    hint: '',
    fieldClassName: '',
    inputWrapperClassName: '',
    disabled: false,
    shouldBeTouched: true,
    validationSchema: {},
    dateRangePickerProps: {},
};

export default FormDateRange;
