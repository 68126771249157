import React from 'react';
import PropTypes from 'prop-types';

function PhoneCell({ value }) {
    return (
        <div className="rgt-cell-inner">
            {value && (
                <a
                    href={`phone:${value}`}
                    title={value}
                    className="text-gray-700 hover:text-blue-700 dark:text-slate-300 hover:underline underline-offset-4"
                >
                    {value}
                </a>
            )}
        </div>
    );
}

PhoneCell.propTypes = {
    value: PropTypes.string,
};

PhoneCell.defaultProps = {
    value: '',
};

export default PhoneCell;
