import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { findIndex, get, values } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useConfigContext } from '../../contexts/ConfigContext';
import Tabs from '../../components/Tabs';
import Grid from '../../components/Grid';
import Table from './components/DictionaryTable';
import urls from '../../setup/urls';

export const dictionaryMap = {
    reservation_type: {
        id: 'reservation_type',
        code: 'reservation-types',
        group: 'reservation_type',
        label: 'Reservation types',
    },
    hotel_types: {
        id: 'hotel_types',
        code: 'hotel-types',
        group: 'hotel_types',
        label: 'Hotel types',
    },
    operators: {
        id: 'operators',
        code: 'operators',
        group: 'operators',
        label: 'Operators',
    },
};
function Dictionaries() {
    const { group } = useParams();
    const { t } = useTranslation();
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [, { setPageTitle }] = useConfigContext();

    useEffect(() => {
        setPageTitle(t('Dictionaries'));
    }, []);

    useEffect(() => {
        setSelectedTabIndex(findIndex(values(dictionaryMap), ['code', group]));
    }, [group]);

    const panes = values(dictionaryMap).map((item) => {
        const { label, code } = item;
        return {
            menuItem: t(label),
            as: Link,
            to: `${get(urls, 'dictionary.route')}/${code}`,
            render: () => <Table {...item} />,
        };
    });

    return (
        <Grid.Container fluid>
            <Grid.Row>
                <Grid.Col>
                    <Tabs selectedIndex={selectedTabIndex} panes={panes} />
                </Grid.Col>
            </Grid.Row>
        </Grid.Container>
    );
}

export default Dictionaries;
