import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { get, find } from 'lodash';

import ReactPDF, { Image, Page, Text, Font, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import Html from 'react-pdf-html';

import { getMomentObject } from '../../utils/date';
import { saveBlobAsFile } from '../../utils/blob';
import { getFormattedPrice } from '../../utils/price';
import { statuses, countries } from '../../utils/dictionaries';

import RobotoRegular from '../../assets/fonts/Roboto/Roboto-Regular.ttf';
import RobotoBold from '../../assets/fonts/Roboto/Roboto-Bold.ttf';

const getStatus = (status) => get(find(statuses, ['value', status]), 'label', '');

Font.register({
    family: 'Roboto',
    fonts: [
        { src: RobotoRegular, fontWeight: 400 },
        { src: RobotoBold, fontWeight: 700 },
    ],
});

const styles = StyleSheet.create({
    page: { fontFamily: 'Roboto', padding: 25, paddingBottom: 100, fontSize: 9 },
    header: { flexDirection: 'row', justifyContent: 'space-between', paddingBottom: 15 },
    headerLogoSmall: { flexBasis: '50%' },
    headerLogo: { flexBasis: '50%' },
    headerImage: { maxWidth: '50%', maxHeight: 60 },
    headerCompanyData: { flexBasis: '50%', fontSize: 9 },
    headerTextStrong: { fontFamily: 'Roboto', fontSize: 9, fontWeight: 700 },
    headerText: { fontFamily: 'Roboto', fontSize: 9 },
    footer: {
        position: 'absolute',
        bottom: 25,
        left: 25,
        right: 25,
        width: '100%',
        borderTop: '1px solid #000',
        paddingTop: 5,
        fontSize: 9,
    },
    footerContent: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    footerText: { fontFamily: 'Roboto', fontSize: 9 },
    pageInfo: {
        fontSize: 9,
        textAlign: 'center',
    },
    generateDate: { fontSize: 7, textAlign: 'right' },
    resHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderTop: '1px solid #000',
        borderBottom: '1px solid #000',
        fontSize: 11,
        paddingVertical: 5,
    },
    resHeaderText: {
        flexBasis: '50%',
        fontWeight: 700,
        textAlign: 'left',
    },
    section: {
        marginVertical: 10,
    },
    sectionTitle: {
        fontSize: 11,
        fontWeight: 700,
        marginBottom: 10,
    },
    purchaserWrapper: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    table: {
        flexBasis: '50%',
        flexDirection: 'column',
    },
    tableRow: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        lineHeight: 2,
    },
    tableLabel: {
        flexBasis: '70',
    },
    listItem: {
        lineHeight: 2,
    },
    text: {
        fontSize: 9,
        lineHeight: 1.5,
    },
    calculationRow: { flexDirection: 'row', justifyContent: 'flex-start' },
    calculationName: {
        flexBasis: '65%',
        paddingVertical: 2,
    },
    calculationNumber: {
        flexBasis: '5%',
        paddingVertical: 2,
        textAlign: 'right',
    },
    calculationPrice: {
        flexBasis: '15%',
        paddingVertical: 2,
        textAlign: 'right',
    },
    calculationCost: {
        flexBasis: '15%',
        paddingVertical: 2,
        textAlign: 'right',
    },
    calculationFooterPrice: {
        fontSize: 11,
        fontWeight: 700,
        marginBottom: 10,
    },
    calculationFooterCost: {
        fontSize: 11,
        fontWeight: 700,
    },
    calculationSummary: {
        lineHeight: 1.5,
    },
    calculationBankName: {
        fontSize: 11,
        textAlign: 'right',
    },
    calculationBankAccount: {
        fontSize: 11,
        fontWeight: 700,
        textAlign: 'right',
    },
    calculationBankInfo: {
        fontSize: 11,
        textAlign: 'right',
    },
});

const htmlDecode = (input) => {
    const doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.documentElement.textContent;
    // return doc.documentElement.textContent.replace(/<br ?\/?>/g, '\n');
};

const getCountries = (countryCodes, t) => {
    const selectedCountries = countryCodes
        .map((countryCode) => get(find(countries, ['value', countryCode]), 'label', countryCode))
        .map((country) => t(country));

    return selectedCountries.join(', ');
};

export function ReservationPdfDocument({ data }) {
    const { t } = useTranslation();
    const { reservation, company = {}, reservationSettings, user } = data;
    const {
        reservationNo,
        country: countryCodes,
        status,
        notes,
        purchaser = {},
        clients = [],
        services = [],
    } = reservation;
    const { regulations } = reservationSettings;

    const getFormattedDate = (date, format = 'DD-MM-YYYY') => (date ? getMomentObject(date).format(format) : '');

    const agent = get(reservation, 'agent.name', `${company.name} ${company.phone}`);

    const country = getCountries(countryCodes, t);
    const currency = process.env.REACT_APP_DEFAULT_CURRENCY;

    const surchargeAmount =
        reservation.advancePayment.reduce((prev, current) => prev + parseFloat(current.amount), 0) || 0;
    const formattedSurchargeAmount = getFormattedPrice(surchargeAmount);

    const serviceTotalCost = reservation.services.reduce((prev, current) => prev + parseFloat(current.cost), 0) || 0;
    const formattedServiceTotalCost = getFormattedPrice(serviceTotalCost);

    const formattedDiffCost = getFormattedPrice(serviceTotalCost - surchargeAmount);

    return (
        <Document title={reservationNo || ''} debug>
            <Page size="A4" style={styles.page}>
                <View style={styles.header} fixed>
                    <View style={styles.headerLogo}>
                        {company.logo && <Image src={company.logo} style={styles.headerImage} />}
                    </View>
                    <View
                        style={styles.headerCompanyData}
                        render={({ pageNumber }) =>
                            pageNumber === 1 && (
                                <Fragment>
                                    <Text style={styles.headerTextStrong}>{company.name}</Text>
                                    <Text style={styles.headerText}>{company.address}</Text>
                                    <Text style={styles.headerText}>NIP {company.nip}</Text>
                                    <Text style={styles.headerText}>REGON {company.regon}</Text>
                                    <Text style={styles.headerText}>Konto: {company.bankName}</Text>
                                    <Text style={styles.headerText}>{company.accountNumber}</Text>
                                </Fragment>
                            )
                        }
                    />
                </View>

                <View style={styles.content}>
                    <Text style={styles.generateDate}>{getFormattedDate(new Date(), 'DD-MM-YYYY HH:mm')}</Text>
                    <View style={styles.resHeader}>
                        <Text style={styles.resHeaderText}>Dowód rezerwacji / Voucher / Umowa nr</Text>
                        <Text style={styles.resHeaderText}>
                            {reservationNo} ({t(getStatus(status || ''))})
                        </Text>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.sectionTitle}>Osoba zamawiająca:</Text>
                        <View style={styles.purchaserWrapper}>
                            <View style={styles.table}>
                                <View style={styles.tableRow}>
                                    <Text style={styles.tableLabel}>Nazwisko:</Text>
                                    <Text style={styles.tableValue}>{purchaser.lastName}</Text>
                                </View>
                                <View style={styles.tableRow}>
                                    <Text style={styles.tableLabel}>Imię:</Text>
                                    <Text style={styles.tableValue}>{purchaser.firstName}</Text>
                                </View>
                                <View style={styles.tableRow}>
                                    <Text style={styles.tableLabel}>Adres:</Text>
                                    <Text style={styles.tableValue}>{purchaser.street}</Text>
                                </View>
                                <View style={styles.tableRow}>
                                    <Text style={styles.tableLabel}>Kod/Miasto:</Text>
                                    <Text style={styles.tableValue}>
                                        {purchaser.postcode}, {purchaser.city}
                                    </Text>
                                </View>
                                <View style={styles.tableRow}>
                                    <Text style={styles.tableLabel}>Telefon:</Text>
                                    <View style={styles.tableValue}>
                                        <Text>{purchaser.phone || ''}</Text>
                                        <Text>{purchaser.phone2 || ''}</Text>
                                    </View>
                                </View>
                                <View style={styles.tableRow}>
                                    <Text style={styles.tableLabel}>Email:</Text>
                                    <View style={styles.tableValue}>
                                        <Text>{purchaser.email || ''}</Text>
                                        <Text>{purchaser.email2 || ''}</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.table}>
                                <View style={styles.tableRow}>
                                    <Text style={styles.tableLabel}>Data wyst.:</Text>
                                    <Text style={styles.tableValue}>{getFormattedDate(reservation.created_at)}</Text>
                                </View>
                                <View style={styles.tableRow}>
                                    <Text style={styles.tableLabel}>Cel podróży:</Text>
                                    <Text style={styles.tableValue}>{t(country)}</Text>
                                </View>
                                <View style={styles.tableRow}>
                                    <Text style={styles.tableLabel}>Hotel/Impreza:</Text>
                                    <Text style={styles.tableValue}>{reservation.hotel}</Text>
                                </View>
                                <View style={styles.tableRow}>
                                    <Text style={styles.tableLabel}>Okres podróży:</Text>
                                    <Text style={styles.tableValue}>
                                        {getFormattedDate(reservation.startDate)} -
                                        {getFormattedDate(reservation.endDate)}
                                    </Text>
                                </View>
                                <View style={styles.tableRow}>
                                    <Text style={styles.tableLabel}>Wystawiający:</Text>
                                    <Text style={styles.tableValue}>{user.name}</Text>
                                </View>
                                <View style={styles.tableRow}>
                                    <Text style={styles.tableLabel}>Agent:</Text>
                                    <Text style={styles.tableValue}>{agent}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View wrap={false} style={styles.section}>
                        <Text style={styles.sectionTitle}>Uczestnicy - dane z dowodu osobistego lub paszportu:</Text>
                        <View style={styles.list}>
                            {clients.map(({ lastName, firstName, birthDate }, index) => (
                                <Text key={birthDate} style={styles.listItem}>
                                    {index + 1}. {lastName} {firstName} ({getFormattedDate(birthDate)})
                                </Text>
                            ))}
                        </View>
                    </View>
                    <View wrap={false} style={styles.section}>
                        <Text style={styles.sectionTitle}>Usługi - w cenie:</Text>
                        <View style={styles.list}>
                            {services.map(({ name }, index) => (
                                <Text key={name} style={styles.listItem}>
                                    {index + 1}. {name}
                                </Text>
                            ))}
                        </View>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.sectionTitle}>Opis usług:</Text>
                        <Html style={styles.text}>{htmlDecode(notes || '')}</Html>
                    </View>
                    <View style={styles.section}>
                        <View wrap={false}>
                            <Text style={styles.sectionTitle}>Kalkulacja ceny:</Text>
                            <View style={styles.calculationRow}>
                                <View style={styles.calculationName}> </View>
                                <View style={styles.calculationNumber}>
                                    <Text>Ilość</Text>
                                </View>
                                <View style={styles.calculationPrice}>
                                    <Text>Cena jedn.</Text>
                                </View>
                                <View style={styles.calculationCost}>
                                    <Text>Wartość w {currency}</Text>
                                </View>
                            </View>
                            {services.map(({ name, number, price, cost }) => (
                                <View style={styles.calculationRow} key={name}>
                                    <View style={styles.calculationName}>
                                        <Text>{name}</Text>
                                    </View>
                                    <View style={styles.calculationNumber}>
                                        <Text>{number}</Text>
                                    </View>
                                    <View style={styles.calculationPrice}>
                                        <Text>{getFormattedPrice(price)}</Text>
                                    </View>
                                    <View style={styles.calculationCost}>
                                        <Text>{getFormattedPrice(cost)}</Text>
                                    </View>
                                </View>
                            ))}
                            <View style={styles.calculationRow}>
                                <View style={styles.calculationName}> </View>
                                <View style={styles.calculationNumber}> </View>
                                <View style={styles.calculationPrice}>
                                    <Text style={styles.calculationFooterPrice}>Razem w {currency}</Text>
                                </View>
                                <View style={styles.calculationCost}>
                                    <Text style={styles.calculationFooterCost}>{formattedServiceTotalCost}</Text>
                                </View>
                            </View>
                        </View>
                        <View wrap={false}>
                            <Text style={styles.calculationSummary}>Kwota słownie: {reservation.amountInWords}</Text>
                            <Text style={styles.calculationSummary}>
                                Kwota zaliczki: {formattedSurchargeAmount} {currency}
                            </Text>
                            <Text style={styles.calculationSummary}>
                                Pozostała część wpłaty należna do dnia: {getFormattedDate(reservation.surchargeDate)} (
                                {formattedDiffCost} {currency})
                            </Text>
                        </View>
                        <View wrap={false}>
                            <Text style={styles.calculationBankName}>{company.bankName}</Text>
                            <Text style={styles.calculationBankAccount}>{company.accountNumber}</Text>
                            <Text style={styles.calculationBankInfo}>
                                Obowiązkowy tytuł przelewu bankowego: nr rezerwacji i nazwisko osoby zamawiającej
                            </Text>
                        </View>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.sectionTitle}>Regulamin:</Text>
                        <Html style={styles.text}>{htmlDecode(regulations || '')}</Html>
                    </View>
                </View>
                <View
                    fixed
                    style={styles.footer}
                    render={({ pageNumber, totalPages }) => (
                        <Fragment>
                            <View style={styles.footerContent}>
                                <View style={styles.footerLeft}>
                                    <Text style={styles.footerText}>{company.name}</Text>
                                    <Text style={styles.footerText}>{company.address}</Text>
                                    <Text style={styles.footerText}>
                                        NIP {company.nip}, REGON {company.regon}
                                    </Text>
                                    <Text style={styles.footerText}>{company.licenseNumber}</Text>
                                    <Text style={styles.footerText}>{company.insuranceGuaranteeNumber}</Text>
                                </View>
                                <View style={styles.footerRight}>
                                    <Text style={styles.footerText}>{company.email}</Text>
                                    <Text style={styles.footerText}>Tel. {company.phone}</Text>
                                    <Text style={styles.footerText}>{company.phone2}</Text>
                                </View>
                            </View>
                            <View>
                                <Text style={styles.pageInfo}>
                                    {pageNumber}/{totalPages}
                                </Text>
                            </View>
                        </Fragment>
                    )}
                />
            </Page>
        </Document>
    );
}

export const downloadReservationPdf = async ({ data, name }) => {
    const blob = await ReactPDF.pdf(<ReservationPdfDocument data={data} />).toBlob();
    saveBlobAsFile(blob, `${name}.pdf`);
};

export function ReservationPdfPreview(props) {
    return (
        <PDFViewer>
            <ReservationPdfDocument {...props} />
        </PDFViewer>
    );
}

function ReservationPdf() {
    return null;
}

ReservationPdf.propTypes = {};
ReservationPdf.defaultProps = {};
export default ReservationPdf;
