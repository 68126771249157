import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Popover } from '@headlessui/react';
import { Float } from '@headlessui-float/react';
import { HiOutlineInformationCircle } from 'react-icons/hi';

function Tooltip({ children }) {
    const buttonRef = useRef();
    const timeoutDuration = 100;
    let timeout;

    const closePopover = () => {
        buttonRef.current?.dispatchEvent(
            new KeyboardEvent('keydown', {
                key: 'Escape',
                bubbles: true,
                cancelable: true,
            }),
        );
    };

    const onMouseEnter = (open) => {
        clearTimeout(timeout);
        if (open) return;

        buttonRef.current?.click();
    };

    const onMouseLeave = (open) => {
        if (!open) return;
        timeout = setTimeout(() => closePopover(), timeoutDuration);
    };
    return (
        <Popover className="relative inline-block text-left">
            {({ open }) => (
                <Float
                    portal
                    autoPlacement
                    arrow={5}
                    offset={15}
                    enter="transition  ease-out duration-200"
                    enterFrom="scale-80 opacity-0"
                    enterTo="scale-100 opacity-100"
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    tailwindcssOriginClass
                >
                    <div className="flex flex-nowrap justify-center items-center">
                        <Popover.Button
                            ref={buttonRef}
                            className="text-2xl text-gray-600 dark:text-slate-300"
                            onMouseEnter={() => {
                                onMouseEnter(open);
                            }}
                            onMouseLeave={() => onMouseLeave(open)}
                        >
                            <HiOutlineInformationCircle />
                        </Popover.Button>
                    </div>
                    <Popover.Panel>
                        <Float.Arrow className="absolute bg-gray-800 w-5 h-5 rotate-45 border border-gray-200" />
                        <div
                            className="relative px-3 py-2 text-sm font-medium text-white bg-gray-800 dark:bg-gray-700 rounded-md shadow-sm"
                            onMouseEnter={() => onMouseEnter(open)}
                            onMouseLeave={() => onMouseLeave(open)}
                        >
                            {children}
                        </div>
                    </Popover.Panel>
                </Float>
            )}
        </Popover>
    );
}

Tooltip.propTypes = {
    children: PropTypes.any,
};

Tooltip.defaultProps = {
    children: null,
};
export default Tooltip;
