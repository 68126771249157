import { useState } from 'react';
import { useField } from 'formik';
import { forEach } from 'lodash';

export const useTravellers = () => {
    const [field, meta] = useField('clients');
    const [modalOpen, setModalOpen] = useState(false);

    const handleClose = () => {
        setModalOpen(false);
    };

    const handleOpen = () => {
        setModalOpen(true);
    };

    const handleAddClient = (arrayHelpers) => {
        arrayHelpers.push({
            birthDate: '',
            firstName: '',
            lastName: '',
        });
    };

    const handleSelection = (clients, arrayHelpers) => {
        forEach(clients, ({ firstName, lastName, birthDate }) => {
            arrayHelpers.push({
                birthDate,
                firstName,
                lastName,
            });
        });

        handleClose();
    };

    return [
        { modalOpen, field, meta },
        {
            handleOpen,
            handleClose,
            handleAddClient,
            handleSelection,
        },
    ];
};
