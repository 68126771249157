import React from 'react';
import PropTypes from 'prop-types';

function DescriptionList({ items }) {
    return (
        <dl className="mt-3 divide-y divide-gray-200 dark:divide-gray-400">
            {items.map(({ label, value }) => (
                <div key={label} className="flex flex-col py-1">
                    <dt className="text-sm">{label}</dt>
                    <dd className="font-semibold dark:text-white">{value}</dd>
                </div>
            ))}
        </dl>
    );
}

DescriptionList.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.any,
            value: PropTypes.any,
        }),
    ),
};

DescriptionList.defaultProps = {
    items: [],
};
export default DescriptionList;
