import React from 'react';
import PropTypes from 'prop-types';

function Container({ children, className, fluid, noPadding }) {
    return (
        <div
            className={`${fluid ? 'max-w-full' : 'container'} ${
                noPadding ? '-my-4' : 'px-2 lg:px-4'
            } mx-auto pb-2 lg:pb-4 ${className}`}
        >
            {children}
        </div>
    );
}

Container.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    fluid: PropTypes.bool,
    noPadding: PropTypes.bool,
};

Container.defaultProps = {
    children: null,
    className: '',
    fluid: false,
    noPadding: false,
};
export default Container;
