import React from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import FormItem from '../../../FormItem';
import Tooltip from '../../../Tooltip';
import { formSchema, useSearchForm } from './useSearchForm';
import styles from './styles.module.css';

const initValues = {
    search: '',
};

function SearchForm(props) {
    const { t } = useTranslation();
    const { searchMessage } = props;
    const { value, handleChange } = useSearchForm(props);

    return (
        <Formik initialValues={initValues} validationSchema={formSchema} enableReinitialize>
            {() => (
                <Form className={styles.searchForm}>
                    <FormItem
                        name="search"
                        icon="search"
                        placeholder={t('Search...')}
                        value={value}
                        fieldClassName="mb-0"
                        style={{ paddingRight: '2.5rem' }}
                        validationSchema={formSchema}
                        onChange={handleChange}
                    />

                    <span className="absolute inset-y-0 flex items-center justify-center right-4">
                        <Tooltip>{searchMessage}</Tooltip>
                    </span>
                </Form>
            )}
        </Formik>
    );
}

SearchForm.propTypes = {
    searchMessage: PropTypes.string,
};

SearchForm.defaultProps = {
    searchMessage: '',
};

export default SearchForm;
