import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import useAuth from './useAuth';

const AuthContext = createContext();

function AuthContextProvider({ children }) {
    const contextValue = useAuth();
    return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
}

AuthContextProvider.propTypes = {
    children: PropTypes.any,
};

AuthContextProvider.defaultProps = {
    children: null,
};

export default AuthContextProvider;
export const useAuthContext = () => useContext(AuthContext);
