import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Yup from '../../../../utils/yup';
import { useNotification } from '../../../../contexts/NotificationContext';
import { sendRequest } from '../../../../utils/request';
import { authForgetPassword } from '../../../../api';

export const formSchema = Yup.object().shape({
    email: Yup.string().email().required(),
});

export const useForm = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const [, { addSuccess, addError }] = useNotification();

    const handleSubmit = async (values, actions) => {
        setLoading(true);
        const response = await sendRequest(authForgetPassword(), {
            method: 'POST',
            data: values,
        });

        actions.setSubmitting(false);
        setLoading(false);
        if (response.status === 200) {
            addSuccess(t('Send an email'));
        } else {
            addError(t(response.data.error));
        }
    };

    return {
        loading,
        handleSubmit,
    };
};
