import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import get from 'lodash/get';
import FormItem from '../../../../../FormItem';

function MultiDropdownFilterField({ column, filters, onFilterChange }) {
    const { label, field: name, options } = column;
    const [value, setValue] = useState([]);
    const [field] = useField(name);

    useEffect(() => {
        setValue(get(filters[name], 'value', []));
    }, [filters[name]]);

    useEffect(() => {
        if (JSON.stringify(field.value) !== JSON.stringify(value)) {
            setValue(field.value);
            onFilterChange(name, { value: field.value, operator: 'in' });
        }
    }, [field]);

    return (
        <FormItem
            label={label}
            name={name}
            value={value}
            options={options}
            type="dropdown"
            isMultiple
            isPortable={false}
        />
    );
}

MultiDropdownFilterField.propTypes = {
    column: PropTypes.object,
    filters: PropTypes.object,
    onFilterChange: PropTypes.func,
};

MultiDropdownFilterField.defaultProps = {
    column: {},
    filters: {},
    onFilterChange: () => {},
};

export default MultiDropdownFilterField;
