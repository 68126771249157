import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

function List({ emptyMessage, items, children, classNameChildWrapper }) {
    const { t } = useTranslation();

    const childWrapperClassName = classNames(
        classNameChildWrapper,
        `flex items-center w-full text-base font-semibold text-gray-900 dark:text-white`,
    );

    if (!items.length)
        return <div className="py-4 font-semibold text-center text-gray-500 dark:text-gray-300">{t(emptyMessage)}</div>;
    return (
        <div className="flow-root">
            <ul className="divide-y divide-gray-200 dark:divide-gray-600">
                {items.map((item, index) => (
                    <li key={index} className="py-1 ">
                        <div className="flex items-center space-x-4">
                            <div className={childWrapperClassName}>{children(item)}</div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
}

List.propTypes = {
    emptyMessage: PropTypes.string,
    children: PropTypes.func,
    items: PropTypes.array,
    classNameChildWrapper: PropTypes.string,
};

List.defaultProps = {
    emptyMessage: 'No items',
    children: () => {},
    items: [],
    classNameChildWrapper: '',
};
export default List;
