import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Form, Formik } from 'formik';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import urls from '../../../../setup/urls';
import Grid from '../../../../components/Grid';
import Button from '../../../../components/Button';
import LinkButton from '../../../../components/LinkButton';
import ButtonsWrapper from '../../../../components/ButtonsWrapper';

import FormFields from './components/FormFields';
import { formSchema, useForm } from './useForm';

export const minInitValues = {
    firstName: '',
    lastName: '',
    birthDate: '',
};

export const initValues = {
    ...minInitValues,
    email: '',
    email2: '',
    phone: '',
    phone2: '',
    postcode: '',
    street: '',
    city: '',
    country: process.env.REACT_APP_DEFAULT_COUNTRY || '',
    nationality: '',
    passport: '',
    passportDate: '',
    innerNotes: '',
};
function ClientForm({ id, details }) {
    const { t } = useTranslation();
    const { loading, handleSubmit } = useForm({ id, details });

    return (
        <Formik
            initialValues={
                !isEmpty(details)
                    ? {
                          ...details,
                          birthDate: get(details, 'birthDate', '') || '',
                          passportDate: get(details, 'passportDate', '') || '',
                      }
                    : initValues
            }
            validationSchema={formSchema}
            enableReinitialize
            onSubmit={handleSubmit}
        >
            {() => (
                <Form>
                    <Grid.Container fluid noPadding>
                        <FormFields loading={loading} formSchema={formSchema} />
                        <Grid.Row>
                            <Grid.Col>
                                <ButtonsWrapper>
                                    <Button type="submit" color="primary" loading={loading}>
                                        {t('Save')}
                                    </Button>

                                    <LinkButton to={get(urls, 'clients.route')} disabled={loading}>
                                        {t('Cancel')}
                                    </LinkButton>
                                </ButtonsWrapper>
                            </Grid.Col>
                        </Grid.Row>
                    </Grid.Container>
                </Form>
            )}
        </Formik>
    );
}

ClientForm.propTypes = {
    id: PropTypes.string,
    details: PropTypes.object,
};

ClientForm.defaultProps = {
    id: null,
    details: {},
};

export default ClientForm;
