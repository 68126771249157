import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HiOutlineMail } from 'react-icons/hi';

import { Form, Formik } from 'formik';
import get from 'lodash/get';
import urls from '../../../../setup/urls';
import FormItem from '../../../../components/FormItem';
import Button from '../../../../components/Button';

import { formSchema, useForm } from './useForm';

function ForgotPasswordForm() {
    const { t } = useTranslation();
    const { loading, handleSubmit } = useForm();

    return (
        <Formik
            initialValues={{
                email: '',
            }}
            validationSchema={formSchema}
            onSubmit={handleSubmit}
        >
            {() => (
                <Form className="flex flex-col">
                    <FormItem
                        name="email"
                        label="Email"
                        type="email"
                        icon="user"
                        disabled={loading}
                        validationSchema={formSchema}
                    />
                    <div className="flex flex-row items-center justify-between">
                        <Button type="submit" loading={loading} iconLeft={HiOutlineMail}>
                            {t('Send reset link')}
                        </Button>
                        <Link to={get(urls, 'login.route')}>{t('Back to login page')}</Link>
                    </div>
                </Form>
            )}
        </Formik>
    );
}
export default ForgotPasswordForm;
