import React from 'react';

import Loader from '../Loader';

function LoadingMask() {
    return (
        <div className="absolute inset-0 z-10 flex items-center justify-center w-full h-full text-3xl bg-slate-300/50 dark:bg-slate-900/50">
            <Loader />
        </div>
    );
}
export default LoadingMask;
