import React from 'react';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';

import { Form, Formik } from 'formik';
import Button from '../../../../components/Button';
import ButtonsWrapper from '../../../../components/ButtonsWrapper';
import FormItem from '../../../../components/FormItem';
import DescriptionList from '../../../../components/DescriptionList';
import { useAuthContext } from '../../../../contexts/AuthContext';

import { formSchema, useForm } from './useForm';

function Details() {
    const { t } = useTranslation();
    const [{ user }] = useAuthContext();
    const { loading, handleSubmit } = useForm();

    return (
        <div>
            <div>
                <div className="px-4 pb-3 mb-4 border rounded-md shadow-lg ring-slate-900 dark:bg-slate-800">
                    <DescriptionList
                        items={[
                            {
                                label: t('Email'),
                                value: get(user, 'email', ''),
                            },
                            {
                                label: t('Role'),
                                value: t(get(user, 'role', '')),
                            },
                        ]}
                    />
                </div>
                <Formik
                    initialValues={{
                        name: get(user, 'name', ''),
                        password: '',
                    }}
                    validationSchema={formSchema}
                    onSubmit={handleSubmit}
                >
                    {() => (
                        <Form autoComplete="off">
                            <FormItem label={t('Name')} name="name" disabled={loading} validationSchema={formSchema} />
                            <FormItem
                                label={t('Your Password')}
                                name="password"
                                type="password"
                                autocomplete="off"
                                disabled={loading}
                                validationSchema={formSchema}
                                hint="For security reason you have to enter your current password to confirm that it's you"
                            />
                            <ButtonsWrapper>
                                <Button type="submit" color="primary" loading={loading}>
                                    {t('Save')}
                                </Button>
                            </ButtonsWrapper>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}
export default Details;
