import React from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from '../Tooltip';

const STATUSES = [
    {
        label: 'Initial',
        description: 'Wstępna rezerwacja, która czeka na procesowanie.',
    },
    {
        label: 'Confirmed',
        description: 'Wizyta potwierdzona przez kupującego, gotowa do realizacji.',
    },
    {
        label: 'Realized',
        description: 'Zrealizwoana rezerwacja.',
    },
    {
        label: 'Cancelled',
        description: 'Rezerwacja nad którą z jakiegoś powodu już nie pracujecie.',
    },
    {
        label: 'Blocked',
        description:
            'Reservation is currently "frozen". This status should be used when no one should be working on the reservation. To unblock reservations, use "Change status for selected" in the reservation table.',
    },
];

function ReservationStatusTooltip() {
    const { t } = useTranslation();
    return (
        <Tooltip>
            <div className="max-w-[80vw] md:max-w-xs max-h-[50vh] md:max-h-96 overflow-y-auto">
                {STATUSES.map(({ label, description }, index) => (
                    <div key={label}>
                        <p className="uppercase mb-1">
                            <strong>{t(label)}</strong>
                        </p>
                        <p className="text-xs leading-5">{t(description)}</p>
                        {index < STATUSES.length - 1 && <hr className="my-3" />}
                    </div>
                ))}
            </div>
        </Tooltip>
    );
}

ReservationStatusTooltip.propTypes = {};
ReservationStatusTooltip.defaultProps = {};

export default ReservationStatusTooltip;
