import React from 'react';
import PropTypes from 'prop-types';
import { Menu as HeadlessMenu } from '@headlessui/react';
import Menu from '../../../../components/Menu';

function UserNav({ name, role, items }) {
    return (
        <div className="px-4">
            <Menu
                trigger={() => (
                    <span className="relative inline-flex items-center justify-center overflow-hidden border-2 border-blue-800 rounded-full w-7 h-7 lg:w-10 lg:h-10 dark:border-blue-600 bg-gradient-to-t from-blue-600 to-blue-800">
                        <span className="font-medium text-white">{name[0] || ''}</span>
                    </span>
                )}
                menuHeader={() => (
                    <HeadlessMenu.Item
                        as="div"
                        className="p-2 border-b border-b-slate-400 dark:text-slate-300 dark:border-b-slate-500"
                    >
                        <div className="text-sm truncate">{name}</div>
                        <div className="text-sm font-semibold truncate">{role}</div>
                    </HeadlessMenu.Item>
                )}
                position="right"
                items={items}
            />
        </div>
    );
}

UserNav.propTypes = {
    name: PropTypes.string,
    role: PropTypes.string,
    items: PropTypes.array,
};

UserNav.defaultProps = {
    name: '',
    role: '',
    items: [],
};

export default UserNav;
