import React from 'react';
import PropTypes from 'prop-types';
import GridTable from '@nadavshaar/react-grid-table';
import { useTranslation } from 'react-i18next';
import { HiOutlineTrash } from 'react-icons/hi';
import Button from '../Button';
import Modal from '../Modal';
import Header from './components/Header';
import Pagination from './components/Pagination';
import PageSize from './components/PageSize';
import useTable from './useTable';
import styles from './styles.module.scss';

function Information() {
    return null;
}

function NoResults() {
    const { t } = useTranslation();
    return t('No Data');
}

function DeleteMessage() {
    const { t } = useTranslation();
    return <p>{t('Are you sure you want to delete item')}</p>;
}

function Table(props) {
    const {
        id,
        columns,
        headerActions,
        rowActions,
        initConfig,
        importConfig,
        contextApi,
        pageSizes,
        showSearch,
        isSelectable,
        withSearch,
        withImport,
        withExport,
        withDelete,
        onSelectionChange,
        getCustomPayload,
        deleteMessage,
        searchMessage,
    } = props;

    const { t } = useTranslation();
    const { state, rowsRef, formattedColumns, deleteModalConfig, api } = useTable({
        id,
        columns,
        initConfig,
        contextApi,
        rowActions,
        isSelectable,
        onSelectionChange,
        getCustomPayload,
        withDelete,
    });
    const { sort, page, pageSize, totalSize, filters } = state;

    const DeleteMessage = deleteMessage;
    return (
        <div>
            <div className="relative shadow-md">
                <GridTable
                    className={styles.table}
                    components={{ Header, Pagination, PageSize, Information, NoResults }}
                    columns={formattedColumns}
                    rows={rowsRef.current}
                    totalRows={totalSize}
                    onRowsRequest={api.handleRowsRequest}
                    onSortChange={api.handleSortChange}
                    onPageChange={api.handlePageChange}
                    onPageSizeChange={api.handlePageSizeChange}
                    onColumnsChange={api.handleColumnsChange}
                    onSelectedRowsChange={api.handleSelectedRowsChange}
                    page={page}
                    pageSize={pageSize}
                    pageSizes={pageSizes}
                    showSearch={showSearch}
                    sort={{
                        colId: sort.field,
                        isAsc: sort.direct === 'ASC',
                    }}
                    additionalProps={{
                        header: {
                            state,
                            actions: headerActions,
                            filters,
                            importConfig,
                            onFilterChange: api.handleFiltersChange,
                            onImportRequest: api.handleImportRequest,
                            onExportRequest: api.handleExportRequest,
                            searchMessage,
                            withSearch,
                            withImport,
                            withExport,
                        },
                        footer: {
                            className: `w-full overflow-auto rounded-b-md bg-white hover:text-white dark:bg-slate-700`,
                        },
                        headerCellContainer: {
                            className: 'px-4 bg-white dark:bg-slate-800',
                        },
                        headerCell: {
                            className: 'text-sm font-semibold dark:bg-slate-800',
                        },
                        cellContainer: {
                            className: 'px-4 py-1 text-sm dark:bg-slate-800',
                        },
                        api: {
                            onOpenDeleteModal: api.handleOpenDeleteModal,
                        },
                    }}
                />
            </div>
            <Modal open={deleteModalConfig.isOpen} onClose={api.handleCloseDeleteModal} title={t('Delete Item')}>
                <div>
                    <DeleteMessage item={deleteModalConfig.item} />
                </div>
                <div className="flex justify-end pt-4 mt-4 border-t border-t-slate-300">
                    <Button color="primary" outline onClick={api.handleCloseDeleteModal}>
                        {t('Cancel')}
                    </Button>
                    <Button
                        color="danger"
                        className="ml-4"
                        iconLeft={HiOutlineTrash}
                        onClick={() => api.handleDeleteItemRequest(deleteModalConfig.item)}
                    >
                        {t('Delete')}
                    </Button>
                </div>
            </Modal>
        </div>
    );
}

Table.propTypes = {
    id: PropTypes.string.isRequired,
    columns: PropTypes.array,
    pageSizes: PropTypes.array,
    initConfig: PropTypes.object,
    showSearch: PropTypes.bool,
    isSelectable: PropTypes.bool,
    onSelectionChange: PropTypes.func,
    getCustomPayload: PropTypes.func,
    deleteMessage: PropTypes.any,
    searchMessage: PropTypes.any,
    withSearch: PropTypes.bool,
    withImport: PropTypes.bool,
    importConfig: PropTypes.object,
    withExport: PropTypes.bool,
    withDelete: PropTypes.func,
};

Table.defaultProps = {
    columns: [],
    initConfig: {},
    pageSizes: [25, 50, 100, 500],
    showSearch: false,
    isSelectable: false,
    onSelectionChange: () => {},
    getCustomPayload: (data) => data,
    deleteMessage: DeleteMessage,
    searchMessage: '',
    withSearch: false,
    withImport: false,
    importConfig: {},
    withExport: false,
    withDelete: () => true,
};
export default Table;
