import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import List from '../List';
import { getFormattedPrice } from '../../utils/price';

function PricingList({ title, items, className, classNamePriceWrapper }) {
    if (!items.length) return null;

    const rootClassName = classNames(
        className,
        `w-full max-w-md p-4 bg-white border rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700`,
    );

    return (
        <div className={rootClassName}>
            <div className="flex items-center justify-between mb-2">
                <h5 className="font-bold leading-none text-gray-900 text-md dark:text-white">{title}</h5>
            </div>
            <List items={items} classNameChildWrapper={classNamePriceWrapper}>
                {({ value, currency }, index) => (
                    <Fragment key={index}>
                        {getFormattedPrice(value)} {currency}
                    </Fragment>
                )}
            </List>
        </div>
    );
}

PricingList.propTypes = {
    title: PropTypes.string,
    items: PropTypes.array,
    className: PropTypes.string,
    classNamePriceWrapper: PropTypes.string,
};

PricingList.defaultProps = {
    title: '',
    items: [],
    className: '',
    classNamePriceWrapper: '',
};
export default PricingList;
