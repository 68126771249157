import React from 'react';
import PropTypes from 'prop-types';
import { keys, isArray } from 'lodash';
import classNames from 'classnames';

import { useTranslation } from 'react-i18next';

function ErrorMessage({ isInvalid, message, className }) {
    const { t } = useTranslation();
    const errorMessageClass = classNames('flex my-1 text-xs text-red-600', className);

    if (!isInvalid) return null;

    return isArray(message) ? (
        <div className="flex gap-4 pt-2">
            {message.map((fields, index) => {
                if (!fields) return null;
                return keys(fields).map((field) => (
                    <span className={errorMessageClass}>
                        {t(field)}[{index}]: {t(fields[field])}
                    </span>
                ));
            })}
        </div>
    ) : (
        <span className={errorMessageClass}>{t(message)}</span>
    );
}

ErrorMessage.propTypes = {
    message: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    isInvalid: PropTypes.bool,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

ErrorMessage.defaultProps = {
    message: '',
    isInvalid: false,
    className: '',
};

export default ErrorMessage;
