import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useConfigContext } from '../../contexts/ConfigContext/ConfigContext';

import Form from './components/Form';

function RegisterForm() {
    const { t } = useTranslation();
    const [, { setPageTitle }] = useConfigContext();

    useEffect(() => {
        setPageTitle(t('Register'));
    }, []);

    return (
        <div>
            <h2 className="text-lg font-semibold text-center lg:text-xl">{t('Register')}</h2>
            <Form />
        </div>
    );
}
export default RegisterForm;
