import React from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from '../Tooltip';

const ROLES = [
    {
        label: 'Administrator',
        description: 'It has permissions to every functionality of the application.',
    },
    {
        label: 'Manager',
        description: 'Has limited access. It cannot manage app users and main app settings.',
    },
    {
        label: 'Worker',
        description: 'He has access only to the customer and booking management panel.',
    },
];

function RolesTooltip() {
    const { t } = useTranslation();
    return (
        <Tooltip>
            <div className="max-w-[80vw] md:max-w-xs max-h-[50vh] md:max-h-96 overflow-y-auto">
                {ROLES.map(({ label, description }, index) => (
                    <div key={label}>
                        <p className="uppercase mb-1">
                            <strong>{t(label)}</strong>
                        </p>
                        <p className="text-xs leading-5">{t(description)}</p>
                        {index < ROLES.length - 1 && <hr className="my-3" />}
                    </div>
                ))}
            </div>
        </Tooltip>
    );
}

RolesTooltip.propTypes = {};
RolesTooltip.defaultProps = {};

export default RolesTooltip;
