import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { omit } from 'lodash';
import classNames from 'classnames';
import CurrencyInput from 'react-currency-input-field';
import { omitProps } from '../../constants';

function FormPrice({ icon, iconPosition, inputWrapperClassName, inputWrapperChildren, isInvalid, onChange, ...props }) {
    const { name } = props;
    const [field, , helpers] = useField(name);

    const handleChange = (value) => {
        if (onChange) {
            onChange(value);
        }

        helpers.setValue(value);
    };

    const inputWrapperClass = classNames('', inputWrapperClassName, {
        'left icon': !!icon && iconPosition === 'left',
        'right icon': !!icon && iconPosition === 'right',
    });

    const inputClass = classNames('', {
        '!border-red-600 focus:!ring-red-600/30': isInvalid,
    });

    return (
        <div className={inputWrapperClass}>
            <CurrencyInput
                id={name}
                name={name}
                value={field.value}
                groupSeparator=" "
                decimalSeparator="."
                className={inputClass}
                onBlur={field.onBlur}
                onValueChange={handleChange}
                {...omit(props, omitProps)}
                type="text"
            />
            {icon && <i className={`${icon} icon`} />}
            {inputWrapperChildren}
        </div>
    );
}

FormPrice.propTypes = {
    name: PropTypes.string.isRequired,
    icon: PropTypes.string,
    iconPosition: PropTypes.oneOf(['right', 'left']),
    inputWrapperClassName: PropTypes.string,
    inputWrapperChildren: PropTypes.any,
    isInvalid: PropTypes.bool,
    onChange: PropTypes.func,
};

FormPrice.defaultProps = {
    icon: '',
    iconPosition: 'left',
    inputWrapperClassName: '',
    inputWrapperChildren: null,
    isInvalid: false,
    onChange: null,
};

export default FormPrice;
