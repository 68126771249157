import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { get, isObject } from 'lodash';

function Label({ label, field, validationSchema }) {
    const { t } = useTranslation();
    const isRequired = () => get(validationSchema, `fields[${field}].exclusiveTests.required`, false);

    return (
        <label className="block mb-1 text-xs font-semibold text-gray-700 uppercase dark:text-slate-300" htmlFor={field}>
            {isObject(label) ? label : t(label)}
            {isRequired(field) && <span className="ml-[2px] opacity-75">*</span>}
        </label>
    );
}

Label.propTypes = {
    label: PropTypes.any,
    field: PropTypes.string,
    validationSchema: PropTypes.object,
};

Label.defaultProps = {
    label: '',
    field: '',
    validationSchema: {},
};

export default Label;
