import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Yup from '../../../../utils/yup';
import { useSettingsContext } from '../../../../contexts/SettingsContext';
import { useNotification } from '../../../../contexts/NotificationContext';
import { sendRequest } from '../../../../utils/request';
import { updateSetting } from '../../../../api';

export const formSchema = Yup.object().shape({
    regulations: Yup.string(),
});

export const useForm = ({ details }) => {
    const { t } = useTranslation();
    const [, { updateSettings }] = useSettingsContext();
    const [, { addError, addSuccess }] = useNotification();
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (values, actions) => {
        setLoading(true);

        const response = await sendRequest(updateSetting({ id: details._id }), {
            method: 'PUT',
            data: values,
        });

        actions.setSubmitting(false);
        setLoading(false);
        if (response.status === 200) {
            addSuccess(t('Agreement settings was saved.'));
            updateSettings('agreement', JSON.parse(response.data.data.data));
        } else {
            addError(t(response.data.error));
        }
    };

    return {
        loading,
        handleSubmit,
    };
};
