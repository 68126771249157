// ======================
// Auth
// ======================
export const authLogin = () => `api/auth/login`;
export const authLogout = () => `api/auth/logout`;
export const register = () => `api/auth/register`;
export const authMe = () => `api/auth/me`;
export const authUpdateProfil = () => `api/auth/update-profil`;
export const authChangePassword = () => `api/auth/change-password`;
export const authForgetPassword = () => `api/auth/forget-password`;
export const authResetPassword = (resetToken) => `api/auth/reset-password/${resetToken}`;
export const authActivateAccount = (token) => `api/auth/activate-account/${token}`;

// ======================
// Recaptcha
// ======================

export const recaptchaVerify = () => `api/recaptcha/verify`;

// ======================
// Agents
// ======================
export const getAgents = () => `api/agents`;
export const createAgent = () => `api/agents/create`;
export const getAgent = ({ id }) => `api/agents/${id}`;
export const updateAgent = ({ id }) => `api/agents/${id}`;
export const deleteAgent = ({ id }) => `api/agents/${id}`;
export const setAgentStatus = ({ id }) => `api/agents/set-status/${id}`;
export const exportAgents = () => `api/agents/export`;
export const importAgents = () => `api/clients/import`;

export const agentApi = {
    get: getAgents,
    create: createAgent,
    update: updateAgent,
    delete: deleteAgent,
    setStatus: setAgentStatus,
    export: exportAgents,
    import: importAgents,
};

// ======================
// Clients
// ======================
export const getClients = () => `api/clients`;
export const searchClients = () => `api/clients/search`;
export const createClient = () => `api/clients/create`;
export const getClient = ({ id }) => `api/clients/${id}`;
export const updateClient = ({ id }) => `api/clients/${id}`;
export const deleteClient = ({ id }) => `api/clients/${id}`;
export const exportClients = () => `api/clients/export`;
export const importClients = () => `api/clients/import`;

export const clientApi = {
    get: getClients,
    create: createClient,
    update: updateClient,
    delete: deleteClient,
    export: exportClients,
    import: importClients,
};
// ======================
// Services
// ======================

export const getServices = () => `api/services`;
export const createService = () => `api/services/create`;
export const getService = ({ id }) => `api/services/${id}`;
export const updateService = ({ id }) => `api/services/${id}`;
export const deleteService = ({ id }) => `api/services/${id}`;
export const setServiceStatus = ({ id }) => `api/services/set-status/${id}`;
export const exportServices = () => `api/services/export`;
export const importServices = () => `api/services/import`;

export const serviceApi = {
    get: getServices,
    create: createService,
    update: updateService,
    delete: deleteService,
    setStatus: setServiceStatus,
    export: exportServices,
    import: importServices,
};

// ======================
// Users
// ======================

export const getUsers = () => `api/users`;
export const createUser = () => `api/users/create`;
export const getUser = ({ id }) => `api/users/${id}`;
export const updateUser = ({ id }) => `api/users/${id}`;
export const deleteUser = ({ id }) => `api/users/${id}`;
export const reactivateUser = ({ id }) => `api/users/reactivate/${id}`;
export const setUserStatus = ({ id }) => `api/users/set-status/${id}`;
export const exportUsers = () => `api/users/export`;
export const importUsers = () => `api/users/import`;

export const userApi = {
    get: getUsers,
    create: createUser,
    update: updateUser,
    delete: deleteUser,
    reactivate: reactivateUser,
    setStatus: setUserStatus,
    export: exportUsers,
    import: importUsers,
};
// ======================
// Settings
// ======================
export const getSettings = () => `api/settings`;
export const getSetting = ({ id }) => `api/settings/${id}`;
export const updateSetting = ({ id }) => `api/settings/${id}`;

// ======================
// Dictionary
// ======================
export const getDictionary = () => `api/dictionaries`;
export const getDictionaryItem = ({ id }) => `api/dictionaries/${id}`;
export const createDictionaryItem = () => `api/dictionaries/create`;
export const updateDictionaryItem = ({ id }) => `api/dictionaries/${id}`;
export const deleteDictionaryItem = ({ id }) => `api/dictionaries/${id}`;
export const setDictionaryItemStatus = ({ id }) => `api/dictionaries/set-status/${id}`;
export const exportDictionary = () => `api/dictionaries/export`;
export const importDictionary = () => `api/dictionaries/import`;

export const dictionaryApi = {
    get: getDictionary,
    create: createDictionaryItem,
    update: updateDictionaryItem,
    delete: deleteDictionaryItem,
    setStatus: setDictionaryItemStatus,
    export: exportDictionary,
    import: importDictionary,
};

// ======================
// Reservations
// ======================
export const getReservations = () => `api/reservations`;
export const getReservationItem = ({ id }) => `api/reservations/${id}`;
export const duplicateReservationItem = ({ id }) => `api/reservations/duplicate/${id}`;
export const createReservationItem = () => `api/reservations/create`;
export const updateReservationItem = ({ id }) => `api/reservations/${id}`;
export const deleteReservationItem = ({ id }) => `api/reservations/${id}`;
export const setStatusesReservations = () => `api/reservations/set-statuses`;
export const exportReservations = () => `api/reservations/export`;
export const importReservations = () => `api/reservations/import`;
export const printReservation = ({ id }) => `api/reservations/print/${id}`;

export const reservationsApi = {
    get: getReservations,
    duplicate: duplicateReservationItem,
    create: createReservationItem,
    update: updateReservationItem,
    delete: deleteReservationItem,
    setStatuses: setStatusesReservations,
    export: exportReservations,
    import: importReservations,
    print: printReservation,
};
